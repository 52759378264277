// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoverb:hover label {
    transform: scaleX(1.2) scaleY(1.1); /* Increase size by 5% */
    transition: transform 0.3s ease-in-out; /* Apply transition */
    /* text-decoration: underline; */
    color:blue
  }
  
 
  
  .hoverb label {
    transition: transform 0.3s ease-in-out; /* Apply transition */
  }`, "",{"version":3,"sources":["webpack://./src/Components/Podcast/assets/podcast.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC,EAAE,wBAAwB;IAC5D,sCAAsC,EAAE,qBAAqB;IAC7D,gCAAgC;IAChC;EACF;;;;EAIA;IACE,sCAAsC,EAAE,qBAAqB;EAC/D","sourcesContent":[".hoverb:hover label {\n    transform: scaleX(1.2) scaleY(1.1); /* Increase size by 5% */\n    transition: transform 0.3s ease-in-out; /* Apply transition */\n    /* text-decoration: underline; */\n    color:blue\n  }\n  \n \n  \n  .hoverb label {\n    transition: transform 0.3s ease-in-out; /* Apply transition */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
