import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap modal and button components
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';

function PodcastPopup({ podcastdetails, handleClose, promptData }) {


  const [token, setToken] = useState('')
  const [newClaudeSummary, setNewClaudeSummary] = useState('')
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    setToken(Cookies.get('admin_token'))
  }, [])


  const [regenFormShow, setRegenFormShow] = useState(false)
  const [prompt, setPrompt] = useState(promptData)

  const handleRegenerate = () => {
    setLoading(true)
    console.log(podcastdetails)
    axios.post(`${process.env.REACT_APP_URL}regenSum`, { podcastId: podcastdetails.podId, episode: podcastdetails.title, prompt }
      , {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {
      if (res.data.task_id) {
        console.log('Task ID:', res.data.task_id);
        toast('Regeneration in progress, please wait...');
        checkTaskStatus(res.data.task_id);
        
      }
    })
    .catch(err => {
      console.error(err);
      setLoading(false)
      toast.error('An error occurred while starting the regeneration.');
    });
    };
    
    let pollTimeout;
    
    const checkTaskStatus = (taskId) => {
    const pollInterval = 5000; // 5 seconds
    
    const pollTaskStatus = () => {
      axios.get(`${process.env.REACT_APP_URL}/regenSum/status/${taskId}`)
        .then(res => {
          if (res.data.status === 'completed') {
            console.log('Task completed:', res.data.result);
            setLoading(false)
            setNewClaudeSummary(res.data.result.podcastsumClaude); // Adjust based on actual response structure
            toast('Summary updated successfully');
          } else if (res.data.status === 'error') {
            console.error('Error:', res.data.message);
            setLoading(false)
            toast.error(res.data.message || 'An error occurred during regeneration.');
          } else {
            // If status is 'in_progress', poll again after the interval
            setLoading(false)
            pollTimeout = setTimeout(pollTaskStatus, pollInterval);
          }
        })
        .catch(err => {
          console.error(err);
          setLoading(false)
          toast.error('An error occurred while checking the task status.');
        });
    };
    
    pollTaskStatus();
    };
    
    useEffect(() => {
    // Cleanup function to clear any remaining timeouts if the component unmounts
    return () => {
      if (pollTimeout) {
        clearTimeout(pollTimeout);
      }
    };
    }, []);

  const handleSaveNewSummary = () => {
    axios.post(`${process.env.REACT_APP_URL}saveNewSum`, { podcastId: podcastdetails.podId, episode: podcastdetails.title, newClaudeSummary }
      , {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {
      if (res.data[0] === 'Ok') {
        console.log(res.data, "----44"); setNewClaudeSummary(res.data[1]); toast.success("Summary Updated Successfully"); setTimeout(() => {
          window.location.reload()
        }, 2000);
      } else { console.log('error'); toast.error("Some Issue Occured") }
    }).catch(err => { console.log(err); toast.error("Some Issue Occured") })
  }

  function renderSummaryG(summary) {
    // Split the summary into paragraphs based on <br> tags
    const paragraphs = summary.split('<br>');

    // Map each paragraph to a <p> tag
    const renderedParagraphs = paragraphs.map((paragraph, index) => (
      <p className='d-flex justify-content-start text-start' key={index}>{paragraph.replace(/&bull;/g, '•').replace(/<br>/g, '\n\n').replace(/\*\*/g, '\n\n')}</p>
    ));

    return renderedParagraphs;
  }

  function renderSummary(summary) {
    // Split the summary into paragraphs based on <br> tags
    const paragraphs = summary.split('<br>');

    // Map each paragraph to a <p> tag
    const renderedParagraphs = paragraphs.map((paragraph, index) => (
      <p className='d-flex justify-content-start text-start' key={index}>{paragraph.replace(/&bull;/g, '•')}</p>
    ));

    return renderedParagraphs;
  }


  return (
    <Modal show={podcastdetails !== null} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{podcastdetails.podTitle}</Modal.Title>

      </Modal.Header>
      <Modal.Body>

        <img src={podcastdetails.image} className="card-img-top" alt={podcastdetails.podTitle} />
        <h4 className='my-3'>ep. - {podcastdetails.title}</h4>
        <h5 className='my-3'>Gemini Summary</h5>
        <p className='my-3'>{renderSummaryG(podcastdetails.summary)}</p>
        <h5 className='my-3'>Claude Summary</h5>
        <p className='my-3'>{renderSummary(podcastdetails.summaryClaude)}</p>
        {
          newClaudeSummary !== '' ?
            <>
              <h5 className='my-3'>Regenerated Claude Summary</h5>
              <div className='px-2 rounded-2' style={{ backgroundColor: 'lightgreen' }}>{renderSummary(newClaudeSummary)}</div>
              <Button className='mt-1 mx-1' variant="primary" onClick={() => handleSaveNewSummary()}>Save New Summary</Button>
              <Button className='mt-1 mx-1' variant="danger" onClick={() => { setRegenFormShow(false); setNewClaudeSummary('') }}>Discard</Button>
            </> : <></>
        }
        <h5 className='my-3'>Links</h5>
        <div className="my-3">
          <a style={{wordWrap:'break-word'}} href={podcastdetails.url}>{podcastdetails.url}</a>
        </div>
        <div className="my-3">
          <a style={{wordWrap:'break-word'}} href={podcastdetails.podcastUrl}>{podcastdetails.podcastUrl}</a>
        </div>
        <div className="my-3">
          <a style={{wordWrap:'break-word'}} href={podcastdetails.rssUrl}>{podcastdetails.rssUrl}</a>
        </div>




        {
          regenFormShow === false ?
            <Button className='mt-5' variant="primary" onClick={() => { setRegenFormShow(true) }}>Regenerate Summary</Button>
            : <></>
        }
        {
          regenFormShow === true ?
            <div className='mt-5 pt-3'>
              <div class="mb-3">
                <h5 for="exampleFormControlInput1" class="form-label">Prompt</h5>
                <textarea type="text" value={prompt} class="form-control" onChange={(e) => setPrompt(e.target.value)} id="exampleFormControlInput1" placeholder="Prompt" />
              </div>
              <Button className='mt-1 mx-1' variant="primary" onClick={() => handleRegenerate()}>Regenerate</Button>
              <Button className='mt-1 mx-1' variant="danger" onClick={() => { setRegenFormShow(false) }}>Cancel</Button>
              <div className='form-group d-flex justify-content-center mt-4'>
        {loading ? (
          <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />

        ) : (
          ''
        )}
      </div>

            </div>
            : <></>
        }

      </Modal.Body>
      <Modal.Footer>

        <Button variant="secondary" onClick={handleClose}>Close</Button>
        {/* <Button variant="primary" href={podcastdetails.podcastUrl}>Listen</Button>
        <Button variant="secondary" href={podcastdetails.url}>Website</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default PodcastPopup;