import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import { GoTriangleLeft } from "react-icons/go";
import { GoTriangleRight } from "react-icons/go";
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import noimg from './assets/noimg.png'
import './assets/podcast.css'

import './section1pod.css'
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";

const PodcastImage = ({ imageUrl, noimg, timeoutDuration = 2000 }) => {
  const [src, setSrc] = useState(imageUrl);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;

    const timer = setTimeout(() => {
      setSrc(noimg);
    }, timeoutDuration);

    img.onload = () => {
      clearTimeout(timer);
      setSrc(imageUrl);
    };

    img.onerror = () => {
      clearTimeout(timer);
      setSrc(noimg);
    };

    return () => clearTimeout(timer);
  }, [imageUrl, noimg, timeoutDuration]);

  return (
    <img
      style={{ height: '200px', width: '200px' }}
      className="rounded-4 imgM"
      alt="podcast"
      src={src}
    />
  );
};


const Section1Podcast = () => {

 const[token,setToken]=useState('')
  useEffect(()=>{
setToken(Cookies.get('token'))
  },[])

  const alltool = []
  const apitoken = process.env.REACT_APP_TOKEN
  const [podcastlist, setPodcastlist] = useState([])
  const [sublist, setSublist] = useState([])
  const [listOfSubscribed, setListOfSubscribed] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [recentlySub, setRecentlySub] = useState(false)
  const [recentSubData, setRecentSubData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [podchaserCost,setPodchaserCost]=useState(0)
  const [flagged,setFlagged]=useState([])

  const[podKey,setPodKey]=useState('Not Authorized')

  const [searchTerm, setSearchTerm] = useState('')
  const[loadingP,setLoadingP]=useState(false)

  const [podavltog,setPodavltog]=useState(false)

  useEffect(()=>{
    if(podavltog === false){
      console.log('1 avail')
    }else if(podavltog === true){
      console.log('2 sub')
    }else{
      console.log('3 sub')
    }
  },[podavltog])
  

useEffect(()=>{
  setLoadingP(true)
  if(token !== ''){
console.log("PPPPPPPPOOOODDDKEY")
axios.get(`${process.env.REACT_APP_URL}flagged`, {  }
  , {
    headers: {
      'Content-Type': 'application/json' // Optional: Specify content type if needed
    }
  }
).then(res => {
  console.log(res.data,"----44"); setFlagged(res.data)}).catch(err => {console.log(err)})


  axios.post(`${process.env.REACT_APP_URL}podchaserKeyGet`, {  }
        , {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        console.log(res.data,"----44"); setPodKey(res.data)}).catch(err => {console.log(err);setLoading(false)})
      }
},[token])


  const query = {
    query: `
            {
                podcasts(
                    filters: {
                        language: "en",
                        rating: { minRating: 4, maxRating: 5 },

                    },
                    first: ${perPage},
                    page: ${page + 1},
                    
                ) {
                    paginatorInfo {
                        count
                        currentPage
                        firstItem
                        hasMorePages
                        lastItem
                        lastPage
                        perPage
                        total
                    }
                    data {
                        id
                        title
                        description
                        webUrl
                        applePodcastsId
                        imageUrl
                    }
                }
            }
        `
  };

  const searchquery = {
    query: `
          {
              podcasts(searchTerm: "${searchTerm}") 
              {
                  paginatorInfo {
                      count
                      currentPage
                      firstItem
                      hasMorePages
                      lastItem
                      lastPage
                      perPage
                      total
                  }
                  data {
                      id
                      title
                      description
                      webUrl
                      applePodcastsId
                      imageUrl
                  }
              }
          }
      `
  };


  const handleSearch = () => {
if( podKey !== 'Not Authorized'){
  setLoadingP(true)
  setPodcastlist([])
    axios.post('https://api.podchaser.com/graphql/cost', searchquery
      , {
        headers: {
          'Authorization': `Bearer ${podKey}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
      // print("X-Podchaser-Points-Remaining:", response.headers.get("X-Podchaser-Points-Remaining"))
      // print("X-Podchaser-Query-Cost:", response.headers.get("X-Podchaser-Query-Cost"))
    ).then(res => {console.log(res.data);setPodchaserCost(res.data)}).catch(err => console.log(err))

    axios.post('https://api.podchaser.com/graphql', searchquery
      , {
        headers: {
          'Authorization': `Bearer ${podKey}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => { console.log(res); setPodcastlist(res.data.data.podcasts.data.filter(podcast => !flagged.includes(podcast.id)));console.log("148--",res.data.data.podcasts.data);setLoadingP(false) })
    .catch(err => {console.log(err.response.data.message);setLoadingP(false)
    })
  }
  }


  useEffect(() => {
    console.log(podKey,"--142")
    if( podKey !== 'Not Authorized' ){
      setLoadingP(true)
  setPodcastlist([])

       console.log(podKey,"--144")
    
     axios.post('https://api.podchaser.com/graphql/cost', query
      , {
        headers: {
          'Authorization': `Bearer ${podKey}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {console.log(res.data,"--155");setPodchaserCost(res.data)}).catch(err => console.log(err))

    axios.post('https://api.podchaser.com/graphql', query
      , {
        headers: {
          'Authorization': `Bearer ${podKey}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => { console.log(res); setPodcastlist(res.data.data.podcasts.data.filter(podcast => !flagged.includes(podcast.id)));console.log("removed--",res.data.data.podcasts.data.filter(podcast => flagged.includes(podcast.id)));console.log("163--",res.data.data.podcasts.data.filter(podcast => !flagged.includes(podcast.id)));setLoadingP(false) })
    .catch(err => {console.log(err.response.data.message);setLoadingP(false)
handleApiError(err.response.data.message)   
    })
  }
  }, [page,podKey])


  useEffect(()=>{
    console.log(podchaserCost.cost,"--190")
    if(podchaserCost.cost > 0){
      const podchaserCost2 = podchaserCost.cost
      axios.post(`${process.env.REACT_APP_URL}podchaserCost`, { podchaserCost2 }
      , {
        headers: {
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => { console.log(res.data); setPodchaserCost(0)  })
    .catch(err => {console.log(err)});

    }
  },[podchaserCost])


  const handleApiError = async (error) => {
    console.error('API Error:', error);
    toast.error("Oops! We're currently experiencing technical difficulties. Our team is working hard to fix this. Thank you for your patience!");

    try {
      await sendErrorMessage(error);
    } catch (err) {
      console.error('Error sending error message:', err);
    }
  };

  const sendErrorMessage = async (errorMessage) => {
    try{
      await axios.post(
        `${process.env.REACT_APP_URL}podchaserIssueF`,
         {errorMessage} ,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {console.log(res.data);}).catch(err => console.log(err))
    }catch(err){
      console.log(err)
    }
   
  };


  const handleLeft = () => {
    if (page !== 0) {
      setPage(page - 1)
    }
  }
  const handleRight = () => {
    setPage(page + 1)
  }


  const handleSub = (chk, podcastid, title,description,image) => {
    setLoading2(true)
    console.log(podcastid, chk,description,"--------handlesub")
    // return
    if (loading === false) {


      axios.post(`${process.env.REACT_APP_URL}sub`, { podcastid, chk, title,description,image }
        , {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        console.log(res.data); // Log the response data to the console
    
    if (res.data === 'Subscription removed' ) {
      setRecentlySub(false); // If the subscription was removed or limit reached, set recentlySub to false
      setLoading2(false); // Set loading state to false
      toast.success(res.data);
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } else if(res.data[1] == 'limit'){
      setRecentlySub(false); // If the subscription was removed or limit reached, set recentlySub to false
      setLoading2(false); // Set loading state to false
      toast.success(res.data[0]);
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }else {
      toast.success(res.data); // Display success message using toast
      setRecentlySub(true); // Set recentlySub state to true
      setRecentSubData([podcastid, title]); // Set recentSubData state
      setLoading2(false);
      setTimeout(() => {
        window.location.reload() 
       }, 2000);
    }
      } ) 
        .catch(err => {
          if (err.response && err.response.status === 401) {
            // Unauthorized error handling
            console.log('Unauthorized: You are not logged in.');
            // Display a message to the user indicating they are not logged in
            toast.error('Unauthorized: You are not logged in.');
            setLoading2(false);
          } else {
            // Other error handling
            console.log('An error occurred:', err);
            setLoading2(false);
            // Display a generic error message to the user
            toast.error('An error occurred. Please try again later.');
          }
        });
    }

  }

  useEffect(() => {
    console.log(recentSubData, "=========93")
    const sendSummary = async () => {
      if (recentlySub && recentSubData.length !== 0 && loading === false) {
        try {
          setLoading(true)
          const response = await axios.post(
            `${process.env.REACT_APP_URL}sendSum`,
            {
              podcastId: recentSubData[0],
              title: recentSubData[1],
            },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          if (response.data.err === 'Error') {
            console.log(response.data.msg);
            toast.error("Some Error Occurred");
            setRecentlySub(false);
            setLoading(false)
            setRecentSubData([])
          } else {
            console.log(response.data);
            toast.success(response.data);
            setRecentlySub(false);
            setLoading(false)
            setRecentSubData([])
              
          }
        }
        catch (err) {
          if (err.response && err.response.status === 401) {
            // Unauthorized error handling
            console.log('Unauthorized: You are not logged in.');
            // Display a message to the user indicating they are not logged in
            toast.error('Unauthorized: You are not logged in.');
            setLoading(false)
            setRecentSubData([])

          } else {
            // Other error handling
            console.log('An error occurred:', err);
            // Display a generic error message to the user
            toast.error('An error occurred. Please try again later.');
            setLoading(false)
            setRecentSubData([])

          }
        };
      }
    };

    sendSummary();
  }, [recentlySub, recentSubData])


  const handleUnSub = (podcastid, title) => {

    // console.log(podcastid,chk)
    const chk = false
    const description = ''
    const image = ''
    setLoading2(true);


    axios.post(`${process.env.REACT_APP_URL}sub`, { podcastid, chk, title,image,description }
      , {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => { console.log(res.data);setLoading2(false); toast.success(res.data); setRecentlySub(false);setTimeout(() => {
     window.location.reload() 
    }, 2000); })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Unauthorized error handling
          console.log('Unauthorized: You are not logged in.');
          setLoading2(false);
          // Display a message to the user indicating they are not logged in
          toast.error('Unauthorized: You are not logged in.');
        } else {
          // Other error handling
          console.log('An error occurred:', err);
          setLoading2(false);
          // Display a generic error message to the user
          toast.error('An error occurred. Please try again later.');
        }
      });
  }


  const [checkedItems, setCheckedItems] = useState({});
  useEffect(() => {
    // Initialize checkedItems state based on sublist
    console.log('checked')
    const initialCheckedItems = {};
    sublist.forEach(id => {
      initialCheckedItems[id] = true;
      console.log(initialCheckedItems[id])
    });
    setCheckedItems(initialCheckedItems);
    console.log(initialCheckedItems)
  }, [sublist]);

  const handleCheckboxChange = (itemId, isChecked) => {
    if (loading === false) {

      setCheckedItems(prevState => ({
        ...prevState,
        [itemId]: isChecked
      }));
    }
  };



  useEffect(() => {
    if(token !== ''){
    axios.post(`${process.env.REACT_APP_URL}sublist`, {}
      , {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => { console.log(res.data); setSublist(res.data.map(podcast => podcast.podcast_id)); setListOfSubscribed(res.data) }).catch(err => console.log(err))
}
}, [token])

const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    if (searchTerm.trim() !== '') {
      handleSearch();
    }
  }
};

const MAX_PARAGRAPH_LENGTH = 200; // Maximum characters before truncation

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text; // Return full text if within limit
    } else {
        return text.slice(0, maxLength) + '...'; // Truncate and add three dots
    }
};

  return (
    <div
    style={{ minHeight: "80vh", background: "#4CA1FF" }}
    className=" min-h-screen pt-5"
  >

<div className='mx-auto paddingToogle rounded-pill row' style={{backgroundColor:'#5162B3',width:'90%', maxWidth:'800px'}}>
<div  className={`fontTog rounded-pill paddingToggleS col-6 ${!podavltog ? 'selectTog' : 'unselTog'}`}  style={{ cursor: 'pointer' }}  onClick={()=>setPodavltog(false)}>
Available Podcast
</div>
<div className={`fontTog rounded-pill paddingToggleS col-6 ${podavltog ? 'selectTog' : 'unselTog'}`}  style={{ cursor: 'pointer' }} onClick={()=>setPodavltog(true)}>
Subscribed Podcast
</div>
</div>

    <div className='mt-4'>
      <GoTriangleLeft className='fs-4 pb-2'  style={{ cursor: 'pointer' }} onClick={(() => handleLeft())} />
      <span className='fs-4 bg-white px-2 rounded-3'>{page + 1}</span>
      <GoTriangleRight className='fs-4 pb-2'  style={{ cursor: 'pointer' }} onClick={(() => handleRight())} />
    </div>
    <div className='form-group d-flex justify-content-center mt-1'>
      {loadingP ? (
        <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />

      ) : (
        ''
      )}
    </div>

    <div className='form-group d-flex justify-content-center mt-1'>
      {loading ? (
        <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />

      ) : (
        ''
      )}
    </div>
    <div className='form-group d-flex justify-content-center mt-1'>
      {loading2 ? (
        <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />

      ) : (
        ''
      )}
    </div>

{
  podavltog === false?
  <>
    <div className="input-group-container mb-3 p-1 container col-lg-8 col-12 mt-2">
            <input
                type="text"
                placeholder="Search the Podcast..."
                style={{ backgroundColor: '#5262B4', color: 'white', borderColor: 'white' }}
                value={searchTerm}
                className="form-control search-input p-2 py-3"
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
                aria-label="search"
            />
            <button
                className="btn  search-button px-4 py-2"
                onClick={handleSearch}
                style={{ cursor: 'pointer' }}
                type="button"
                id="button-addon2"
                disabled={searchTerm.trim() === ''}
            >
                Browse Podcast
            </button>
        </div>

        <div className='row mx-3 mt-2'>
       
{
  podcastlist.map((x)=>(
    <div className=' d-flex px-4  col-xl-6 wrapSubCard ' style={{maxWidth:'900px'}}>
      {/* <img style={{height:'200px', width:'200px'}} className='rounded-4 imgM' alt='podcast' src={x.imageUrl || noimg} onError={(e) => { e.target.src = noimg }}/> */}
      <PodcastImage imageUrl={x.imageUrl} noimg={noimg} timeoutDuration={5000} />
      <div style={{color:'white'}} className='textM '>
        <h2>{x.title}</h2>
        <p className='mt-3 pb-3' style={{borderBottom:'1px solid white'}}>
        {truncateText(x.description || "Podcast Description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit repudiandae est dicta dolore, exercitationem aliquam? Laborum atque ipsam aperiam magnam consectetur, fugiat a quo facilis dolores adipisci voluptas obcaecati vitae dolorum sed eligendi minima cupiditate possimus nesciunt inventore! Esse maxime rerum nulla perferendis, aut ipsa, debitis omnis quod voluptatem sit labore neque amet aliquam, sunt perspiciatis. Molestias, dolor sit. Dicta veniam sit possimus, error atque, vel ratione quod dolores animi, pariatur ex labore sint? Sed ex quo tenetur impedit, quibusdam rem, alias vel voluptates laudantium blanditiis repellat magnam quos! Eum dolores similique sunt veritatis nulla provident molestiae rerum saepe neque!", MAX_PARAGRAPH_LENGTH)}
        </p>
        {
                      checkedItems[x.id] ?
                      <button type="button" value={x.id} className="btn btnunsub rounded-pill px-5"  onClick={(e) => handleUnSub(e.target.value, x.title)}>Unsubscribe {checkedItems[x.id]}</button>
                      :
                    <button type="button" value={x.id} className="btn rounded-pill btnsub px-5" style={{ }} onClick={(e) => {handleSub(true, e.target.value, x.title,x.description,x.imageUrl); console.log(e.target.value,"---hanse sub target")}}><FaPlus className='me-2' /> Subscribe {checkedItems[x.id]}</button>

                    }
      </div>
    </div>
    
  ))
}
</div>
  </> 
  :''
}
{
  podavltog === true?
  <>
    {/* <div className="input-group-container mb-3 p-1 container col-lg-8 col-12 mt-5">
            <input
                type="text"
                placeholder="Search the Podcast..."
                style={{ backgroundColor: '#5262B4', color: 'white', borderColor: 'white' }}
                value={searchTerm}
                className="form-control search-input p-2 py-3"
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
                aria-label="search"
            />
            <button
                className="btn  search-button px-4 py-2"
                onClick={handleSearch}
                
                type="button"
                id="button-addon2"
                disabled={searchTerm.trim() === ''}
            >
                Browse Podcast
            </button>
        </div> */}
<div className='row mx-3 mt-5'>
{
          listOfSubscribed.length === 0 ? <h1 style={{color:'white'}}>You haven't subscribed to any Podcast</h1>:''
        }
{
  listOfSubscribed.map((x)=>(
    <div className=' d-flex px-4  col-xl-6 wrapSubCard ' style={{maxWidth:'900px'}}>
      <img style={{height:'200px', width:'200px'}} className='rounded-4 imgM' alt='podcast' src={x.image || noimg} onError={(e) => { e.target.src = noimg }}/>
      <div style={{color:'white'}} className='textM '>
        <h2 style={{minWidth:'300px'}}>{x.title}</h2>
        <p className='mt-3 pb-3' style={{borderBottom:'1px solid white'}}>
        {truncateText(x.description || "Podcast Description Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit repudiandae est dicta dolore, exercitationem aliquam? Laborum atque ipsam aperiam magnam consectetur, fugiat a quo facilis dolores adipisci voluptas obcaecati vitae dolorum sed eligendi minima cupiditate possimus nesciunt inventore! Esse maxime rerum nulla perferendis, aut ipsa, debitis omnis quod voluptatem sit labore neque amet aliquam, sunt perspiciatis. Molestias, dolor sit. Dicta veniam sit possimus, error atque, vel ratione quod dolores animi, pariatur ex labore sint? Sed ex quo tenetur impedit, quibusdam rem, alias vel voluptates laudantium blanditiis repellat magnam quos! Eum dolores similique sunt veritatis nulla provident molestiae rerum saepe neque!", MAX_PARAGRAPH_LENGTH)}
        </p>
        <button type="button" value={x.podcast_id} className="btn btnunsub rounded-pill px-5" style={{ }} onClick={(e) => handleUnSub(e.target.value, x.title)}>Unsubscribe</button>
      </div>
    </div>
    
  ))
}
</div>
  </> 
  :''
}

    

    {/* <div
      className=" mx-2  flex flex-col"
      style={{ background: "#89c8ff" }}
    >



      <div className="">
        <div className="d-flex justify-content-center mx-auto row" style={{minWidth:'100%'}}>
          {podcastlist.map((item, index) => (
            // <div className='mx-2'>
            <div key={index} className="card my-4  mx-2 px-0 hoverb" style={{ width: '300px' }}>
              <img src={item.imageUrl || noimg} className="card-img-top" style={{ width: '100%',height:'300px' }} alt='' onError={(e) => { e.target.src = noimg }} />
              <div className="card-body">
                <h5 className="card-title mb-3">{item.title}</h5>
                <div className="pt-4 mx-auto d-flex justify-content-center">
                  <div style={{ position: 'absolute', bottom: '10px' }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={item.id}
                      id={item.id}
                      checked={checkedItems[item.id] || false}
                      onChange={(e) => {
                        handleCheckboxChange(item.id, e.target.checked);
                        handleSub(e.target.checked, e.target.value, item.title);
                      }}
                    />
                    {
                      checkedItems[item.id] ?
                    <label htmlFor={item.id} className="mx-2">Subscribed</label>:
                    <label htmlFor={item.id} className="mx-2">Subscribe</label>
                    }

                  </div>
                </div>
              </div>
            </div>
            // </div>
          ))}
        </div>
        <div className=' flex justify-center mx-auto mt-4'>
         
        </div>
      </div>

      <h3 className='my-3'>Subscribed Podcasts</h3>

      <div className="overflow-x-auto">

        <table style={{ minWidth: '100%' }} className="min-w-full bg-white border shadow-lg">
          
          <thead className="bg-gray-100  ">
            <tr className="">
              <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                Sr. No.
              </th>
              <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                Title
              </th>
              <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                Subscribed
              </th>
            </tr>
          </thead>
          <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {
              listOfSubscribed?.length === 0 ?
              <tr  className='bg-white' style={{ transition: "background-color 0.3s ease" }}>
              <td
                className="py-2 px-4 border-b text-center"
                style={{ minWidth: '150px' }}
              >
               
              </td>
              <td
                className="py-2 px-4 border-b text-center"
                style={{ minWidth: '150px' }}
              >
              <h5> No Subscriptions Yet</h5>
              </td>
              <td
                className="py-2 px-4 border-b text-center"
                style={{ minWidth: '50px' }}
              >
               
              </td>
            </tr> : ''
            }
            {listOfSubscribed.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                <td
                  className="py-2 px-4 border-b text-center"
                  style={{ minWidth: '50px' }}
                >
                  {(index + 1)}.
                </td>
                <td
                  className="py-2 px-4 border-b text-center"
                  style={{ minWidth: '150px' }}
                >
                  {item.title}
                </td>
                <td
                  className="py-2 px-4 border-b text-center"
                  style={{ minWidth: '50px' }}
                >
                  <button type="button" value={item.podcast_id} className="btn btn-danger" onClick={(e) => handleUnSub(e.target.value, item.title)}>Unsubscribe</button>
                
                </td>
              </tr>
            ))}
          </tbody>
        </table>


        <div className=' flex justify-center mx-auto mt-4'>
        
        </div>
      </div>

    </div> */}
    <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
  </div>

  )
}

export default Section1Podcast
