// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoverb:hover {
    transform: scaleX(1.04) scaleY(1.04); /* Increase size by 5% */
    transition: transform 0.3s ease-in-out; /* Apply transition */
    /* text-decoration: underline; */
    /* color:blue */
  }
  
 
  
  .hoverb {
    transition: transform 0.3s ease-in-out; /* Apply transition */
  }

  @media screen and (max-width: 767px) {
    .wrapSubCard{
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 50px;
      }
      .textM{
        margin-left: 0px;
        margin-right: 0x;
        margin-top: 30px;
        text-align: center;
      }
      .imgM{
        align-self: center;
      }
      
}
@media screen and (min-width: 768px) {
    .wrapSubCard{
        flex-wrap: nowrap;
        justify-content: start;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .textM{
        margin-left: 20px;
        margin-right: 0x;
        text-align: start;
      }
      .imgM{
        align-self: start;
      }
}`, "",{"version":3,"sources":["webpack://./src/Components/Podcasters/assets/podcast.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC,EAAE,wBAAwB;IAC9D,sCAAsC,EAAE,qBAAqB;IAC7D,gCAAgC;IAChC,eAAe;EACjB;;;;EAIA;IACE,sCAAsC,EAAE,qBAAqB;EAC/D;;EAEA;IACE;QACI,eAAe;QACf,uBAAuB;QACvB,gBAAgB;QAChB,mBAAmB;MACrB;MACA;QACE,gBAAgB;QAChB,gBAAgB;QAChB,gBAAgB;QAChB,kBAAkB;MACpB;MACA;QACE,kBAAkB;MACpB;;AAEN;AACA;IACI;QACI,iBAAiB;QACjB,sBAAsB;QACtB,gBAAgB;QAChB,mBAAmB;MACrB;MACA;QACE,iBAAiB;QACjB,gBAAgB;QAChB,iBAAiB;MACnB;MACA;QACE,iBAAiB;MACnB;AACN","sourcesContent":[".hoverb:hover {\n    transform: scaleX(1.04) scaleY(1.04); /* Increase size by 5% */\n    transition: transform 0.3s ease-in-out; /* Apply transition */\n    /* text-decoration: underline; */\n    /* color:blue */\n  }\n  \n \n  \n  .hoverb {\n    transition: transform 0.3s ease-in-out; /* Apply transition */\n  }\n\n  @media screen and (max-width: 767px) {\n    .wrapSubCard{\n        flex-wrap: wrap;\n        justify-content: center;\n        margin-top: 50px;\n        margin-bottom: 50px;\n      }\n      .textM{\n        margin-left: 0px;\n        margin-right: 0x;\n        margin-top: 30px;\n        text-align: center;\n      }\n      .imgM{\n        align-self: center;\n      }\n      \n}\n@media screen and (min-width: 768px) {\n    .wrapSubCard{\n        flex-wrap: nowrap;\n        justify-content: start;\n        margin-top: 30px;\n        margin-bottom: 30px;\n      }\n      .textM{\n        margin-left: 20px;\n        margin-right: 0x;\n        text-align: start;\n      }\n      .imgM{\n        align-self: start;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
