import React, { useState, useEffect } from 'react'
import './Home.css'
import img1 from './assets/ppp1.webp'
import img2 from './assets/p2.webp'
import img3 from './assets/p3.webp'
import img4 from './assets/p4.webp'

const HomeS3Manager = () => {

    const [selected, setSelected] = useState('1')

    useEffect(() => {
        console.log(selected)
    }, [selected])

    return (
        <div id='Features' className="homepage-banner  " style={{ paddingTop: '30px', paddingBottom: '30px', backgroundColor: 'white', color: 'black' }}>
            <div className=" text-center pt-5 px-2">
                <h1 className='banner-font-size ' style={{ color: '#5B058F' }}>Unlock Weekly Podcast Summaries for Just $9/Month</h1>
                <p className="lead pt-5 fs-5 fw-bold col-lg-7 mb-0 mx-auto " style={{ color: '#FF8730' }}>Podcast Summarize: Your Ultimate Podcast Assistant. Absorb more, faster than ever.  Let Podcast  </p>
                <p className="lead pt-1 fs-5 fw-bold mt-0 col-lg-7 mx-auto " style={{ color: '#FF8730' }}>Summarize distill the essence of podcasts for you, so you can focus on learning and growing. </p>
                <div className='py-5 d-xl-flex justify-content-around flex-wrap d-none'>
                    <div className='col-xl-4 col-11 pt-0 pe-0' id='text-part'>
                        <div onClick={(e) => setSelected('1')} className={` my-3 px-3 py-1 rounded-3 hoverB ${selected === '1' ? 'selectB' : ''}`}>
                            <h5 className='text-start texthovc fs-3  py-4' style={{  }}><span className='py-3' style={{ textDecoration:'underline 1px', textUnderlineOffset:'5px'}}>Discover</span> New Horizons</h5>
                            <p className='text-start texthovc' style={{}}>Begin your journey with Podcast Summarize by exploring an extensive library of podcasts across various genres.</p>
                        </div>
                        <div onClick={(e) => setSelected('2')} className={` my-3 px-3 py-1 rounded-3 hoverB ${selected === '2' ? 'selectB' : ''}`}>
                            <h5 className='text-start  texthovc fs-3  py-4' style={{  }}><span className='py-3' style={{textDecoration:'underline 1px', textUnderlineOffset:'5px'}}>Curate</span> Your Listening Experience</h5>
                            <p className='text-start texthovc ' style={{}}>Take control of your podcast listening with the ability to select up to five podcasts for personalized summaries. </p>
                        </div>
                        <div onClick={(e) => setSelected('3')} className={` my-3 px-3 py-1 rounded-3 hoverB ${selected === '3' ? 'selectB' : ''}`}>
                            <h5 className='text-start  texthovc fs-3  py-4' style={{  }}><span className='py-3' style={{textDecoration:'underline 1px', textUnderlineOffset:'5px'}}>Weekly</span> Summaries, Delivered</h5>
                            <p className='text-start texthovc ' style={{}}>Every week, receive a concise, insightful summary of your chosen podcasts directly in your inbox. </p>
                        </div>
                        <div onClick={(e) => setSelected('4')} className={` my-3 px-3 py-1 rounded-3 hoverB ${selected === '4' ? 'selectB' : ''}`}>
                            <h5 className='text-start  texthovc fs-3  py-4' style={{  }}><span className='py-3' style={{textDecoration:'underline 1px', textUnderlineOffset:'5px'}}>Seamless</span> Listening with a Single Click</h5>
                            <p className='text-start texthovc ' style={{}}>Embedded within each summary are direct links to the full podcast episodes. Whether a particular summary piques your interest or you wish to explore a topic in-depth, you're just a click away from listening to the episode in its entirety.</p>
                        </div>
                    </div>
                    <div className='col-xl-7 col-11 py-4 d-flex' id='image-aprt' style={{ height: 'auto' }}>
                        {
                            selected === '1' ? <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img1} style={{ height: '100%' }} /></div> : ''
                        }
                        {
                            selected === '2' ? <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img2} style={{ height: '100%', width: 'auto' }} /></div> : ''
                        }
                        {
                            selected === '3' ? <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img3} style={{ height: '100%', width: 'auto' }} /></div> : ''
                        }
                        {
                            selected === '4' ? <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img4} style={{ height: '100%', width: 'auto' }} /></div> : ''
                        }
                    </div>
                </div>

                <div className='py-5 d-flex justify-content-around flex-wrap d-xl-none'>
                    <div className={`p-3 rounded-3 mx-3 me-auto mt-5`}>
                        <h5 className='text-start' style={{ color: 'black' }}>Discover New Horizons</h5>
                        <p className='text-start' style={{}}>Begin your journey with Podcast Summarize by exploring an extensive library of podcasts across various genres.</p>
                    </div>
                    <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img1} style={{ height: '100%' }} /></div>
                    
                    <div className={`p-3 mx-3 rounded-3 me-auto mt-5`}>
                        <h5 className='text-start ' style={{ color: 'black' }}>Curate Your Listening Experience</h5>
                        <p className='text-start ' style={{}}>Take control of your podcast listening with the ability to select up to five podcasts for personalized summaries. </p>
                    </div>
                    <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img2} style={{ height: '100%' }} /></div>

                    <div className={`p-3 mx-3 rounded-3 me-auto mt-5`}>
                        <h5 className='text-start ' style={{ color: 'black' }}>Weekly Summaries, Delivered</h5>
                        <p className='text-start ' style={{}}>Every week, receive a concise, insightful summary of your chosen podcasts directly in your inbox. </p>
                    </div>
                    <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img3} style={{ height: '100%' }} /></div>

                    <div className={`p-3 mx-3 rounded-3 me-auto mt-5`}>
                        <h5 className='text-start ' style={{ color: 'black' }}>Seamless Listening with a Single Click</h5>
                        <p className='text-start ' style={{}}>Embedded within each summary are direct links to the full podcast episodes. Whether a particular summary piques your interest or you wish to explore a topic in-depth, you're just a click away from listening to the episode in its entirety.</p>
                    </div>
                    <div className='col-xl-12 align-items-center d-flex justify-content-center'>< img loading='lazy' className='col-11 rounded-4 img-fluid' src={img4} style={{ height: '100%' }} /></div>

                </div>

            </div>
        </div>
    )
}

export default HomeS3Manager















