import React from 'react'
import Footer2 from '../Components/Common/Footer2'
import Section1PodcastersDetails from '../Components/PodcastersDetails/Section1PodcastersDetails';
import { ToastContainer, toast } from 'react-toastify';
import NavbarTwo from '../Components/Common/NavbarTwo';

const PodcastersDetails = () => {
  return (
    <div>
         <NavbarTwo/>
        <Section1PodcastersDetails/>
        <Footer2/>
    </div>
  )
}

export default PodcastersDetails