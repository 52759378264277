import React from 'react'
import NavbarTwo from '../Components/Common/NavbarTwo';
import Section1Privacy from '../Components/Privacy/Section1Privacy';

const PrivacyPolicy = () => {
    return (
        <div>
            <NavbarTwo />          
<Section1Privacy/>
        </div>
    )
}

export default PrivacyPolicy