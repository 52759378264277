import React, { useState,useEffect , useRef} from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import img1 from './assets/1.jpg'
import axios from 'axios';
import Navbar from '../Components/Common/Navbar';
import {ThreeDots} from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import NavbarTwo from '../Components/Common/NavbarTwo';
import Footer from '../Components/Common/Footer';


const ForgotPassword = () => {


    const BACKEND = process.env.REACT_APP_URL

    const navigate = useNavigate();

    const[username,setUsername]=useState('')
    const[OTP,setOTP]=useState('')
    const[loading, setLoading] = useState(false);
    const[loading2, setLoading2] = useState(false);
    const[loading3, setLoading3] = useState(false);
    const[showForm,setShowFrom]=useState(false)
    const[showP,setShowP]=useState(false)
    const[password,setPassword]=useState('')
    const[confirmPassword,setConfirmPassword]=useState('')
    const[showMainform,setShowmainform]=useState(true)
    const confirmpasswordRef = useRef(null);


    const handleOTP=(e)=>{
        e.preventDefault()
        setLoading(true)

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(username)) {
          toast.error("Invalid Email Format");
          setLoading(false);
          console.log(username)
          return;
        }

        axios.post(`${BACKEND}signup/forgetpassword`,{username},{
            headers: {
              'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
          }).then(res=> {if(res.data == 'OK'){ setLoading(false);setShowFrom(true);setShowmainform(false);console.log(res.data);toast.success('Verification Code Sent')}
          else if(res.data){ setLoading(false);console.log(res.data);toast.error(res.data)}})
          .catch(err=>{ setLoading(false);console.log(err);toast.error('Some Error Occured')})
    }

    const handleOTPFP=(e)=>{
        e.preventDefault()
        setLoading2(true)

        if (OTP === '' || OTP.length === 0) {
          toast.error("Verification Code Required");
          setLoading2(false);
          console.log(username)
          return;
        }

        axios.post(`${BACKEND}signup/forgototp`,{OTP,username},{
            headers: {
              'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
          }).then(res=> {if(res.data == 'OK'){ setLoading2(false);setShowFrom(false);setShowP(true);console.log(res.data);toast.success('Password Change Allowed');setOTP('')}
          else if(res.data){ setLoading2(false);console.log(res.data);toast.error(res.data);setShowFrom(false);setShowmainform(true);setOTP('')}})
          .catch(err=>{ setLoading2(false);console.log(err);toast.error('Some Error Occured');setShowFrom(false);setShowmainform(true);setOTP('')})
    }

    const handlePass=(e)=>{
        e.preventDefault()
        setLoading3(true)

        if (password.length < 8) {
            console.log("pass")
            toast.error("Password must be at least 8 characters long");
            setLoading3(false);
            return;
          }
          const passRegex = /\s{2,}/;
          if (passRegex.test(password)) {
            toast.error("Invalid Password Format, 2 consecutive white spaces");
            setLoading3(false);
            return;
          }
          if (password !== confirmPassword) {
            console.log("pass")
            toast.error("Password and Confirm Password Don't Match !!!");
            setLoading3(false);
            return;
          }


        axios.post(`${BACKEND}signup/pass`,{password,username},{
            headers: {
              'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
          }).then(res=> {if(res.data == 'OK'){ setLoading3(false);setShowP(false);console.log(res.data);toast.success('Password Changed Successfully');setTimeout(() => { navigate('/signin') }, 2000)}
          else if(res.data){ setLoading3(false);console.log(res.data);toast.error(res.data);setShowP(false)}})
          .catch(err=>{ setLoading3(false);console.log(err);toast.error('Some Error Occured');setShowP(false)})
    }

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
          handleOTP(e);
      }
    };

    const handleKeyPress2 = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
          handleOTPFP(e);
      }
    };

    const handleKeyPress3 = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (e.target.id === 'password') {
          confirmpasswordRef.current.focus();
        } else {
          handlePass(e);
        }
      }
    };


  return (
    <>
    <NavbarTwo/>
    <div >
     
    <div className='' style={{paddingTop:'100px',paddingBottom:'150px',   height:'80vh'}} >
 <div className='d-flex justify-content-center col-12'  >

   </div>

   {
     showMainform ?
     <div className='d-flex justify-content-center col-12'  >
          <form action="#" className="mt-4 register-form rounded-3  mx-1 " style={{width:'300px', paddingTop:'0px',minWidth:'270px',backgroundColor:'white',  border:'1px  lightgrey'}}>

<div className="row py-3">
<p className='fw-bold text-start fs-4 px-0 my-0'>Forgot Password</p>
        <p className=' text-start px-0'>Receive Verification Code</p>

 <div className="col-12 px-0 py-3">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Email"
          id="nameFreelancer"
          required
          aria-label="Name"
          value={username}
          onKeyPress={handleKeyPress}

          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <button
        type="button"
        onClick={handleOTP}
        className="btn btn-primary col-12 mx-auto"
      >
        Send Verification Code
      </button>
    </div>

 <div className='form-group d-flex justify-content-center mt-4'>
{loading ? (
   <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
  
 ) : (
   ''
 )}
 </div>



</div>
</form>
</div>:<></>
   }


{
   showForm ?
   <div className='d-flex justify-content-center col-12'  >
  <form action="#" className="mt-4 register-form rounded-3 mx-1" style={{ width: '300px', paddingTop: '50px', minWidth: '270px', backgroundColor: 'white', border: '1px lightgrey' }}>

<div className="row py-3">
  <p className='fw-bold text-start fs-4 px-0 my-0'>Verification Code</p>
  <p className=' text-start px-0'>Enter Verification Code</p>

  <div className="col-12 px-0 py-3">
    <label htmlFor="text" className="mb-1 fw-bold text-black d-flex justify-content-start">
      Verification Code<span className="text-danger">*</span>
    </label>
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control"
        placeholder="Verification Code"
        id="nameFreelancer"
        required
        aria-label="Name"
        value={OTP}
        onKeyPress={handleKeyPress2}
        onChange={(e) => setOTP(e.target.value)}
      />
    </div>
  </div>
  <button type="button" onClick={handleOTPFP} className="btn btn-primary col-12 mx-auto">Submit Verification Code</button>

  <div className='form-group d-flex justify-content-center mt-4'>
    {/* You can replace the below code with your loading indicator */}
    {loading2 ? (
      <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
    ) : (
      ''
    )}
  </div>
</div>
</form>
</div>:<></>
}

{
   showP ?
   <div className='d-flex justify-content-center col-12'  >
   <form action="#" className="mt-4 register-form rounded-3 mx-1" style={{ width: '300px', paddingTop: '50px', minWidth: '270px', backgroundColor: 'white', border: '1px lightgrey' }}>

<div className="row py-3">
  <p className='fw-bold text-start fs-4 px-0 my-0'>Reset Password</p>
  <p className='text-start px-0'>Enter New Password</p>

  <div className="col-12 px-0 pt-3 pb-1">
    <label htmlFor="password" className="mb-1 fw-bold text-black d-flex justify-content-start">
      New Password<span className="text-danger">*</span>
    </label>
    <div className="input-group mb-3">
      <input
        type="password"
        className="form-control"
        placeholder="Password"
        id="password"
        required
        aria-label="New Password"
        value={password}
        onKeyPress={handleKeyPress3}
        tabIndex={1}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
  </div>
  <div className="col-12 px-0 pt-1 pb-3">
    <label htmlFor="confirmPassword" className="mb-1 fw-bold text-black d-flex justify-content-start">
      Confirm Password<span className="text-danger">*</span>
    </label>
    <div className="input-group mb-3">
      <input
        type="password"
        className="form-control"
        placeholder="Confirm Password"
        id="confirmPassword"
        required
        aria-label="Confirm Password"
        value={confirmPassword}
        onKeyPress={handleKeyPress3}
        tabIndex={2}
        ref={confirmpasswordRef}
        
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
    </div>
  </div>
  <button type="button" onClick={handlePass} className="btn btn-primary col-12 mx-auto">Set New Password</button>

  <div className='form-group d-flex justify-content-center mt-4'>
    {/* You can replace the below code with your loading indicator */}
    {loading3 ? (
      <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
    ) : (
      ''
    )}
  </div>
</div>
</form>
</div>:<></>
}




</div>
<ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />

       </div>
       {/* <Footer/> */}
</>
  )
}

export default ForgotPassword