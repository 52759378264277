// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  /* Apply background images when screen width is below 992px (lg) */
  @media (max-width: 992px) {
   .image-below-lg-noshow{
    display: none;
   }
  }
  @media (min-width: 993px) {
   .image-below-lg-show{
    display: none;
   }
  }
  
  /* Button gradient styling */
  .btn-gradient {
    background: linear-gradient(to bottom, #C362FF, #AD26FF);
    border: none;
    color: white;
    transition: background-color 0.3s ease-in-out;
  }
  
  /* .btn-gradient:hover {
    background: linear-gradient(to bottom, #AD26FF, #C362FF);
  } */`, "",{"version":3,"sources":["webpack://./src/Page/btngradient.css"],"names":[],"mappings":";;EAEE,kEAAkE;EAClE;GACC;IACC,aAAa;GACd;EACD;EACA;GACC;IACC,aAAa;GACd;EACD;;EAEA,4BAA4B;EAC5B;IACE,wDAAwD;IACxD,YAAY;IACZ,YAAY;IACZ,6CAA6C;EAC/C;;EAEA;;KAEG","sourcesContent":["\n  \n  /* Apply background images when screen width is below 992px (lg) */\n  @media (max-width: 992px) {\n   .image-below-lg-noshow{\n    display: none;\n   }\n  }\n  @media (min-width: 993px) {\n   .image-below-lg-show{\n    display: none;\n   }\n  }\n  \n  /* Button gradient styling */\n  .btn-gradient {\n    background: linear-gradient(to bottom, #C362FF, #AD26FF);\n    border: none;\n    color: white;\n    transition: background-color 0.3s ease-in-out;\n  }\n  \n  /* .btn-gradient:hover {\n    background: linear-gradient(to bottom, #AD26FF, #C362FF);\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
