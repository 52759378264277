import React from 'react'
import NavbarTwo from '../Components/Common/NavbarTwo';
import Section1Terms from '../Components/Terms/Section1Terms';


const TermsAndConditions = () => {
  return (
    <div>
        <NavbarTwo/>
    <Section1Terms/>
    </div>
  )
}

export default TermsAndConditions