// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `liBB::before {
    content: "• ";
    color: black; /* or whatever color you prefer */
    padding-right: 5px;
  }

  liBB {
    list-style-type: none; /* Remove the default list style */
    counter-reset: my-counter; /* Reset the counter */
    display: flex; /* Use flexbox to align dt and number on the same line */
  }
  

  .BulletPoint_numbered-list__9RAJh {
    list-style-type: none; /* Remove the default list style */
    counter-reset: my-counter; /* Reset the counter */
  }
  
  .BulletPoint_list-item__qor1W {
    display: flex; /* Use flexbox to align dt and number on the same line */
  }
  
  .BulletPoint_list-item__qor1W::before {
    content: counter(my-counter); /* Display the counter value as list marker */
    counter-increment: my-counter; /* Increment the counter for each item */
    color: black; /* Set the color for the numbers */
    font-weight: bold; /* Set the font weight for the numbers */
    margin-right: 5px; /* Optional: Add spacing between number and dt */
    font-size:x-large;
  }
  
  .BulletPoint_list-item-content__gRlMJ {
    flex: 1 1; /* Fill the remaining space to align dt and dd */
  }
  
  olBB{
    padding-left:'0px'
  }`, "",{"version":3,"sources":["webpack://./src/Components/Privacy/BulletPoint.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY,EAAE,iCAAiC;IAC/C,kBAAkB;EACpB;;EAEA;IACE,qBAAqB,EAAE,kCAAkC;IACzD,yBAAyB,EAAE,sBAAsB;IACjD,aAAa,EAAE,wDAAwD;EACzE;;;EAGA;IACE,qBAAqB,EAAE,kCAAkC;IACzD,yBAAyB,EAAE,sBAAsB;EACnD;;EAEA;IACE,aAAa,EAAE,wDAAwD;EACzE;;EAEA;IACE,4BAA4B,EAAE,6CAA6C;IAC3E,6BAA6B,EAAE,wCAAwC;IACvE,YAAY,EAAE,kCAAkC;IAChD,iBAAiB,EAAE,wCAAwC;IAC3D,iBAAiB,EAAE,gDAAgD;IACnE,iBAAiB;EACnB;;EAEA;IACE,SAAO,EAAE,gDAAgD;EAC3D;;EAEA;IACE;EACF","sourcesContent":["liBB::before {\n    content: \"• \";\n    color: black; /* or whatever color you prefer */\n    padding-right: 5px;\n  }\n\n  liBB {\n    list-style-type: none; /* Remove the default list style */\n    counter-reset: my-counter; /* Reset the counter */\n    display: flex; /* Use flexbox to align dt and number on the same line */\n  }\n  \n\n  .numbered-list {\n    list-style-type: none; /* Remove the default list style */\n    counter-reset: my-counter; /* Reset the counter */\n  }\n  \n  .list-item {\n    display: flex; /* Use flexbox to align dt and number on the same line */\n  }\n  \n  .list-item::before {\n    content: counter(my-counter); /* Display the counter value as list marker */\n    counter-increment: my-counter; /* Increment the counter for each item */\n    color: black; /* Set the color for the numbers */\n    font-weight: bold; /* Set the font weight for the numbers */\n    margin-right: 5px; /* Optional: Add spacing between number and dt */\n    font-size:x-large;\n  }\n  \n  .list-item-content {\n    flex: 1; /* Fill the remaining space to align dt and dd */\n  }\n  \n  olBB{\n    padding-left:'0px'\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numbered-list": `BulletPoint_numbered-list__9RAJh`,
	"list-item": `BulletPoint_list-item__qor1W`,
	"list-item-content": `BulletPoint_list-item-content__gRlMJ`
};
export default ___CSS_LOADER_EXPORT___;
