// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 767px) {
    .responsive-side-nav-other{
        margin-left:0;
      }
  }

  @media only screen and (min-width: 768px) {
    .responsive-side-nav-other{
        margin-left:268px;
      }
  } `, "",{"version":3,"sources":["webpack://./src/Components/Common/sidenav.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;MACf;EACJ;;EAEA;IACE;QACI,iBAAiB;MACnB;EACJ","sourcesContent":["@media only screen and (max-width: 767px) {\n    .responsive-side-nav-other{\n        margin-left:0;\n      }\n  }\n\n  @media only screen and (min-width: 768px) {\n    .responsive-side-nav-other{\n        margin-left:268px;\n      }\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
