// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-border1 {
    border-bottom: 2px solid linear-gradient(
        to right,
        orange 0%,
        orange 55%,
        rgba(255, 165, 0, 0.5) 70%,
        #EFEFEF 85%,
      
      );
  }
  
`, "",{"version":3,"sources":["webpack://./src/Components/Home/home4.css"],"names":[],"mappings":"AAAA;IACI;;;;;;;OAOG;EACL","sourcesContent":[".custom-border1 {\n    border-bottom: 2px solid linear-gradient(\n        to right,\n        orange 0%,\n        orange 55%,\n        rgba(255, 165, 0, 0.5) 70%,\n        #EFEFEF 85%,\n      \n      );\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
