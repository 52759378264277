import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import { GoTriangleLeft } from "react-icons/go";
import { GoTriangleRight } from "react-icons/go";
import noimg from './assets/noimg.png'
import '../PodcastersEpisode/assets/podcast.css'
import { useNavigate } from 'react-router-dom';

const PodcastImage = ({ imageUrl, noimg, timeoutDuration = 2000 }) => {
    const [src, setSrc] = useState(imageUrl);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;

        const timer = setTimeout(() => {
            setSrc(noimg);
        }, timeoutDuration);

        img.onload = () => {
            clearTimeout(timer);
            setSrc(imageUrl);
        };

        img.onerror = () => {
            clearTimeout(timer);
            setSrc(noimg);
        };

        return () => clearTimeout(timer);
    }, [imageUrl, noimg, timeoutDuration]);

    return (
        <img
            style={{ minWidth: '200px', maxHeight: '500px', maxWidth: '500px', objectFit: 'cover', width: '100%' }}
            className="rounded-4 m-auto"
            alt="podcast"
            src={src}
        />
    );
};

const PodcastImage2 = ({ imageUrl, noimg, timeoutDuration = 2000 }) => {
    const [src, setSrc] = useState(imageUrl);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;

        const timer = setTimeout(() => {
            setSrc(noimg);
        }, timeoutDuration);

        img.onload = () => {
            clearTimeout(timer);
            setSrc(imageUrl);
        };

        img.onerror = () => {
            clearTimeout(timer);
            setSrc(noimg);
        };

        return () => clearTimeout(timer);
    }, [imageUrl, noimg, timeoutDuration]);

    return (
        <img
            style={{ minWidth: '150px', maxHeight: '150px', maxWidth: '150px', objectFit: 'cover', width: '100%' }}
            className="rounded-4 m-auto"
            alt="podcast"
            src={src}
        />
    );
};

const Section1PodcastersEpisode = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { category, id } = useParams(); // Access the id parameter
    // const [namePart, podIdPart, titlePart,episodePart] = id.split('&');
    const podId = ''
    console.log(category, '==', id, "-----------state legit id")

    const podcasterLink = category
    const podcasterLinkEp = id


    const [podEpisode, setPodEpisode] = useState([])
    const [podList, setPodlist] = useState([])
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')

    const handleLeft = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    const handleRight = () => {
        setPage(page + 1)
    }

    const transformTitle = (title) => {
        // Replace spaces with hyphens
        let transformed = title.replace(/ /g, '-');

        // Replace special characters except apostrophes and periods with hyphens
        transformed = transformed.replace(/[^\w'.-]/g, '-');

        return transformed;
    };

    const handlePodcasterDetail = (event) => {
        const podcasterLinkEp = event.target.getAttribute('data-episode');
        const podcasterLink = event.target.getAttribute('data-podTitle');
        const podId = event.target.getAttribute('data-podId');
        console.log(podcasterLinkEp, "---------119")
        navigate(`/podcasters/${transformTitle(podcasterLink)}/${transformTitle(podcasterLinkEp)}`)
    }

    const handleSearch = () => {
        // console.log(podId,page,podTitle)
        axios.post(`${process.env.REACT_APP_URL}podcastersSearchDetails`, { batch_size: 3, page_number: page, podcasterLink }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            // console.log(res.data)
            if (res.data[0].length === 0 && page > 1) {
                setPage(page - 1)
            } else {
                setPodlist(res.data)
                console.log(res.data, "--149")

            }
        }).catch(err => { console.log(err) })

    }

    const handleSearchTerm = () => {
        // console.log(searchTerm)
        if (searchTerm === '') {

            handleSearch()
            return
        }
        axios.post(`${process.env.REACT_APP_URL}podcastersSearchDetailsSearchTerm`, { batch_size: 3, page_number: page, podcasterLink, searchTerm }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            // console.log(res.data)
            if (res.data.length === 0 && page > 1) {
                setPage(page - 1)
            } else {
                setPodlist(res.data)
                // console.log(res.data)

            }
        }).catch(err => { console.log(err) })
    }

    useEffect(() => {
        //   console.log("----149",podTitle)
        if (searchTerm === '') {
            handleSearch()
        } else {
            handleSearchTerm()
        }

    }, [page])

    useEffect(() => {

        axios.post(`${process.env.REACT_APP_URL}podcastersEpisode`, { podcasterLink, podcasterLinkEp }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            // console.log(res.data)
            setPodEpisode(res.data)
            console.log(res.data, "-----202")
        }).catch(err => { console.log(err) })

    }, [category, id])

    function renderSummary(summary) {
        // Split the summary into paragraphs based on <br> tags
        const paragraphs = summary.split('<br>');

        // Map each paragraph to a <p> tag
        const renderedParagraphs = paragraphs.map((paragraph, index) => (
            <p className='d-flex justify-content-start text-start' key={index}>{paragraph.replace(/&bull;/g, '•')}</p>
        ));

        return renderedParagraphs;
    }

    return (

        <div>
            <div className=" mx-0  flex flex-col" style={{ backgroundColor: "#7D88FF", minHeight: '80vh' }}>
                <div className="">


                    <div className="d-flex justify-content-center mx-auto row" style={{ minWidth: '100%' }}>
                        {podEpisode.map((item, index) => (
                            <>
                                <div className='row col-xxl-10 col-lg-11  col-md-9'>
                                    <div className='col-lg-6'>
                                        <img src={item.image || noimg} className="card-img-top mx-auto mt-5 rounded-5" style={{}} alt='' onError={(e) => { e.target.src = noimg }} />
                                    </div>
                                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                                        <h1 className="card-title my-1 text-white">{item.podTitle}</h1>
                                    </div>
                                    
                                    
                                </div>

                                <div className='row col-xxl-10 col-lg-11 mb-3 col-md-9'>
                                    <div className='col-lg-6 pe-xl-5 pe-2'>
                                        <h3 className="card-title mt-5 mb-1 text-start text-white ">{item.title}</h3>
                                        <h6 className="card-title mt-3 mb-4  text-start text-white ">{item.airDate}</h6>
                                        <p className='fs-5 text-start text-white'>{renderSummary(item.summary)}</p>
                                    </div>
                                    <div className="col-lg-6 d-flex ps-xl-5 ps-2 flex-wrap align-items-start">

                                        <div className="row mx-0 mt-2">
                                            <p className="card-title mb-5 fs-2 text-start text-white col-12 mt-4 mt-xl-0 " style={{ color: 'white', textDecoration: 'underline #FFAA6C 1px', textUnderlineOffset: '15px' }}>Other <span style={{ color: '#FFAA6C' }}>Episodes</span></p>

                                            {podList.length > 0 && podList[0].length > 0 && podList[0].map((item, index) => (
                                                <div key={index} className="d-flex pe-2  wrapSubCardsumpd" style={{ maxWidth: '900px' }}>
                                                    <PodcastImage2 imageUrl={item.image || noimg} noimg={noimg} style={{width:'180px'}} timeoutDuration={5000} />
                                                    <div className="d-flex flex-column me-sm-auto ms-sm-3 mx-auto " style={{ color: 'white',width:'100%' }}>
                                                        {/* <h4 className='text-md-start text-center mt-md-0 mt-5'>Testing title {index*1000000}</h4> */}
                                                        <h4 className='text-md-start text-center mt-md-0 mt-5'>{item.title}</h4>
                                                        <h6 className='text-md-start text-center mt-md-2 mt-2'>{item.airDate}</h6>
                                                        <button
                                                            type="button"
                                                            value={item.podId}
                                                            className="btn rounded-pill px-5 fw-bold mt-md-auto mt-3 mx-auto  mx-md-0"
                                                            style={{ backgroundColor: '#FFAA6C', color: 'white', maxWidth: '150px' }}
                                                            data-episode={item.PodcasterLinkEp} data-podTitle={item.PodcasterLink} data-podId={item.podId}
                                                            onClick={handlePodcasterDetail}
                                                        >
                                                            Details
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className='mb-3'>
                                                <button className='' onClick={(() => handleLeft())}><GoTriangleLeft  /></button>
                                                
                                                <span className='fs-4 p-3'>{page}</span>
                                                <button className='' onClick={(() => handleRight())}> <GoTriangleRight  /></button>
                                               
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1PodcastersEpisode