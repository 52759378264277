import React, { useState, useEffect } from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import './Home.css'
import Cookies from 'js-cookie';
import {  useNavigate } from 'react-router-dom';

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomeS5Pricing = () => {

    const navigate = useNavigate();

   const token = Cookies.get('token')

   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleSub = async (key) => {
        setIsButtonDisabled(true);
        console.log(key)

        if(token === undefined){
            toast.error("You need to be Logged In")
            setTimeout(() => {
                navigate('/signin')
            }, 2000);
        }
        
        await axios.post(`${process.env.REACT_APP_URL}stripepay/create-checkout-session`, { key }, 
        {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json' // Optional: Specify content type if needed
            }
          }).then(res => { window.location.replace(res.data); console.log(res); })
          .catch(err => {console.log("handle subscribe error = ", err.response.data); setIsButtonDisabled(false); })
    }

    useEffect(() => {
        const checkSubscriptionStatus = async () => {
            if (token !== undefined) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_URL}api/chksubstatus`, {}, 
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json' // Optional: Specify content type if needed
                            }
                        }
                    );
                    console.log('52------',response)
                    if (response.data === 'Expired') {
                        toast.info('Kindly Buy/Renew your Subscription plan');
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        };
    
        checkSubscriptionStatus();
    }, []);

    return (
        <div id='Pricing' className="homepage-banner  " style={{ paddingTop: '100px', paddingBottom: '100px', backgroundColor: 'white' }}>
            <div className="container text-center py-5 col-lg-5">
                <h1 className='banner-font-size ' style={{ color: '#5B058F' }} >Pricing for Everyone</h1>
                <p className="lead pt-3 fw-bold" style={{ color: '#FF8730' }}>We have made Podcast Summarize simple to afford and we will be adding features to this.</p>
                
            </div>
            <div className='d-flex justify-content-center row' style={{width:'100%'}}>
            
            <div className="card width-card rounded-4 col-lg-3 col-sm-7 col-10 mx-3 my-2 pe-0 py-4" style={{ minHeight: '350px', backgroundColor: '#FFF7F1', borderTop: '4px solid #FF8730', borderBottom: '0px solid transparent', borderRight: '0px solid transparent', borderLeft: '0px solid transparent', boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1), 0px 14px 28px rgba(0, 0, 0, 0.1)' }}>
    <div className="card-body d-flex flex-column justify-content-between">
        <div>
            <h5 className="card-text text-start fs-3" style={{ color: 'black' }}>Try it For Free</h5>
            <p className='text-start mt-3' style={{ color: 'black' }}>Any one Podcast you can get a summary for free. It's free forever.</p>
            <h1 className='text-start' style={{ color: '#FF8730' }}>Get it for 0$</h1>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#FF8730' }} /> Just one Podcast per week
            </div>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#FF8730' }} /> Additional features won't be applicable
            </div>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#FF8730' }} /> Change as Needed
            </div>
        </div>
        <button onClick={() => handleSub('Free')} type="button" style={{ width: '100%', color: 'white', backgroundColor: '#FF8730' }} className="btn py-3 rounded-pill mt-auto fw-bold">Get A Podcast Summary Weekly</button>
    </div>
</div>

                    <div className="card width-card rounded-4 col-lg-3 col-sm-7 col-10 mx-3 my-2 py-4" style={{ minHeight: '350px', backgroundColor: '#EFEFEF', borderColor: '#8906E6', borderTop: '4px solid #7407FF', borderBottom: '0px solid transparent', borderRight: '0px solid transparent', borderLeft: '0px solid transparent', boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1), 0px 14px 28px rgba(0, 0, 0, 0.1)' }}>
    <div className="card-body d-flex flex-column justify-content-between pe-0">
        <div>
            <h5 className="card-text text-start fs-3 pe-0" style={{ color: 'black' }}>Monthly</h5>
            <p className='text-start mt-3' style={{ color: 'black' }}>Get Podcast Summaries for up to 5 Podcasts per week</p>
            <h1 className='text-start' style={{ color: '#7407FF' }}>$9</h1>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#7407FF' }} /> Improved features added on as available
            </div>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#7407FF' }} /> Send Roadmap Recommendations
            </div>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#7407FF' }} /> Customer Support over Twitter
            </div>
        </div>
        {
            token !== '' || token !== undefined ? (
                <button onClick={() => handleSub('Monthly')}  disabled={isButtonDisabled} type="button" style={{ width: '100%', backgroundColor: '#7407FF', color: 'white' }} className="btn py-3 rounded-pill mt-auto fw-bold">Get 5 Podcast Summaries Weekly</button>
            ) : (
                <button onClick={() => handleSub('Monthly')}  disabled={isButtonDisabled} type="button" style={{ width: '100%', backgroundColor: '#7407FF', color: 'white' }} className="btn py-3 rounded-pill mt-auto fw-bold">Get 5 Podcast Summaries</button>
            )
        }
    </div>
</div>

                    <div className="card width-card rounded-4 col-lg-3 col-sm-7 col-10 mx-3 my-2 py-4" style={{ minHeight: '350px', backgroundColor: '#F8F3FF', borderTop: '4px solid #7407FF', borderBottom: '0px solid transparent', borderRight: '0px solid transparent', borderLeft: '0px solid transparent', boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1), 0px 14px 28px rgba(0, 0, 0, 0.1)' }}>
    <div className="card-body d-flex flex-column justify-content-between pe-0">
        <div>
            <h5 className="card-text text-start fs-3" style={{ color: 'black' }}>Annual</h5>
            <p className='text-start mt-3' style={{ color: 'black' }}>Get committed to increasing your Podcast knowledge for the entire year.</p>
            <h1 className='text-start' style={{ color: '#7407FF' }}><span style={{ textDecoration: 'line-through' }}>$108</span> $90</h1>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#7407FF' }} /> A full year of Podcast Summarize
            </div>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#7407FF' }} /> New features added frequently
            </div>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#7407FF' }} /> Customer support on Twitter
            </div>
            <div className='col-12 container text-start mb-4 break-words' style={{ color: 'black' }} >
                <FaRegCheckCircle className='mb-1 me-2' style={{ color: '#7407FF' }} /> Roadmap review every month
            </div>
        </div>
        <button onClick={() => handleSub('Yearly')}  disabled={isButtonDisabled} type="button" style={{ width: '100%', color: 'white', backgroundColor: '#7407FF' }} className="btn py-3 fw-bold rounded-pill mt-auto">Get 5 Podcast Summaries Weekly</button>
    </div>
</div>
                </div>
            
        </div>
    )
}

export default HomeS5Pricing