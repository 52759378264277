import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

function CustomTooltip2({ payload, label, active }) {
  if (active) {
    return (
      <div className="custom-tooltip " style={{ backgroundColor: '#FFFFFF', color: 'black' }} >
         {
          payload.length !== 0 ? 
        <><p className="label">{`${label}`}</p> <p>Token Usage {`${payload[0].value}`}</p></>:''
         }
      </div>
    );
  }
  return null;
}

function ClaudePopup({ detail, showModalClaude, handleClose }) {

  const [data, setData] = useState([]);
  const [adminToken, setAdminToken] = useState('');
  const [inputDate, setInputDate] = useState('');
  const [filteredDataG, setFilteredDataG] = useState([]);
  const [minHeight, setMinHeight] = useState(500);

  useEffect(() => {
    setAdminToken(Cookies.get('admin_token'));
  }, []);

  useEffect(() => {
    if (adminToken !== '') {
      axios.post(`${process.env.REACT_APP_URL}StatsClaude`, {}, {
        headers: {
          'Authorization': `Bearer ${adminToken}`,
          'Content-Type': 'application/json'
        }
      }).then(res => {
        setData(res.data[1]);
        setFilteredDataG(res.data[1]); // Set initial data to display
        console.log(res.data)
      }).catch(err => console.log(err));
    }
  }, [adminToken]);

  const handleDateChange = (e) => {
    setInputDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data)
    if (data === 0) {
      console.log('inside')
      toast.error("Data Doesn't exist for this date ");
      return
    }

    const filtered = data.filter((item) => {
      const itemDate = item.date;
      return itemDate.startsWith(inputDate);
    });
    console.log(data)
    setFilteredDataG(filtered);
    if (filtered.length === 0) {
      toast.error("Data Doesn't exist for this date ");
    }
  };

  return (
    <Modal show={showModalClaude} onHide={handleClose}  fullscreen={true} >
      <Modal.Header  closeButton>
        <Modal.Title>Claude Token Usage per Week</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fs-4 d-flex justify-content-center  text-center mt-1 mb-5'>
          <div className="input-group mb-3 mx-2 " >
            <input type="text" value={inputDate} onChange={handleDateChange} className="form-control col-lg-6" placeholder="YYYY-MM-DD, YYYY-MM, or YYYY" aria-label="Recipient's username" aria-describedby="button-addon2" />
            <button className="btn btn-secondary" type="button" id="button-addon2" onClick={handleSubmit}>Generate Graph</button>
          </div>
        </div>
        <div className='fs-4 col-12 d-flex justify-content-center  text-center mb-5 mt-3' style={{ color: '#FFFFFF' }}>
          {filteredDataG.length === 0 || filteredDataG === 0 ? <h1 style={{color:'black'}}>No Data</h1> :
            <ResponsiveContainer width="100%" minHeight={minHeight}>
              <LineChart width={1000} height={800} data={filteredDataG} margin={{ top: 5, right: 20, bottom: 5, left: 10 }}>
                <Line type="monotone" dataKey="CreatedWeekly" stroke="#8884d8" />
                <XAxis dataKey="date" minTickGap={10} style={{ fontSize: '14px' }} />
                <YAxis style={{ fontSize: '14px' }} allowDataOverflow={true} />
                <Tooltip content={<CustomTooltip2 />} />
              </LineChart>
            </ResponsiveContainer>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ClaudePopup;
