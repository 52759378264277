import React from 'react'
import NavbarTwo from '../Components/Common/NavbarTwo'
import Footer2 from '../Components/Common/Footer2'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import logo from './assets/logo.png'



const Cancel = () => {
  return (
    <div>
    <NavbarTwo />
    <div style={{ backgroundColor: '#89c8ff', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="card p-4" style={{ width: '300px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <img src={logo} className='mx-auto mb-3' style={{ height: '100px', width: '100px', borderRadius: '50%' }} alt="Logo" />
            <div className="card-body text-center">
                <h5 className="card-title mb-3">Cancellation Successful</h5>
                <p className="card-text">Your payment has been cancelled.</p>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <Button className='fw-bold fs-5 rounded-pill px-4 py-2 mt-3' style={{ color: '#FFFFFF', backgroundColor: '#242439', borderColor: '#242439' }} variant="primary">Back to Home</Button>
                </Link>
            </div>
        </div>
    </div>
    <Footer2 />
</div>
  )
}

export default Cancel