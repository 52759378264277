import React from 'react'

import Footer2 from '../Components/Common/Footer2'
import Section1Podcast from '../Components/Podcast/Section1Podcast'
import { ToastContainer, toast } from 'react-toastify';
import NavbarTwo from '../Components/Common/NavbarTwo';

const Podcast = () => {
  return (
    <div>
        <NavbarTwo/>
        <Section1Podcast/>
        <Footer2/>
    </div>
  )
}

export default Podcast