import {Routes,Route,BrowserRouter} from 'react-router-dom'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './Page/Home';
import Signup from './Page/Signup';
import Signin from './Page/Signin';
import Podcast from './Page/Podcast';
import SuperAdmin from './Page/SuperAdmin';
import Success from './Page/Success';
import Cancel from './Page/Cancel';
import SamplePod from './Page/SamplePod';
import ForgotPassword from './Page/ForgotPassword';
import PrivacyPolicy from './Page/PrivacyPolicy';
import TermsAndConditions from './Page/TermsAndConditions'
import Podcasters from './Page/Podcasters';
import PodcastersDetails from './Page/PodcastersDetails';
import PodcastersEpisode from './Page/PodcastersEpisode';

import ScrollToTop from './Page/ScrollToTop';

function App() {
  return (
    <div className="App" >
     <BrowserRouter>
     <ScrollToTop/>
     <Routes>
      <Route path='/' element={<Home/>}  />
      <Route path='/signup' element={<Signup/>}  />
      <Route path='/signin' element={<Signin/>}  />
      <Route path='/forget' element={<ForgotPassword/>}/>

      <Route path='/podcast' element={<Podcast/>}  />
      <Route path='/superadmin' element={<SuperAdmin/>}/>
      <Route path='/SuccessPage' element={<Success/>}/>
      <Route path='/CancelPage' element={<Cancel/>}/>
      <Route path='/sample' element={<SamplePod/>}/>
      <Route path='/privacy' element={<PrivacyPolicy/>}/>
      <Route path='/tc' element={<TermsAndConditions/>}/>
      <Route path='/podcasters' element={<Podcasters/>}/>
      <Route path='/podcasters/:id' element={<PodcastersDetails/>}/>
      <Route path='/podcasters/:category/:id' element={<PodcastersEpisode/>}/>




     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;


// //////////////  working gpt schema - fetch podcast from podchaser

// openapi: 3.0.0
// info:
//   title: Podchaser GraphQL API
//   description: This OpenAPI specification is designed to interact with the Podchaser API for searching podcasts using a corrected GraphQL query.
//   version: 1.0.0
// servers:
//   - url: https://api.podchaser.com
//     description: Main Podchaser API server
// paths:
//   /graphql:
//     post:
//       operationId: searchBusinessPodcasts
//       summary: Search for business podcasts with specific filters and pagination.
//       description: Executes a GraphQL query to search for podcasts that match the given criteria, including language, rating, and a specific search term.
//       requestBody:
//         required: true
//         content:
//           application/json:
//             schema:
//               type: object
//               properties:
//                 query:
//                   type: string
//                   example: |
//                     {
//                       podcasts(
//                         filters: {
//                           language: "en",
//                           rating: { minRating: 4, maxRating: 5 }
//                         },
//                         searchTerm: "business",
//                         first: 10,
//                         page: 1
//                       ) {
//                         paginatorInfo {
//                           count
//                           currentPage
//                           firstItem
//                           hasMorePages
//                           lastItem
//                           lastPage
//                           perPage
//                           total
//                         }
//                         data {
//                           id
//                           title
//                           description
//                           webUrl
//                           applePodcastsId
//                           imageUrl
//                         }
//                       }
//                     }
//       responses:
//         '200':
//           description: A successful response with the list of podcasts matching the search criteria.
//           content:
//             application/json:
//               schema:
//                 $ref: '#/components/schemas/PodcastSearchResponse'
//         '400':
//           description: Bad Request. The request was invalid or cannot be otherwise served.
//         '401':
//           description: Authentication credentials were missing or incorrect.
//         '500':
//           description: Internal Server Error. Something went wrong on the server's end.
// security:
//   - ApiKeyAuth: []
// components:
//   securitySchemes:
//     ApiKeyAuth:
//       type: apiKey
//       in: header
//       name: Authorization
//   schemas:
//     PodcastSearchResponse:
//       type: object
//       properties:
//         data:
//           type: object
//           properties:
//             podcasts:
//               type: object
//               properties:
//                 paginatorInfo:
//                   $ref: '#/components/schemas/PaginatorInfo'
//                 data:
//                   type: array
//                   items:
//                     $ref: '#/components/schemas/Podcast'
//     PaginatorInfo:
//       type: object
//       properties:
//         count:
//           type: integer
//         currentPage:
//           type: integer
//         firstItem:
//           type: integer
//         hasMorePages:
//           type: boolean
//         lastItem:
//           type: integer
//         lastPage:
//           type: integer
//         perPage:
//           type: integer
//         total:
//           type: integer
//     Podcast:
//       type: object
//       properties:
//         id:
//           type: string
//         title:
//           type: string
//         description:
//           type: string
//         webUrl:
//           type: string
//         applePodcastsId:
//           type: string
//         imageUrl:
//           type: string







// openapi: 3.0.0
// info:
//   title: Podchaser GraphQL API
//   description: This OpenAPI specification is designed to interact with the Podchaser API for searching podcasts using a GraphQL query with dynamic search terms.
//   version: 1.0.0
// servers:
//   - url: https://api.podchaser.com
//     description: Main Podchaser API server
// paths:
//   /graphql:
//     post:
//       operationId: searchPodcasts
//       summary: Search for podcasts with specific filters and dynamic search term.
//       description: Executes a GraphQL query to search for podcasts that match given criteria including language, rating, and a user-defined search term. The client is responsible for inserting the dynamic `searchTerm` into the GraphQL query before sending the request.
//       requestBody:
//         required: true
//         content:
//           application/json:
//             schema:
//               type: object
//               properties:
//                 query:
//                   type: string
//                   description: The GraphQL query string with a placeholder for the `searchTerm`. The client should replace `${searchTerm}` with the actual search term before sending the request.
//                   example: >
//                     {
//                       query: "\n            {\n                podcasts(\n                    filters: {\n                        language: \"en\",\n                        rating: { minRating: 4, maxRating: 5 }\n                    },\n                    first: 12,\n                    page: 1,\n                    searchTerm: \"${searchTerm}\"\n                ) {\n                    paginatorInfo {\n                        count\n                        currentPage\n                        firstItem\n                        hasMorePages\n                        lastItem\n                        lastPage\n                        perPage\n                        total\n                    }\n                    data {\n                        id\n                        title\n                        description\n                        webUrl\n                        applePodcastsId\n                        imageUrl\n                    }\n                }\n            }\n        "
//                 variables:
//                   type: object
//                   properties:
//                     searchTerm:
//                       type: string
//                   required:
//                     - searchTerm
//       responses:
//         '200':
//           description: A successful response with the list of podcasts matching the search criteria.
//           content:
//             application/json:
//               schema:
//                 $ref: '#/components/schemas/PodcastSearchResponse'
//         '400':
//           description: Bad Request. The request was invalid or cannot be otherwise served.
//         '401':
//           description: Authentication credentials were missing or incorrect.
//         '500':
//           description: Internal Server Error. Something went wrong on the server's end.
// security:
//   - ApiKeyAuth: []
// components:
//   securitySchemes:
//     ApiKeyAuth:
//       type: apiKey
//       in: header
//       name: Authorization
//   schemas:
//     PodcastSearchResponse:
//       type: object
//       properties:
//         data:
//           type: object
//           properties:
//             podcasts:
//               type: object
//               properties:
//                 paginatorInfo:
//                   $ref: '#/components/schemas/PaginatorInfo'
//                 data:
//                   type: array
//                   items:
//                     $ref: '#/components/schemas/Podcast'
//     PaginatorInfo:
//       type: object
//       properties:
//         count:
//           type: integer
//         currentPage:
//           type: integer
//         firstItem:
//           type: integer
//         hasMorePages:
//           type: boolean
//         lastItem:
//           type: integer
//         lastPage:
//           type: integer
//         perPage:
//           type: integer
//         total:
//           type: integer
//     Podcast:
//       type: object
//       properties:
//         id:
//           type: string
//           description: Unique podcast ID
//         title:
//           type: string
//         description:
//           type: string
//         webUrl:
//           type: string
//           description: URL to podcast page
//         applePodcastsId:
//           type: string
//           description: Apple Podcasts ID
//         imageUrl:
//           type: string
//           description: URL to podcast image




// openapi: 3.0.0
// info:
//   title: Podchaser GraphQL API
//   description: This OpenAPI specification is designed to interact with the Podchaser API for searching podcasts using a GraphQL query with dynamic search terms.
//   version: 1.0.0
// servers:
//   - url: https://api.podchaser.com
//     description: Main Podchaser API server
// paths:
//   /graphql:
//     post:
//       operationId: searchPodcasts
//       summary: Search for podcasts with specific filters and dynamic search term.
//       description: Executes a GraphQL query to search for podcasts that match given criteria including language, rating, and a user-defined search term.
//       requestBody:
//         required: true
//         content:
//           application/json:
//             schema:
//               type: object
//               properties:
//                 query:
//                   type: string
//                   example: |
//                     {
//                       query: "\n            {\n                podcasts(\n                    filters: {\n                        language: \"en\",\n                        rating: { minRating: 4, maxRating: 5 }\n                    },\n                    first: 12,\n                    page: 1,\n                    searchTerm: \"business\"\n                ) {\n                    paginatorInfo {\n                        count\n                        currentPage\n                        firstItem\n                        hasMorePages\n                        lastItem\n                        lastPage\n                        perPage\n                        total\n                    }\n                    data {\n                        id\n                        title\n                        description\n                        webUrl\n                        applePodcastsId\n                        imageUrl\n                    }\n                }\n            }\n        ".replace("business", ${searchTerm})
//                     }
//                 variables:
//                   type: object
//                   properties:
//                     searchTerm:
//                       type: string
//                   required:
//                     - searchTerm
//       responses:
//         '200':
//           description: A successful response with the list of podcasts matching the search criteria.
//           content:
//             application/json:
//               schema:
//                 $ref: '#/components/schemas/PodcastSearchResponse'
//         '400':
//           description: Bad Request. The request was invalid or cannot be otherwise served.
//         '401':
//           description: Authentication credentials were missing or incorrect.
//         '500':
//           description: Internal Server Error. Something went wrong on the server's end.
// security:
//   - ApiKeyAuth: []
// components:
//   securitySchemes:
//     ApiKeyAuth:
//       type: apiKey
//       in: header
//       name: Authorization
//   schemas:
//     PodcastSearchResponse:
//       type: object
//       properties:
//         data:
//           type: object
//           properties:
//             podcasts:
//               type: object
//               properties:
//                 paginatorInfo:
//                   $ref: '#/components/schemas/PaginatorInfo'
//                 data:
//                   type: array
//                   items:
//                     $ref: '#/components/schemas/Podcast'
//     PaginatorInfo:
//       type: object
//       properties:
//         count:
//           type: integer
//         currentPage:
//           type: integer
//         firstItem:
//           type: integer
//         hasMorePages:
//           type: boolean
//         lastItem:
//           type: integer
//         lastPage:
//           type: integer
//         perPage:
//           type: integer
//         total:
//           type: integer
//     Podcast:
//       type: object
//       properties:
//         id:
//           type: string
//           description: Unique podcast id used to recognize podcast
//         title:
//           type: string
//         description:
//           type: string
//         webUrl:
//           type: string
//         imageUrl:
//           type: string





// openapi: 3.0.0
// info:
//   title: Podchaser GraphQL API
//   description: This OpenAPI specification is designed to interact with the Podchaser API for searching podcasts using a GraphQL query with a dynamic search term.
//   version: 1.0.0
// servers:
//   - url: https://api.podchaser.com
//     description: Main Podchaser API server
// paths:
//   /graphql:
//     post:
//       operationId: searchPodcasts
//       summary: Search for podcasts with specific filters and pagination, including a dynamic search term.
//       description: Executes a GraphQL query to search for podcasts that match the given criteria, including language, rating, and a specific search term given by the user. The search term should be included directly in the GraphQL query string.
//       requestBody:
//         required: true
//         content:
//           application/json:
//             schema:
//               type: object
//               properties:
//                 query:
//                   type: string
//                   example: >
//                     query SearchPodcasts($language: String, $minRating: Float, $maxRating: Float, $searchTerm: String, $first: Int, $page: Int) {
//                       podcasts(
//                         filters: {
//                           language: $language,
//                           rating: { minRating: $minRating, maxRating: $maxRating }
//                         },
//                         searchTerm: $searchTerm,
//                         first: $first,
//                         page: $page
//                       ) {
//                         paginatorInfo {
//                           count
//                           currentPage
//                           firstItem
//                           hasMorePages
//                           lastItem
//                           lastPage
//                           perPage
//                           total
//                         }
//                         data {
//                           id
//                           title
//                           description
//                           webUrl
//                           applePodcastsId
//                           imageUrl
//                         }
//                       }
//                     }
//                 variables:
//                   type: object
//                   properties:
//                     language:
//                       type: string
//                       default: "en"
//                     minRating:
//                       type: float
//                       default: 4
//                     maxRating:
//                       type: float
//                       default: 5
//                     searchTerm:
//                       type: string
//                     first:
//                       type: int
//                       default: 10
//                     page:
//                       type: int
//                       default: 1
//       responses:
//         '200':
//           description: A successful response with the list of podcasts matching the search criteria.
//           content:
//             application/json:
//               schema:
//                 $ref: '#/components/schemas/PodcastSearchResponse'
//         '400':
//           description: Bad Request. The request was invalid or cannot be otherwise served.
//         '401':
//           description: Authentication credentials were missing or incorrect.
//         '500':
//           description: Internal Server Error. Something went wrong on the server's end.
// security:
//   - ApiKeyAuth: []
// components:
//   securitySchemes:
//     ApiKeyAuth:
//       type: apiKey
//       in: header
//       name: Authorization
//   schemas:
//     PodcastSearchResponse:
//       type: object
//       properties:
//         data:
//           type: object
//           properties:
//             podcasts:
//               type: object
//               properties:
//                 paginatorInfo:
//                   $ref: '#/components/schemas/PaginatorInfo'
//                 data:
//                   type: array
//                   items:
//                     $ref: '#/components/schemas/Podcast'
//     PaginatorInfo:
//       type: object
//       properties:
//         count:
//           type: integer
//         currentPage:
//           type: integer
//         firstItem:
//           type: integer
//         hasMorePages:
//           type: boolean
//         lastItem:
//           type: integer
//         lastPage:
//           type: integer
//         perPage:
//           type: integer
//         total:
//           type: integer
//     Podcast:
//       type: object
//       properties:
//         id:
//           type: string
//         title:
//           type: string
//         description:
//           type: string
//         webUrl:
//           type: string
//         applePodcastsId:
//           type: string
//         imageUrl:
//           type: string





/////////////////////////////////////////////////////////////////////////  podcasr summarizer schema gpt works

// openapi: 3.0.0
// info:
//   title: Podcast Summary API
//   description: API to receive a podcast ID, title, and email, then return a summary via email. This is a resource-intensive process that could take up to 30 minutes or more.
//   version: 1.0.0
// servers:
//   - url: https://podcastsummarize.com/service
//     description: Main API server
// paths:
//   /sendSumGPT:
//     post:
//       operationId: sendPodcastSummaryByEmail
//       summary: Receives podcast ID, title, and email to send a summary via email. This operation may take up to 30 minutes or more.
//       requestBody:
//         required: true
//         content:
//           application/json:
//             schema:
//               type: object
//               properties:
//                 podcastId:
//                   type: string
//                   description: The ID of the podcast.
//                 title:
//                   type: string
//                   description: The title of the podcast.
//                 email:
//                   type: string
//                   format: email
//                   description: The email address to send the podcast summary to.
//               required:
//                 - podcastId
//                 - title
//                 - email
//         responses:
//         '200':
//           description: Successfully processed the request. The podcast summary will be sent to the provided email. The operation may take up to 30 minutes or more to complete.
//           content:
//             application/json:
//               schema:
//                 type: object
//                 properties:
//                   summary:
//                     type: string
//                     example: "Summary sent to email"
//         default:
//           description: Unexpected error
//           content:
//             application/json:
//               schema:
//                 type: object
//                 properties:
//                   error:
//                     type: string
