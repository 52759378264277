import Cookies from 'js-cookie'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import PodcastPopup from './PodcastPopup';
import SentToPopup from './SentToPopup';
import SummaryPopup from './SummaryPopup';
import PodchaserPopup from './PodchaserPopup';
import GeminiPopup from './GeminiPopup';
import { GoTriangleLeft } from "react-icons/go";
import { GoTriangleRight } from "react-icons/go";
import ClaudePopup from './ClaudePopup';
import { TiTick } from "react-icons/ti";
import { IoReorderThree } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { FaListUl } from "react-icons/fa6";
import { BsPeopleFill } from "react-icons/bs";
import '../SuperAdmin/sidenav.css'
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo.png'
import noimg from './assets/noimg.png'
import { Link, NavLink, useLocation } from "react-router-dom";
import { createBrowserHistory } from 'history';


const Section1SA = () => {

  const [token, setToken] = useState('')
  const [admin_token, setAdmin_token] = useState('')

  const refreshAccessToken = async () => {
    try {
      const refreshToken = Cookies.get('refresh_token');
      const response = await axios.post(`${process.env.REACT_APP_URL}login/refresh`, {
        refresh_token: refreshToken,
      }, {
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });

      if (response.data.token) {
        // Update the access token in cookies or localStorage
        Cookies.set('token', response.data.token, { expires: 1, path: '/' });
        if (response.data.admintoken) {
          Cookies.set('admin_token', response.data.admintoken, { expires: 1, path: '/' });
        }
        // console.log("Token Refreshed");
      } else {
        console.log(response.data.error);
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
    }
  };

  // Call refreshAccessToken every 5 minutes (300,000 milliseconds)
  let intervalId;
  const startTokenRefresh = () => {
    intervalId = setInterval(refreshAccessToken, 300000);
  };
  const stopTokenRefresh = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    if (token === '' && Cookies.get('token') !== undefined) {
      setToken(Cookies.get('token'));
      console.log(Cookies.get('token'));
    }
    if (admin_token === '' && Cookies.get('admin_token') !== undefined) {
      setAdmin_token(Cookies.get('admin_token'));
    }
  }, [token]); // Include token in dependency array

  useEffect(() => {

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refreshAccessToken();
        // console.log("VVVVVVVVVVV")
      }
      // console.log("VVVVVVVVVVVVVVVvvvvvv aaaaaaaaaaa out")
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    startTokenRefresh(); // Start token refresh when component mounts
    return () => stopTokenRefresh(); // Cleanup function to stop interval when component unmounts
  }, []);



  const navigate = useNavigate();
  const [adminToken, setAdminToken] = useState('')
  const [sublist, setSublist] = useState([])
  const [detailsub, setDetailsub] = useState([])
  const [detailname, setDetailname] = useState('')
  const [prompt, setPrompt] = useState('')
  const [loading, setLoading] = useState('')
  const [podcastdetails, setPodcastdetails] = useState(null)
  const [podcastIssue, setPodcastIssue] = useState(null)
  const [podcastDetailsLT, setPodcastDetailsLT] = useState(null)
  const [podcastDetailsLTPopup, setPodcastDetailsLTPopup] = useState(null)
  const [subPrice, setSubPrice] = useState(null)
  const [isOpen, setIsOpen] = useState(false);
  const [statCard, setStatCard] = useState([])
  const [apiLimitData, setApiLimitData] = useState([])

  const [showModal, setShowModal] = useState(false);
  const [showModalSummary, setShowModalSummary] = useState(false);
  const [showModalPodchaser, setShowModalPodchaser] = useState(false);
  const [showModalGemini, setShowModalGemini] = useState(false);
  const [showModalClaude, setShowModalClaude] = useState(false);
  const [showModalLTP, setShowModalLTP] = useState(false);
  const [listchoice, setListchoice] = useState('S')
  const [disableButtonPay, setDisableButtonPay] = useState(false)
  const [disableButtonIssue, setDisableButtonIssue] = useState(false)
  const [disableButtonCreated, setDisableButtonCreated] = useState(false)

  const [loadingPay, setLoadingPay] = useState(false)
  const [loadingIssue, setLoadingIssue] = useState(false)
  const [loadingCreated, setLoadingCreated] = useState(false)
  const [loadingGenerateSummary, setLoadingGenerateSummary] = useState(false)

  const [pageNumberPay, setPageNumberPay] = useState(1);

  // const [sideNavChoose, setSideNavChoose] = useState('')
  const [sideNavChoose, setSideNavChoose] = useState(() => {
    const savedNavChoice = localStorage.getItem('sideNavChoose');
    return savedNavChoice || 'Stats';
  });

  useEffect(() => {
    const savedNavChoice = localStorage.getItem('sideNavChoose');
    console.log('Saved Nav Choice:', savedNavChoice); // Debugging line

    if (!savedNavChoice) {
      console.log('Setting Nav Choice to Stats'); // Debugging line
      localStorage.setItem('sideNavChoose', 'Stats');
    } else {
      setSideNavChoose(savedNavChoice);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sideNavChoose', sideNavChoose);
    console.log("***", sideNavChoose);
  }, [sideNavChoose]);


  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [cross, setCross] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 768);
      setCross(screenWidth < 768)
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const handlePageChangePay = (newPageNumber) => {
    setPageNumberPay(newPageNumber);
  };

  const [pageNumberIssue, setPageNumberIssue] = useState(1);

  const handlePageChangeIssue = (newPageNumber) => {
    setPageNumberIssue(newPageNumber);
  };

  const [pageNumberPod, setPageNumberPod] = useState(1);

  const handlePageChangePod = (newPageNumber) => {
    setPageNumberPod(newPageNumber);
  };

  const [pageNumberGenSum, setPageNumberGenSum] = useState(1);

  const handlePageChangeGenSum = (newPageNumber) => {
    setPageNumberGenSum(newPageNumber);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPodcastdetails(null)
  };
  const handleCloseModalLTP = () => {
    setShowModalLTP(false);
    setPodcastDetailsLTPopup(null)
  };

  const handleCloseSummary = () => {
    setShowModalSummary(false);
  };

  const handleClosePodchaser = () => {
    setShowModalPodchaser(false);
  };

  const handleCloseGemini = () => {
    setShowModalGemini(false);
  };

  const handleCloseClaude = () => {
    setShowModalClaude(false);
  };

  useEffect(() => {
    setAdminToken(Cookies.get('admin_token'))
  }, [])

  useEffect(() => {
    if (adminToken !== '') {
      console.log(adminToken)
      axios.post(`${process.env.REACT_APP_URL}adminsub`, {}
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { setSublist(res.data) }).catch(err => console.log(err))
    }

  }, [adminToken])

  useEffect(() => {
    if (adminToken !== '') {
      // console.log(adminToken)
      axios.post(`${process.env.REACT_APP_URL}getprompt`, {}
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { setPrompt(res.data.prompt) }).catch(err => console.log(err))
    }

  }, [adminToken])

  const transformTitle = (title) => {
    // Replace spaces with hyphens
    let transformed = title.replace(/ /g, '-');

    // Replace special characters except apostrophes and periods with hyphens
    transformed = transformed.replace(/[^\w'.-]/g, '-');

    return transformed;
  };

  const handleDetails = (detailId) => {

    axios.post(`${process.env.REACT_APP_URL}adminsubdetail`, { detailId }
      , {
        headers: {
          'Authorization': `Bearer ${adminToken}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => { setDetailsub(res.data); setDetailname(detailId) }).catch(err => console.log(err))
  }

  const handlePrompt = () => {
    axios.post(`${process.env.REACT_APP_URL}editprompt`, { prompt }
      , {
        headers: {
          'Authorization': `Bearer ${adminToken}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => { setPrompt(res.data.prompt) }).catch(err => console.log(err))
  }

  useEffect(() => {
    if (podcastdetails !== null) {
      // console.log(podcastdetails)
      setShowModal(true);
    }
  }, [podcastdetails])

  useEffect(() => {
    if (adminToken !== '') {
      // console.log(adminToken)
      setLoadingIssue(true)
      setPodcastIssue([])
      axios.post(`${process.env.REACT_APP_URL}podcastIssueGet`, { batch_size: 10, page_number: pageNumberIssue }
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        ; console.log(res.data, "----292")
        const sortedPodcastIssues = res.data.sort((a, b) => new Date(b.time) - new Date(a.time));
        setPodcastIssue(sortedPodcastIssues); setLoadingIssue(false)
          ; if (res.data.length === 0) { setDisableButtonIssue(true) } else { setDisableButtonIssue(false) }
      }).catch(err => { console.log(err); setLoadingIssue(false) })
    }
  }, [adminToken, pageNumberIssue])

  useEffect(() => {
    if (adminToken !== '') {
      // console.log(adminToken)
      setPodcastDetailsLT([])
      setLoadingCreated(true)
      axios.post(`${process.env.REACT_APP_URL}podcastDetailsSentToGet`, { batch_size: 10, page_number: pageNumberPod }
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        setLoadingCreated(false)
        setPodcastDetailsLT(res.data); console.log(res.data)
          ; if (res.data.length === 0) { setDisableButtonCreated(true) } else { setDisableButtonCreated(false) }

      }).catch(err => { console.log(err); setLoadingCreated(false) })
    }
  }, [adminToken, pageNumberPod])

  useEffect(() => {
    if (adminToken !== '') {
      console.log('subpriceget')
      setSubPrice([])
      setLoadingPay(true)
      axios.post(`${process.env.REACT_APP_URL}subPriceGet`, { batch_size: 10, page_number: pageNumberPay }
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        setLoadingPay(false)
        const sortedPrice = res.data.sort((a, b) => new Date(b.created) - new Date(a.created));
        setSubPrice(sortedPrice); console.log(res.data); if (res.data.length === 0) { setDisableButtonPay(true) } else { setDisableButtonPay(false) }
      }).catch(err => { console.log(err); setLoadingPay(false) })
    }
  }, [adminToken, pageNumberPay])


  useEffect(() => {
    if (adminToken !== '') {

      axios.post(`${process.env.REACT_APP_URL}StatsCard`, {}
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res.data, "--332"); setStatCard(res.data) }).catch(err => console.log(err))
    }
  }, [adminToken])

  useEffect(() => {
    if (adminToken !== '') {

      axios.post(`${process.env.REACT_APP_URL}ApiLimitDataGet`, {}
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res.data); setApiLimitData(res.data) }).catch(err => console.log(err))
    }
  }, [adminToken])

  const handleLogout = () => {
    Cookies.remove('token')
    Cookies.remove('refresh_token')
    Cookies.remove('admin_token')
    console.log('logout')
    window.location.href = '/';

  }

  const handleEditApiKey = (index, newKey) => {
    console.log("newkey", newKey)
    const updatedApiLimitData = [...apiLimitData];
    updatedApiLimitData[index].Key = newKey;
    setApiLimitData(updatedApiLimitData);
  };

  const handleEditApiKeySubmit = (index, newKey) => {

    const filteredApiData = apiLimitData.filter(item => item.API === newKey);
    console.log("--", filteredApiData)

    if (adminToken !== '') {

      axios.post(`${process.env.REACT_APP_URL}ApiLimitDataEdit`, { newKey, filteredApiData }
        , {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        console.log(res.data); if (res.data === 'Key Updated / Renewed Successfully') {
          toast.success(res.data); setTimeout(() => {
            window.location.reload()
          }, 2000);
        }
      }).catch(err => console.log(err))
    }
  };




  const [podchaserCost, setPodchaserCost] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [podcastsearchid, setPodcastsearchid] = useState('')
  const [toogleSearchPod, setToogleSearchPod] = useState(true)
  const [toogleSearchEp, setToogleSearchEp] = useState(false)
  const [resultSummary, setResultSummary] = useState([])

  const [searchTermEpisode, setSearchTermEpisode] = useState('')
  const [searchTermPod, setSearchTermPod] = useState('')
  const [podKey, setPodKey] = useState('Not Authorized')
  const [podcastlist, setPodcastlist] = useState([])
  const [podcastepisodelist, setPodcastepisodelist] = useState([])
  const [podcastname, setPodcastname] = useState('')
  const [loadingP, setLoadingP] = useState(false)

  const searchquery = {
    query: `
          {
              podcasts(searchTerm: "${searchTermPod}") 
              {
                  paginatorInfo {
                      count
                      currentPage
                      firstItem
                      hasMorePages
                      lastItem
                      lastPage
                      perPage
                      total
                  }
                  data {
                      id
                      title
                      imageUrl
                  }
              }
          }
      `
  };

  const searchqueryepisode = {
    query: `
    {
      podcast(identifier: {id: "${podcastsearchid}", type: PODCHASER}) {
          id
          title
          imageUrl
          episodes(first: 5, sort: {sortBy: AIR_DATE, direction: DESCENDING}) {
            data {
              title
            }
          }
      }
    }
  `
  };

  const searchqueryspecificepisode = {
    query: `
    {
      podcast(identifier: {id: "${podcastsearchid}", type: PODCHASER}) {
          id
          title
          imageUrl
          episodes(searchTerm:"${searchTermEpisode}") {
            data {
              title
            }
          }
      }
    }
  `
  };

  useEffect(() => {
    setLoadingP(true)
    if (token !== '') {
      console.log("PPPPPPPPOOOODDDKEY")
      axios.post(`${process.env.REACT_APP_URL}podchaserKeyGet`, {}
        , {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        setPodKey(res.data)
      }).catch(err => { console.log(err); setLoading(false) })
    }
  }, [token])



  const handleSearch = async () => {
    console.log("inside handle search", searchquery)
    if (podKey !== 'Not Authorized') {
      setLoadingP(true)
      setPodcastlist([])
      await axios.post('https://api.podchaser.com/graphql/cost', searchquery
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
        // print("X-Podchaser-Points-Remaining:", response.headers.get("X-Podchaser-Points-Remaining"))
        // print("X-Podchaser-Query-Cost:", response.headers.get("X-Podchaser-Query-Cost"))
      ).then(res => { console.log(res.data); setPodchaserCost(res.data) }).catch(err => console.log(err))
      console.log("inside handle search 222", searchquery)
      await axios.post('https://api.podchaser.com/graphql', searchquery
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res); setPodcastlist(res.data.data.podcasts.data); console.log("148--", res.data.data.podcasts.data); setLoadingP(false) })
        .catch(err => {
          console.log(err.response.data.message); setLoadingP(false)
        })
    }
  }

  const handleSearchPod = async () => {
    console.log("inside handle search", searchquery)
    if (podKey !== 'Not Authorized') {
      setLoadingP(true)
      setPodcastlist([])
      // setPodcastsearchid('')
      await axios.post('https://api.podchaser.com/graphql/cost', searchquery
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
        // print("X-Podchaser-Points-Remaining:", response.headers.get("X-Podchaser-Points-Remaining"))
        // print("X-Podchaser-Query-Cost:", response.headers.get("X-Podchaser-Query-Cost"))
      ).then(res => { console.log(res.data); setPodchaserCost(res.data) }).catch(err => console.log(err))
      console.log("inside handle search 222", searchquery)
      await axios.post('https://api.podchaser.com/graphql', searchquery
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res); setPodcastlist(res.data.data.podcasts.data); console.log("148--", res.data.data.podcasts.data); setLoadingP(false) })
        .catch(err => {
          console.log(err.response.data.message); setLoadingP(false)
        })
    }
  }

  useEffect(() => {
    console.log(podchaserCost.cost, "--190")
    if (podchaserCost.cost > 0) {
      const podchaserCost2 = podchaserCost.cost
      axios.post(`${process.env.REACT_APP_URL}podchaserCost`, { podchaserCost2 }
        , {
          headers: {
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res.data); setPodchaserCost(0) })
        .catch(err => { console.log(err) });

    }
  }, [podchaserCost])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [sideNavChoose]);

  const handleEpisodeSearch = async () => {
    console.log(podcastsearchid, "---520")
    console.log("inside handle search", searchqueryepisode)
    if (podKey !== 'Not Authorized') {
      setLoadingP(true)
      // setPodcastlist([])
      await axios.post('https://api.podchaser.com/graphql/cost', searchqueryepisode
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
        // print("X-Podchaser-Points-Remaining:", response.headers.get("X-Podchaser-Points-Remaining"))
        // print("X-Podchaser-Query-Cost:", response.headers.get("X-Podchaser-Query-Cost"))
      ).then(res => { console.log(res.data); setPodchaserCost(res.data) }).catch(err => console.log(err))
      await axios.post('https://api.podchaser.com/graphql', searchqueryepisode
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res); setPodcastepisodelist(res.data.data.podcast.episodes.data); console.log("148--", res.data.data.podcast.episodes.data); setLoadingP(false) })
        .catch(err => {
          console.log(err.response.data.message); setLoadingP(false)
        })
    }
  }

  useEffect(() => {
    // This code will run whenever podcastsearchid changes
    if (podcastsearchid !== '') {
      handleEpisodeSearch();
      setToogleSearchPod(false);
      setToogleSearchEp(true);
    }
  }, [podcastsearchid]);

  const handleSearchSpecificEpisode = async () => {

    if (podKey !== 'Not Authorized') {
      setLoadingP(true)
      // setPodcastlist([])
      await axios.post('https://api.podchaser.com/graphql/cost', searchqueryspecificepisode
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res.data); setPodchaserCost(res.data) }).catch(err => console.log(err))
      await axios.post('https://api.podchaser.com/graphql', searchqueryspecificepisode
        , {
          headers: {
            'Authorization': `Bearer ${podKey}`,
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => { console.log(res); setPodcastepisodelist(res.data.data.podcast.episodes.data); console.log("148--", res.data.data.podcast.episodes.data); setLoadingP(false) })
        .catch(err => {
          console.log(err.response.data.message); setLoadingP(false)
        })
    }

  }

  const [specificGeneratedSummary, setSpecificGeneratedSummary] = useState([])

  const handleGenerateSpecificSummary = async (episode, podid) => {
    axios.post(`${process.env.REACT_APP_URL}genSpecificEpisodeSum`, { podcastId: podid, episode: episode }
      , {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    )
      // .then(res => {
      //   console.log(res.data,"----44");setSpecificGeneratedSummary(res.data)}).catch(err => {console.log(err)})
      // }
      .then(res => {
        if (res.data.task_id) {
          console.log('Task ID:', res.data.task_id);
          toast('Generation in progress, please wait...');
          checkTaskStatus(res.data.task_id);
        }
      })
      .catch(err => {
        console.error(err);
        toast.error('An error occurred while starting the regeneration.');
      });
  };

  let pollTimeout;

  const checkTaskStatus = (taskId) => {
    const pollInterval = 5000; // 5 seconds

    const pollTaskStatus = () => {
      axios.get(`${process.env.REACT_APP_URL}/genSpecificEpisodeSum/status/${taskId}`)
        .then(res => {
          if (res.data.status === 'completed') {
            console.log('Task completed:', res.data.result);
            setSpecificGeneratedSummary(res.data.result);
            console.log(res.data)
            // Adjust based on actual response structure
            toast('Summary created successfully');
          } else if (res.data.status === 'error') {
            console.error('Error:', res.data.message);
            toast.error(res.data.message || 'An error occurred during regeneration.');
          } else {
            // If status is 'in_progress', poll again after the interval
            pollTimeout = setTimeout(pollTaskStatus, pollInterval);
          }
        })
        .catch(err => {
          console.error(err);
          toast.error('An error occurred while checking the task status.');
        });
    };

    pollTaskStatus();
  };

  useEffect(() => {
    // Cleanup function to clear any remaining timeouts if the component unmounts
    return () => {
      if (pollTimeout) {
        clearTimeout(pollTimeout);
      }
    };
  }, []);

  function renderSummary(summary) {
    // Split the summary into paragraphs based on <br> tags
    console.log(summary)
    const paragraphs = summary.split('<br>');

    // Map each paragraph to a <p> tag
    const renderedParagraphs = paragraphs.map((paragraph, index) => (
      <p className='d-flex justify-content-start text-start' key={index}>{paragraph.replace(/&bull;/g, '•')}</p>
    ));

    return renderedParagraphs;
  }



  //////////////////////podcasters



  const [podcasterList, setPodcasterlist] = useState([])
  const [page, setPage] = useState(1)
  const [loadingPodcasters, setLoadingPodcasters] = useState(false)

  useEffect(() => {
    if (searchTerm === '') {
      handleSearchPodcasterslist()
    } else {
      handleSearchTerm()
    }

  }, [page])

  const handleSearchPodcasterslist = () => {
    setLoadingPodcasters(true)
    axios.post(`${process.env.REACT_APP_URL}podcasters`, { batch_size: 10, page_number: page }
      , {
        headers: {
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {
      setPodcasterlist(res.data)
      console.log(res.data, "==list")
      setLoadingPodcasters(false)
      // console.log(res.data)
      if (res.data.length === 0 && pageEp > 1) {
        setPage(page - 1)
      }
    }).catch(err => { console.log(err); setLoadingPodcasters(false) })
  }

  const handleLeft = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const handleRight = (x) => {
    if (x === 10) {
      setPage(page + 1)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchTerm.trim() !== '') {
        handleSearchTerm();
      } else {
        handleSearch()
      }
    }
  };

  const handleSearchTerm = () => {
    console.log(page, searchTerm, '----searchterm')
    if (searchTerm !== '') {
      axios.post(`${process.env.REACT_APP_URL}podcastersSearch`, { batch_size: 10, page_number: page, searchTerm }
        , {
          headers: {
            'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
        }
      ).then(res => {
        setPodcasterlist(res.data, "-----podcaster")
        // console.log(res.data)
        if (res.data.length === 0 && page > 1) {
          setPage(page - 1)
        }
      }).catch(err => { console.log(err) })
    } else {
      handleSearchPodcasterslist()
    }
  }

  const handleSearchPodcaster = () => {
    axios.post(`${process.env.REACT_APP_URL}podcasters`, { batch_size: 10, page_number: page }
      , {
        headers: {
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {
      setPodcasterlist(res.data)
      console.log(res.data, "==list")
      // console.log(res.data)
      if (res.data.length === 0) {
        setPage(page - 1)
      }
    }).catch(err => { console.log(err) })
  }

  const handleSearchTermPodcaster = () => {
    axios.post(`${process.env.REACT_APP_URL}podcastersSearch`, { batch_size: 10, page_number: page, searchTerm }
      , {
        headers: {
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {
      setPodcasterlist(res.data, "----podcasterList")
      // console.log(res.data)
      if (res.data.length === 0 && page > 1) {
        setPage(page - 1)
      }
    }).catch(err => { console.log(err) })
  }

  ////////////////////podcasters end

  /////////////////////podcasters episode


  const [pageEp, setPageEp] = useState(1)
  const [searchTermEp, setSearchTermEp] = useState('')
  const [podcasterListEp, setPodcasterlistEp] = useState([])
  const [loadingPodcastersEp, setLoadingPodcastersEp] = useState(false)

  const handleSearchTermEp = () => {
    console.log(pageEp, searchTermEp, '----searchterm')
    setLoadingPodcastersEp(true)
    axios.post(`${process.env.REACT_APP_URL}podcastersSearchDetailsAll`, { batch_size: 10, page_number: pageEp, searchTermEp }
      , {
        headers: {
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {

      setPodcasterlistEp(res.data, "-----podcaster")
      setLoadingPodcastersEp(false)
      // console.log(res.data)
      if (res.data.length === 0 && pageEp > 1) {
        setPageEp(pageEp - 1)
      }
    }).catch(err => { console.log(err); setLoadingPodcastersEp(false) })
  }


  const handleLeftEp = () => {
    if (pageEp > 1) {
      setPageEp(pageEp - 1)
    }
  }
  const handleRightEp = (x) => {
    if (x === 10) {
      setPageEp(pageEp + 1)
    }
  }

  const handleKeyPressEp = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchTermEp.trim() !== '') {
        handleSearchTermEp();
      } else {
        handleSearchEp()
      }
    }
  };

  const handleSearchEp = () => {
    console.log('834----------------------834')
    axios.post(`${process.env.REACT_APP_URL}podcastersSearchDetailsAll`, { batch_size: 10, page_number: page }
      , {
        headers: {
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }
    ).then(res => {
      // console.log(res.data)
      if (res.data.length === 0 && page > 1) {
        setPageEp(pageEp - 1)
      } else {
        setPodcasterlistEp(res.data)
        console.log(res.data, "---137")

      }
    }).catch(err => { console.log(err) })

  }

  useEffect(() => {
    console.log('---------------855 handlesearchep')
    if (searchTermEp === '') {
      handleSearchTermEp()
    } else {
      handleSearchEp()
    }

  }, [pageEp])

  useEffect(() => {
    setSearchTerm('')
    setSearchTermEp('')
    setSearchTermEpisode('')
  }, [sideNavChoose])

  // useEffect(()=>{
  //   axios.post(`${process.env.REACT_APP_URL}addpodcasterandep`, { }
  //     , {
  //         headers: {
  //             'Content-Type': 'application/json' // Optional: Specify content type if needed
  //         }
  //     }
  // ).then(res => {
  //     console.log(res.data)
  // }).catch(err => { console.log(err) })
  // },[])


  useEffect(()=>{
    axios.post(`${process.env.REACT_APP_URL}deleteextra`, { }
      , {
          headers: {
              'Content-Type': 'application/json' // Optional: Specify content type if needed
          }
      }
  ).then(res => {
      console.log(res.data)
  }).catch(err => { console.log(err) })
  },[])

  return (
    <div style={{ minHeight: '85vh', backgroundColor: '#89c8ff' }}>

      <aside style={{ position: 'fixed', top: '0px', overflowY: 'auto', minHeight: '100vh', zIndex: '20', maxWidth: '268px', backgroundColor: 'black' }} className={`sidebar-dark-primary elevation-4 ${!isSmallScreen ? '' : 'd-none'}`}>

        <a className="brand-link" style={{ height: '55px' }}>
        </a>
        <div className={`d-flex m-2 ${isSmallScreen == false ? '' : 'd-none'}`} style={{ color: 'white' }} onClick={() => setIsSmallScreen(true)}>
          <RxCrossCircled className={`ms-auto ${cross ? '' : 'd-none'}`} />
        </div>


        <div className="sidebar">

          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar col-12 flex-column me-2" data-widget="treeview" role="menu" data-accordion="false">
              {/* <li className="nav-header col-12" style={{ color: 'white' }} onClick={()=>navigate('/')}>Podcast Summarize</li> */}
              <NavLink className="navbar-brand mx-auto" style={{ height: '85px', width: '85px' }} to="/"><img src={logo} style={{ height: '85px', width: '85px', borderRadius: '100%' }} className='mx-2 ' /></NavLink>
              <li className="nav-item col-12 d-flex flex-wrap">
                <button style={{ color: 'white' }} className="nav-link col-12 btn btn-link text-start"  >
                  <FaListUl style={{ color: 'white', display: 'none' }} className='me-2' />
                </button>
                <li className="nav-item col-12">
                  <button value='Stats' style={{ color: 'white', backgroundColor: sideNavChoose === 'Stats' ? '#494E53' : '' }} className="nav-link col-12 btn btn-link text-start" onClick={(e) => { setSideNavChoose(e.target.value) }} >
                    Stats
                  </button>
                </li>
                <li className="nav-item col-12 d-flex flex-wrap">
                  <button value='Prompt' style={{ color: 'white', backgroundColor: sideNavChoose === 'Prompt' ? '#494E53' : '' }} onClick={(e) => { setSideNavChoose(e.target.value) }} className="nav-link fs-6 text-start col-12 btn btn-link">
                    Prompt
                  </button>
                </li>
                <li className="nav-item col-12 d-flex flex-wrap">
                  <button value='API' style={{ color: 'white', backgroundColor: sideNavChoose === 'API' ? '#494E53' : '' }} onClick={(e) => { setSideNavChoose(e.target.value) }} className="nav-link fs-6 text-start col-12 btn btn-link">
                    API Limits
                  </button>
                </li>
                <li className="nav-item col-12">
                  <button value='Subscribers' style={{ color: 'white', backgroundColor: sideNavChoose === 'Subscribers' ? '#494E53' : '' }} className="nav-link col-12 btn btn-link text-start" onClick={(e) => { setSideNavChoose(e.target.value) }} >
                    Subscribers
                  </button>
                </li>
                <li className="nav-item col-12 d-flex flex-wrap">
                  <button value='Podcasts Created' style={{ color: 'white', backgroundColor: sideNavChoose === 'Podcasts Created' ? '#494E53' : '' }} onClick={(e) => { setSideNavChoose(e.target.value) }} className="nav-link fs-6 text-start col-12 btn btn-link">
                    Podcasts Created
                  </button>
                </li>
                <li className="nav-item col-12">
                  <button value='User Payments' style={{ color: 'white', backgroundColor: sideNavChoose === 'User Payments' ? '#494E53' : '' }} className="nav-link col-12 btn btn-link text-start" onClick={(e) => { setSideNavChoose(e.target.value) }} >
                    User Payments
                  </button>
                </li>
                <li className="nav-item col-12 d-flex flex-wrap">
                  <button value='Podcasts Issues' style={{ color: 'white', backgroundColor: sideNavChoose === 'Podcasts Issues' ? '#494E53' : '' }} onClick={(e) => { setSideNavChoose(e.target.value) }} className="nav-link fs-6 text-start col-12 btn btn-link">
                    Podcasts Issues
                  </button>
                </li>
                <li className="nav-item col-12 d-flex flex-wrap">
                  <button value='Generate Summaries' style={{ color: 'white', backgroundColor: sideNavChoose === 'Generate Summaries' ? '#494E53' : '' }} onClick={(e) => { setSideNavChoose(e.target.value) }} className="nav-link fs-6 text-start col-12 btn btn-link">
                    Generate Summaries
                  </button>
                </li>
                <li className="nav-item col-12 d-flex flex-wrap">
                  <button value='Podcasters' style={{ color: 'white', backgroundColor: sideNavChoose === 'Podcasters' ? '#494E53' : '' }} onClick={(e) => { setSideNavChoose(e.target.value) }} className="nav-link fs-6 text-start col-12 btn btn-link">
                    Podcasters
                  </button>
                </li>
                <li className="nav-item col-12 d-flex flex-wrap">
                  <button value='Episodes' style={{ color: 'white', backgroundColor: sideNavChoose === 'Episodes' ? '#494E53' : '' }} onClick={(e) => { setSideNavChoose(e.target.value) }} className="nav-link fs-6 text-start col-12 btn btn-link">
                    Episodes
                  </button>
                </li>
              </li>
              <li className="nav-item col-12 mt-5 d-flex flex-wrap">
                <button value='Podcasts Issues' onClick={(e) => { handleLogout() }} className="fs-6  text-center mx-auto col-11 btn btn-outline-primary">
                  Log Out
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
      <IoReorderThree size={42} style={{ top: '50px', left: '10px', position: 'fixed', zIndex: '2', color: 'black' }} onClick={() => setIsSmallScreen(false)} />

      <div style={{ paddingTop: '30px', paddingBottom: '150px', height: '100%', minHeight: '80vh' }} className='responsive-side-nav-other'>

        {
          sideNavChoose === 'Stats' ?
            <>
              <h1 className='pt-4'>Stats</h1>
              <div className='d-flex justify-content-center flex-wrap px-2 '>

                <SummaryPopup
                  detail={statCard[0]}
                  showModalSummary={showModalSummary}
                  handleClose={handleCloseSummary}
                />

                <PodchaserPopup
                  detail={statCard[1]}
                  showModalPodchaser={showModalPodchaser}
                  handleClose={handleClosePodchaser}
                />

                <GeminiPopup
                  detail={statCard[1]}
                  showModalGemini={showModalGemini}
                  handleClose={handleCloseGemini}
                />

                <ClaudePopup
                  detail={statCard[1]}
                  showModalClaude={showModalClaude}
                  handleClose={handleCloseClaude}
                />

                <div className='col-12 d-flex justify-content-center flex-wrap'>
                  <div class="card m-2" style={{ width: '200px' }} >
                    <div class="card-body">
                      <h5 class="card-title">Website Users</h5>
                      <h6 class="card-subtitle mb-2 text-white">This week</h6>

                      <h1 class="card-text py-1">{statCard[4]}</h1>

                    </div>
                  </div>

                  <div class="card m-2" style={{ width: '200px' }} >
                    <div class="card-body">
                      <h5 class="card-title">GPT Uses</h5>
                      <h6 class="card-subtitle mb-2 text-white">This week</h6>

                      <h1 class="card-text py-1">{statCard[5]}</h1>

                    </div>
                  </div>
                </div>

                <div className='col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center flex-wrap'>
                  <div class="card m-2" style={{ width: '200px' }} >
                    <div class="card-body px-1">
                      <h5 class="card-title">Summaries Created</h5>
                      <h6 class="card-subtitle mb-2 text-body-secondary">This week</h6>
                      <h1 class="card-text py-1">{statCard[0]}</h1>
                      <button className='btn btn-maroon' onClick={() => { setShowModalSummary(true); console.log(showModalSummary) }} >Details</button>

                    </div>
                  </div>

                  <div class="card m-2 " style={{ width: '200px' }} >
                    <div class="card-body">
                      <h5 class="card-title">Podchaser Used</h5>
                      <h6 class="card-subtitle mb-2 text-body-secondary">This week</h6>
                      <h1 class="card-text py-1">{statCard[1]}</h1>
                      <button className='btn btn-maroon' onClick={() => { setShowModalPodchaser(true); console.log(showModalPodchaser) }} >Details</button>


                    </div>
                  </div>
                </div>

                <div className='col-sm-6 col-12 d-flex justify-content-sm-start justify-content-center flex-wrap'>
                  <div class="card m-2" style={{ width: '200px' }} >
                    <div class="card-body">
                      <h5 class="card-title">Gemini Used</h5>
                      <h6 class="card-subtitle mb-2 text-body-secondary">This week</h6>
                      <h1 class="card-text py-1">{statCard[2]}</h1>
                      <button className='btn btn-maroon' onClick={() => { setShowModalGemini(true); console.log(showModalGemini) }} >Details</button>


                    </div>
                  </div>

                  <div class="card m-2" style={{ width: '200px' }} >
                    <div class="card-body">
                      <h5 class="card-title">Claude Used</h5>
                      <h6 class="card-subtitle mb-2 text-body-secondary">This week</h6>
                      <h1 class="card-text py-1">{statCard[3]}</h1>
                      <button className='btn btn-maroon' onClick={() => { setShowModalClaude(true); console.log(showModalClaude) }} >Details</button>

                    </div>




                  </div>
                </div>


              </div>
            </> : <></>
        }


        {
          sideNavChoose === 'Prompt' ?
            <>
              <div className="col-10 mx-auto px-0">
                <label htmlFor="text" className="mb-1 fw-bold text-black d-flex justify-content-center">
                  <h1 className='mt-4'>Prompt</h1>
                </label>
                <div className="input-group mb-3 col-11">
                  <textarea
                    type="text"
                    className="form-control col-11"
                    placeholder="Prompt"
                    id="nameFreelancer"
                    required
                    aria-label="Name"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                </div>
                <button type="button" onClick={() => handlePrompt()} className="btn btn-primary  mx-auto">Edit Prompt</button>

              </div>
            </> : <></>
        }


        {
          sideNavChoose === 'Subscribers' ?
            <>
              <h1 className='pt-4'>Subscribers</h1>

              <div className="overflow-x-auto mt-4">



                <table style={{}} className=" col-11 mx-auto bg-white border shadow-lg">
                  <thead className="bg-gray-100  ">
                    <tr className="">
                      <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                        Sr. No.
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Email
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                      sublist?.length === 0 ?
                        <h3 className="flex justify-center mx-3">No Subscribers</h3> : ''
                    }
                    {sublist.map((item, index) => (
                      <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '50px' }}
                        >
                          {(index + 1)}.
                        </td>
                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '150px' }}
                        >
                          {item.email}
                        </td>
                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '50px' }}
                        >
                          <button type="button" value={item.email} onClick={(e) => handleDetails(e.target.value)} className="btn btn-maroon" >Details</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>


                <div className=' flex justify-center mx-auto mt-4'>
                  {/* {loading ? (
// <div>hello</div>
<ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

) : (
''
)} */}
                </div>
              </div>

              {
                detailname !== '' ?

                  <h3 className='mt-4  ' style={{ wordBreak: 'break-word' }}>Details of {detailname}</h3> : ''
              }
              {
                detailname !== '' && detailsub?.length === 0 ?
                  <h3 className='mt-4  ' style={{ wordBreak: 'break-word' }}>No Data</h3> : ''

              }

              <div className="">

                {
                  detailsub?.length === 0 ?
                    <h3 className="flex justify-center mx-3 pt-3"></h3> : ''
                }
                <div className='d-flex justify-content-center flex-wrap'>
                  {detailsub.map((item, index) => (
                    <div key={index} className="card my-4 col-lg-3 mx-2 px-0 hoverb" style={{ width: '300px' }}>
                      <img src={item.image || noimg} className="card-img-top" style={{ width: '100%', height: '300px' }} alt='' onError={(e) => { e.target.src = noimg }} />
                      <div className="card-body">
                        <h5 className="card-title mb-3">{item.title}</h5>
                        {/* <p className="card-text">{item.description}</p> */}
                        <div className="pt-4 mx-auto d-flex justify-content-center">
                          <div style={{ position: 'absolute', bottom: '10px' }}>
                            <button type="button" value={item} onClick={(e) => setPodcastdetails({
                              'image': item.image,
                              'podTitle': item.podTitle,
                              'podcastUrl': item.podcastUrl,
                              'rssUrl': item.rssUrl,
                              'summary': item.summary,
                              'summaryClaude': item.summaryClaude,
                              'title': item.title,
                              'url': item.url,
                              'sentToLT': item.sentToLT,
                              'podId': item.podId
                            })} className="btn btn-maroon " >Details</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {
                  podcastdetails !== null ?
                    <PodcastPopup
                      podcastdetails={podcastdetails}
                      showModal={showModal}
                      promptData={prompt}
                      handleClose={handleCloseModal}
                    /> : <></>
                }


                <div className=' flex justify-center mx-auto mt-4'>
                  {loading ? (
                    // <div>hello</div>
                    <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

                  ) : (
                    ''
                  )}
                </div>
              </div>
            </> : <></>
        }


        {
          sideNavChoose === 'API' ?
            <>
              <h1 className='pt-4'>API Limits</h1>

              <div className="overflow-x-auto mt-4">



                <table style={{}} className=" col-11 mx-auto bg-white border shadow-lg">
                  <thead className="bg-gray-100  ">
                    <tr className="">
                      <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                        Sr. No.
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        API
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Key
                      </th>
                      {/* <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
          Token Left
        </th> */}
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Flag
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Renew / Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                      apiLimitData?.length === 0 ?
                        <h3 className="flex justify-center mx-3">No API data</h3> : ''
                    }
                    {apiLimitData.map((item, index) => (
                      <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '50px' }}
                        >
                          {(index + 1)}.
                        </td>
                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '150px' }}
                        >
                          {item.API}
                        </td>
                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '450px', maxWidth: '450px', }}
                        >
                          <textarea style={{ minWidth: '450px', maxWidth: '450px', overflow: 'scroll' }} value={item.Key} onChange={(e) => handleEditApiKey(index, e.target.value)} />
                        </td>

                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '150px' }}
                        >
                          {item.Flag}
                        </td>
                        <td
                          className="py-2 px-4 border-b text-center"
                          style={{ minWidth: '50px' }}
                        >
                          <button type="button" value={item.API} onClick={(e) => handleEditApiKeySubmit(index, e.target.value)} className="btn btn-maroon" >Renew / Edit Key</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>


                <div className=' flex justify-center mx-auto mt-4'>
                  {/* {loading ? (
// <div>hello</div>
<ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

) : (
''
)} */}
                </div>
              </div>
            </> : ''
        }


        {
          sideNavChoose === 'Podcasts Created' ?
            <>
              <h1 className='pt-4'>Podcasts Created</h1>

              <div className=' d-flex justify-content-center col-12 mx-auto  mt-4'>
                {loadingCreated ? (
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-content-center mx-auto' color="black" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>

              <div className="overflow-x-auto mx-2">
                <button
                  onClick={() => handlePageChangePod(pageNumberPod - 1)}
                  disabled={pageNumberPod === 1}

                >
                  <GoTriangleLeft />
                </button>
                <span className='p-3'>{pageNumberPod}</span>
                <button
                  onClick={() => { if (podcastDetailsLT.length === 10) { handlePageChangePod(pageNumberPod + 1) } }}
                  disabled={disableButtonCreated === true}
                >
                  <GoTriangleRight />
                </button>

                <table className="col-11 mx-auto  bg-white border shadow-lg">
                  <thead className="bg-gray-100  ">
                    <tr className="">
                      <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                        Sr. No.
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Title
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Episode
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Sent To
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Currently Subscribed
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Website
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        GPT
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                      podcastDetailsLT === null ?
                        <h3 className="flex justify-center mx-3">No Podcasts Created Yet</h3> :

                        podcastDetailsLT.map((item, index) => (
                          <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '50px' }}
                            >
                              {(index + 1) + ((pageNumberPod - 1) * 10)}.
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.title}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.episode}
                            </td>
                            <td
                              className="py-2 px-4 border-b fw-bold text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.sentToLT.length}
                            </td>
                            <td
                              className="py-2 px-4 border-b fw-bold text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.sentTo.length}
                            </td>
                            <td
                              className="py-2 px-4 border-b fw-bold text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.Website}
                            </td>
                            <td
                              className="py-2 px-4 border-b fw-bold text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.GPT}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              <button type="button" value={item} onClick={(e) => setPodcastDetailsLTPopup({
                                'episode': item.episode,
                                'title': item.title,
                                'sentTo': item.sentTo,
                                'sentToLT': item.sentToLT,
                                'Gemini': item.summary,
                                'Claude': item.summaryClaude,
                                'podId': item.podId,
                                'image': item.imageUrl
                              })} className="btn btn-maroon " >Details</button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
              {
                podcastDetailsLTPopup !== null ?
                  <SentToPopup
                    podcastdetails={podcastDetailsLTPopup}
                    showModal={showModalLTP}
                    promptData={prompt}
                    handleClose={handleCloseModalLTP}
                  /> : <></>
              }
            </> : <></>
        }
        {
          sideNavChoose === 'Podcasts Issues' ?
            <>
              <h1 className='pt-4'>Podcast Issues</h1>

              <div className=' d-flex justify-content-center col-12 mx-auto mt-4'>
                {loadingIssue ? (
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-content-center mx-auto' color="black" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>

              <div className="overflow-x-auto mx-2">
                <button
                  onClick={() => handlePageChangeIssue(pageNumberIssue - 1)}
                  disabled={pageNumberIssue === 1}

                >
                  <GoTriangleLeft />
                </button>
                <span className='p-3'>{pageNumberIssue}</span>
                <button
                  onClick={() => { if (podcastIssue.length === 10) { handlePageChangeIssue(pageNumberIssue + 1) } }}
                  disabled={disableButtonIssue === true}
                >
                  <GoTriangleRight />
                </button>
                <table className="col-11 mx-auto  bg-white border shadow-lg">

                  <thead className="bg-gray-100  ">
                    <tr className="">
                      <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                        Sr. No.
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Time
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Title
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Episode
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Issue
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                      podcastIssue === null ?
                        <h3 className="flex justify-center mx-3">No Issues Yet</h3> :

                        podcastIssue.map((item, index) => (
                          <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '50px' }}
                            >
                              {(index + 1) + ((pageNumberIssue - 1) * 10)}.
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.time}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.title}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.episode}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.issue}
                            </td>

                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </> : <></>
        }


        {
          sideNavChoose === 'User Payments' ?
            <>
              <h1 className='pt-4 '>User Payments</h1>

              <div className=' d-flex justify-content-center col-12 mx-auto mt-4'>
                {loadingPay ? (
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-content-center mx-auto' color="black" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>

              <div className="overflow-x-auto mx-2">

                <button
                  onClick={() => handlePageChangePay(pageNumberPay - 1)}
                  disabled={pageNumberPay === 1}

                >
                  <GoTriangleLeft />
                </button>
                <span className='p-3'>{pageNumberPay}</span>
                <button
                  onClick={() => { if (subPrice.length === 10) { handlePageChangePay(pageNumberPay + 1) } }}
                  disabled={disableButtonPay === true}
                >
                  <GoTriangleRight />
                </button>
                <table className="col-11 mx-auto  bg-white border shadow-lg">

                  <thead className="bg-gray-100  ">
                    <tr className="">
                      <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                        Sr. No.
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Username
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Date
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Product
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                      subPrice === null ?
                        <h3 className="flex justify-center mx-3">No Payments Yet</h3> :

                        subPrice.map((item, index) => (
                          <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '50px' }}
                            >
                              {(index + 1) + ((pageNumberPay - 1) * 10)}.
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.username}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.created}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.product_id}
                            </td>
                            <td
                              className="py-2 px-4 border-b text-center"
                              style={{ minWidth: '150px' }}
                            >
                              {item.amount / 100} $
                            </td>

                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </>
            : <></>}

        {
          sideNavChoose === 'Generate Summaries' ?
            <>
              <h1 className='pt-4'>Generate Summaries</h1>

              <div className=' d-flex justify-content-center col-12 mx-auto mt-4'>
                {loadingGenerateSummary ? (
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-content-center mx-auto' color="black" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>

              <div className="overflow-x-auto">
                <div className="">

                  {
                    toogleSearchPod === true ?
                      <div className="input-group mb-3 container col-lg-5 col-12">
                        <h5 className='d-flex justify-content-center col-12 mx-auto'>Search Podcast</h5>
                        <input
                          type="text"
                          placeholder='Search Podcasts'
                          value={searchTermPod}
                          className="form-control"
                          onChange={(e) => setSearchTermPod(e.target.value)}
                          // onKeyPress={handleKeyPress}
                          aria-label="search"
                        />
                        <button
                          className="btn btn-primary"
                          onClick={handleSearchPod}
                          type="button"
                          id="button-addon2"
                          disabled={searchTermPod.trim() === ''}
                        >
                          Search
                        </button>

                        <div className="d-flex justify-content-center mx-auto row" style={{ minWidth: '100%' }}>
                          {podcastlist.map((item, index) => (
                            // <div className='mx-2'>
                            <div key={index} className="card my-4  mx-2 px-0 hoverb" style={{ width: '300px' }}>
                              <img src={item.imageUrl || noimg} className="card-img-top" style={{ width: '100%', height: '300px' }} alt='' onError={(e) => { e.target.src = noimg }} />
                              <div className="card-body">
                                <h5 className="card-title mb-3">{item.title} </h5>
                                {/* <p className="card-text">{item.description}</p> */}
                                <div className="pt-4 mx-auto d-flex justify-content-center">
                                  <div style={{ position: 'absolute', bottom: '10px' }}>

                                    <button
                                      className="btn btn-primary"
                                      onClick={async () => {
                                        console.log(item.id)
                                        setPodcastepisodelist([])
                                        setPodcastsearchid(item.id)
                                        setPodcastname(item.title)
                                      }}
                                      type="button"
                                      id="button-addon2"
                                    >
                                      Episode List
                                    </button>

                                  </div>
                                </div>
                              </div>
                            </div>
                            // </div>
                          ))}
                        </div>

                      </div> : ''
                  }

                  {
                    toogleSearchEp === true ?
                      <div className="input-group mb-3 container col-lg-5 col-12">
                        <h5 className='d-flex justify-content-center col-12 mx-auto'>Search Episode</h5>

                        <input
                          type="text"
                          placeholder='Search Podcasts'
                          value={searchTermEpisode}
                          className="form-control col-10 rounded-2 mx-1"
                          onChange={(e) => setSearchTermEpisode(e.target.value)}
                          // onKeyPress={handleKeyPress}
                          aria-label="search"
                        />
                        <button
                          className="btn btn-primary rounded-2 mx-1"
                          onClick={handleSearchSpecificEpisode}
                          type="button"
                          id="button-addon2"
                          disabled={searchTermEpisode.trim() === ''}
                        >
                          Search
                        </button>
                        
                        <div className="d-flex justify-content-center mx-auto row mt-3" style={{ minWidth: '100%' }}>
                        <h5 className='d-flex justify-content-center col-12 mx-auto'>{podcastname}</h5>
                        
                          <table style={{}} className=" col-11 mx-auto bg-white border shadow-lg">


                            <thead className="bg-gray-100  ">
                              <tr className="">
                                <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                                  Sr. No.
                                </th>
                                <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                                  Episode
                                </th>
                                <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                                  Generate Summary
                                </th>
                              </tr>
                            </thead>

                            <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                              {
                                podcastepisodelist?.length === 0 && loadingP === false ?
                                  <h3 className="flex justify-center mx-3">No Episodes</h3> : ''
                              }
                              {
                               podcastepisodelist?.length === 0 && loadingP === true ? <ThreeDots type="Oval" position="top-center" className='d-flex justify-content-center mx-auto' color="black" height={50} width={50} /> : ''
                              }
                              {podcastepisodelist.map((item, index) => (
                                <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                                  <td
                                    className="py-2 px-4 border-b text-center"
                                    style={{ minWidth: '50px' }}
                                  >
                                    {(index + 1)}.
                                  </td>
                                  <td
                                    className="py-2 px-4 border-b text-center"
                                    style={{ minWidth: '150px' }}
                                  >
                                    {item.title}
                                  </td>
                                  <td
                                    className="py-2 px-4 border-b text-center"
                                    style={{ minWidth: '50px' }}
                                  >
                                    <button type="button" value={item.title} onClick={() => { console.log(item, podcastsearchid); handleGenerateSpecificSummary(item.title, podcastsearchid) }} className="btn btn-maroon" >Generate Summary</button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
    <tr>
      <td colSpan="100%" className="text-end">
        <button
          className="btn btn-primary m-2 rounded-2"
          onClick={() => { 
            setToogleSearchPod(true); 
            setToogleSearchEp(false); 
            setPodcastsearchid(''); 
          }}
          type="button"
          id="button-addon2"
        >
          Podcasts
        </button>
      </td>
    </tr>
  </tfoot>
                          </table>
                        </div>
                        
                      </div> : ''
                  }
                  {
                    specificGeneratedSummary.length !== 0 ?
                      <div className="d-flex justify-content-center mx-auto row" style={{ minWidth: '100%' }}>
                        <div className="card my-4  mx-2 px-0" style={{ width: '800px', backgroundColor: "#89c8ff", border: '1px solid ' }}>
                          <img src={specificGeneratedSummary.imagePod || noimg} className="card-img-top mx-auto mt-5" style={{ width: '600px' }} alt='' onError={(e) => { e.target.src = noimg }} />
                          <div className="card-body">
                            <h1 className="card-title my-3">{specificGeneratedSummary.title}</h1>
                            <h3 className="card-title my-4">{specificGeneratedSummary.episode}</h3>
                            {/* <p>{item.summary.replace(/<br>/g, '<br/>').replace(/&bull;/g, '•')}</p> */}
                            <p className='fs-5'>{renderSummary(specificGeneratedSummary.podcastsumClaude)}</p>
                          </div>
                        </div>

                      </div> : ''
                  }

                </div>
              </div>

            </> : <></>
        }

        {
          sideNavChoose === 'Podcasters' ?
            <>
              <h1 className='pt-4'>Podcasters</h1>
              <div className=' d-flex justify-content-center col-12 mx-auto mt-4'>
                {loadingPodcasters ? (
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-content-center mx-auto' color="black" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>
              <div className="overflow-x-auto mx-2 mt-4">

                <div>
                  <button disabled={page === 1} className=' ' onClick={(() => handleLeft())} ><GoTriangleLeft /></button>
                  <span className='  p-3 '>{page}</span>
                  <button className='  ' onClick={(() => handleRight(podcasterList?.length))} ><GoTriangleRight /></button>

                </div>

                <div className="input-group-container mb-3 p-1 container col-lg-8 col-12 mt-2">
                  <input
                    type="text"
                    placeholder='Search the Podcasters...'
                    style={{ backgroundColor: '#5262B4', color: 'white', borderColor: 'white' }}
                    value={searchTerm}
                    className="form-control search-input p-2 py-3"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                    aria-label="search"
                  />
                  <button
                    className="btn search-button px-4 py-2"
                    onClick={handleSearchTerm}
                    type="button"
                    id="button-addon2"

                  >
                    Browse Podcasters
                  </button>
                </div>


                <table style={{}} className=" col-11 mx-auto bg-white border shadow-lg">
                  <thead className="bg-gray-100  ">
                    <tr className="">
                      <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                        Sr. No.
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Podcasts
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Details
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Latest Air Date
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                      podcasterList?.length === 0 ?
                        <h3 className="flex justify-center mx-3">No Podcasts</h3> : ''
                    }
                    {podcasterList.map((item, index) => (
                      <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '50px' }}
                        >
                          {(page - 1) * 10 + (index + 1)}.
                        </td>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '150px' }}
                        >
                          {item.title}
                        </td>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '50px' }}
                        >
                          <a
                            href={`/podcasters/${transformTitle(item.title)}`}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/podcasters/${transformTitle(item.title)}`);
                            }}
                            className="link"
                          >
                            {`https://podcastsummarize.com/podcasters/${transformTitle(item.title)}`}
                          </a>

                        </td>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '150px' }}
                        >
                          {item.airDate}
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </table>



                <div className=' flex justify-center mx-auto mt-4'>
                  {/* {loading ? (
// <div>hello</div>
<ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

) : (
''
)} */}
                </div>
              </div>

              {/* {
            detailname !== '' ?

              <h3 className='mt-4  ' style={{ wordBreak: 'break-word' }}>Details of {detailname}</h3> : ''
          }
          {
            detailname !== '' && detailsub?.length === 0 ?
              <h3 className='mt-4  ' style={{ wordBreak: 'break-word' }}>No Data</h3> : ''

          }

          <div className="">

            {
              detailsub?.length === 0 ?
                <h3 className="flex justify-center mx-3 pt-3"></h3> : ''
            }
            <div className='d-flex justify-content-center flex-wrap'>
              {detailsub.map((item, index) => (
                <div key={index} className="card my-4 col-lg-3 mx-2 px-0 hoverb" style={{ width: '300px' }}>
                  <img src={item.image || noimg} className="card-img-top" style={{ width: '100%',height:'300px' }} alt='' onError={(e) => { e.target.src = noimg }} />
                  <div className="card-body">
                    <h5 className="card-title mb-3">{item.title}</h5>
                    <div className="pt-4 mx-auto d-flex justify-content-center">
                      <div style={{ position: 'absolute', bottom: '10px' }}>
                        <button type="button" value={item} onClick={(e) => setPodcastdetails({
                          'image': item.image,
                          'podTitle': item.podTitle,
                          'podcastUrl': item.podcastUrl,
                          'rssUrl': item.rssUrl,
                          'summary': item.summary,
                          'summaryClaude': item.summaryClaude,
                          'title': item.title,
                          'url': item.url,
                          'sentToLT': item.sentToLT,
                          'podId': item.podId
                        })} className="btn btn-maroon " >Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {
              podcastdetails !== null ?
                <PodcastPopup
                  podcastdetails={podcastdetails}
                  showModal={showModal}
                  promptData={prompt}
                  handleClose={handleCloseModal}
                /> : <></>
            }


            <div className=' flex justify-center mx-auto mt-4'>
              {loading ? (
                // <div>hello</div>
                <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

              ) : (
                ''
              )}
            </div>
          </div> */}
            </> : <></>
        }

        {
          sideNavChoose === 'Episodes' ?
            <>
              <h1 className='pt-4'>Episodes</h1>
              <div className=' d-flex justify-content-center col-12 mx-auto mt-4'>
                {loadingPodcastersEp ? (
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-content-center mx-auto' color="black" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>

              <div className="overflow-x-auto mx-2 mt-4">

                <div>
                  <button disabled={pageEp === 1} className='' onClick={(() => handleLeftEp())}><GoTriangleLeft /></button>
                  <span className='p-3'>{pageEp}</span>
                  <button className='' onClick={(() => handleRightEp(podcasterListEp?.length))}> <GoTriangleRight /></button>

                </div>

                <div className="input-group-container mb-3 p-1 container col-lg-8 col-12 mt-2">
                  <input
                    type="text"
                    placeholder='Search the Episode...'
                    style={{ backgroundColor: '#5262B4', color: 'white', borderColor: 'white' }}
                    value={searchTermEp}
                    className="form-control search-input p-2 py-3"
                    onChange={(e) => setSearchTermEp(e.target.value)}
                    onKeyPress={handleKeyPressEp}
                    aria-label="search"
                  />
                  <button
                    className="btn search-button px-4 py-2"
                    onClick={handleSearchTermEp}
                    type="button"
                    id="button-addon2"
                  // disabled={searchTermEp.trim() === ''}
                  >
                    Browse Podcasters
                  </button>
                </div>


                <table style={{}} className=" col-11 mx-auto bg-white border shadow-lg">
                  <thead className="bg-gray-100  ">
                    <tr className="">
                      <th className="py-2 px-4 border-b" style={{ minWidth: '50px' }}>
                        Sr. No.
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Podcasts
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Episode
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Details
                      </th>
                      <th className="py-2 px-4 border-b" style={{ minWidth: '150px' }}>
                        Air Date
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                      podcasterListEp?.length === 0 ?
                        <h3 className="flex justify-center mx-3">No Podcasts</h3> : ''
                    }
                    {podcasterListEp.map((item, index) => (
                      <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"} style={{ transition: "background-color 0.3s ease" }}>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '50px' }}
                        >
                          {(pageEp - 1) * 10 + (index + 1)}.
                        </td>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '150px' }}
                        >
                          {item.podTitle}
                        </td>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '150px' }}
                        >
                          {item.title}
                        </td>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '50px' }}
                        >
                          <a
                            href={`/podcasters/${transformTitle(item.podTitle)}/${transformTitle(item.title)}`}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/podcasters/${transformTitle(item.podTitle)}/${transformTitle(item.title)}`);
                            }}
                            className="link"
                          >
                            {`https://podcastsummarize.com/podcasters/${transformTitle(item.podTitle)}/${transformTitle(item.title)}`}
                          </a>
                        </td>
                        <td
                          className="py-2 px-4 border-b text-start"
                          style={{ minWidth: '150px' }}
                        >
                          {item.airDate}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>
                <div className=' flex justify-center mx-auto mt-4'>
                  {/* {loading ? (
// <div>hello</div>
<ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

) : (
''
)} */}
                </div>
              </div>

              {/* {
            detailname !== '' ?

              <h3 className='mt-4  ' style={{ wordBreak: 'break-word' }}>Details of {detailname}</h3> : ''
          }
          {
            detailname !== '' && detailsub?.length === 0 ?
              <h3 className='mt-4  ' style={{ wordBreak: 'break-word' }}>No Data</h3> : ''

          }

          <div className="">

            {
              detailsub?.length === 0 ?
                <h3 className="flex justify-center mx-3 pt-3"></h3> : ''
            }
            <div className='d-flex justify-content-center flex-wrap'>
              {detailsub.map((item, index) => (
                <div key={index} className="card my-4 col-lg-3 mx-2 px-0 hoverb" style={{ width: '300px' }}>
                  <img src={item.image || noimg} className="card-img-top" style={{ width: '100%',height:'300px' }} alt='' onError={(e) => { e.target.src = noimg }} />
                  <div className="card-body">
                    <h5 className="card-title mb-3">{item.title}</h5>
                    <div className="pt-4 mx-auto d-flex justify-content-center">
                      <div style={{ position: 'absolute', bottom: '10px' }}>
                        <button type="button" value={item} onClick={(e) => setPodcastdetails({
                          'image': item.image,
                          'podTitle': item.podTitle,
                          'podcastUrl': item.podcastUrl,
                          'rssUrl': item.rssUrl,
                          'summary': item.summary,
                          'summaryClaude': item.summaryClaude,
                          'title': item.title,
                          'url': item.url,
                          'sentToLT': item.sentToLT,
                          'podId': item.podId
                        })} className="btn btn-maroon " >Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {
              podcastdetails !== null ?
                <PodcastPopup
                  podcastdetails={podcastdetails}
                  showModal={showModal}
                  promptData={prompt}
                  handleClose={handleCloseModal}
                /> : <></>
            }


            <div className=' flex justify-center mx-auto mt-4'>
              {loading ? (
                // <div>hello</div>
                <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

              ) : (
                ''
              )}
            </div>
          </div> */}
            </> : <></>
        }

        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
      </div>
    </div>
  )
}

export default Section1SA

{/* <HashLink className='btn btn-link px-0 ' to={`/superadminBot/${x.bot_id}#${x.err_id}`} scroll={el => scrollWidthOffset(el)} style={{ textDecoration: 'underline', color: '#FFFFFF' }}   >Details</HashLink> */ }