import React from 'react'
import NavbarTwo from '../Components/Common/NavbarTwo'
import Footer2 from '../Components/Common/Footer2'
import Section1 from '../Components/SamplePod/Section1'


const SamplePod = () => {
  return (
    <div>
         <NavbarTwo/>
        <Section1/>
        <Footer2/>
        </div>
  )
}

export default SamplePod