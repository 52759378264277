import React, { useState, useEffect } from 'react'
import { GoTriangleLeft } from "react-icons/go";
import { GoTriangleRight } from "react-icons/go";
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios'
import noimg from './assets/noimg.png'
import './assets/podcast.css'
import { useNavigate } from 'react-router-dom';

const PodcastImage = ({ imageUrl, noimg, timeoutDuration = 2000 }) => {
    const [src, setSrc] = useState(imageUrl);
  
    useEffect(() => {
      const img = new Image();
      img.src = imageUrl;
  
      const timer = setTimeout(() => {
        setSrc(noimg);
      }, timeoutDuration);
  
      img.onload = () => {
        clearTimeout(timer);
        setSrc(imageUrl);
      };
  
      img.onerror = () => {
        clearTimeout(timer);
        setSrc(noimg);
      };
  
      return () => clearTimeout(timer);
    }, [imageUrl, noimg, timeoutDuration]);
  
    return (
      <img
        style={{ height: '200px', width: '200px' }}
        className="rounded-4 imgM"
        alt="podcast"
        src={src}
      />
    );
  };

const Section1Podcasters = () => {

    const navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [podcasterlist, setPodcasterlist] = useState([])

   

    const handleLeft = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    const handleRight = () => {
        setPage(page + 1)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (searchTerm.trim() !== '') {
                handleSearchTerm();
            } else {
                handleSearch()
            }
        }
    };

    const handleSearch = () => {
        axios.post(`${process.env.REACT_APP_URL}podcasters`, { batch_size: 10, page_number: page }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            setPodcasterlist(res.data)
            console.log(res.data,"==list")
            // console.log(res.data)
            if (res.data.length === 0) {
                setPage(page - 1)
            }
        }).catch(err => { console.log(err) })
    }

    const handleSearchTerm = () => {
        axios.post(`${process.env.REACT_APP_URL}podcastersSearch`, { batch_size: 10, page_number: page, searchTerm }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            setPodcasterlist(res.data)
            // console.log(res.data)
            if (res.data.length === 0 && page > 1) {
                setPage(page - 1)
            }
        }).catch(err => { console.log(err) })
    }

    useEffect(() => {
        if (searchTerm === '') {
            handleSearch()
        } else {
            handleSearchTerm()
        }

    }, [page])

    const transformTitle = (title) => {
        // Replace spaces with hyphens
        let transformed = title.replace(/ /g, '-');
        
        // Replace special characters except apostrophes and periods with hyphens
        transformed = transformed.replace(/[^\w'.-]/g, '-');
        
        return transformed;
      };
    
    const handlePodcasterDetail = (event) => {
        const title = event.target.getAttribute('data-title');
        const podId = event.target.getAttribute('data-podId');
        navigate(`/podcasters/${transformTitle(title)}`)
    }

    const MAX_PARAGRAPH_LENGTH = 200; // Maximum characters before truncation

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text; // Return full text if within limit
    } else {
        return text.slice(0, maxLength) + '...'; // Truncate and add three dots
    }
};

    return (

        <div>
            <div
                style={{ minHeight: "80vh", background: "#8086FF" }}
                className=" min-h-screen pt-5"
            >
                <h2 className='my-3 text-white'>Podcasters</h2>
                <div >
                    <button className='' onClick={(() => handleLeft())}><GoTriangleLeft  /></button>
                    
                    <span className='fs-4 p-3'>{page}</span>
                    <button className=''  onClick={(() => handleRight())}><GoTriangleRight  /></button>
                </div>

                <div className="input-group-container mb-3 p-1 container col-lg-8 col-12 mt-2">
                            <input
                                type="text"
                                placeholder='Search the Podcasters...'
                                style={{ backgroundColor: '#5262B4', color: 'white', borderColor: 'white' }}
                                value={searchTerm}
                                className="form-control search-input p-2 py-3"
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={handleKeyPress}
                                aria-label="search"
                            />
                            <button
                                className="btn search-button px-4 py-2"
                                onClick={handleSearchTerm}
                                type="button"
                                id="button-addon2"
                                disabled={searchTerm.trim() === ''}
                            >
                                Browse Podcasters
                            </button>
                        </div>

                        <div className="row mx-3 mt-2">
    {podcasterlist.map((item, index) => (
       <div key={index} className="d-flex px-4 py-4 col-xl-6 wrapSubCard" style={{ maxWidth: '900px' }}>
       <PodcastImage imageUrl={item.image || noimg} noimg={noimg} timeoutDuration={5000} />
       <div className="d-flex flex-column  mx-3" style={{ color: 'white' }}>
           <h2 className='text-start'>{item.title}</h2>
           <p className="mt-3 text-start pb-3" style={{ borderBottom: '1px solid white' }}>
               {truncateText(item.description || "Podcast Description not available. Sorry for the inconvenience.", MAX_PARAGRAPH_LENGTH)}
           </p>
           <button
               type="button"
               value={item.podId}
               className="btn rounded-pill px-5 mt-auto fw-bold"
               style={{ backgroundColor: '#FFAA6C', color: 'white',width:'250px' }}
               data-title={item.title}
               data-podId={item.podId}
               onClick={handlePodcasterDetail}
           >
               Podcaster Details
           </button>
       </div>
   </div>

    ))}
</div>
            </div>
        </div>


        // <div>
        //     <div
        //         style={{ minHeight: "80vh", background: "#8086FF" }}
        //         className=" min-h-screen pt-5"
        //     >
        //         <h2 className='my-3 text-white'>Podcasters</h2>
        //         <div>
        //             <GoTriangleLeft className='fs-4 pb-2' onClick={(() => handleLeft())} />
        //             <span className='fs-4 bg-white px-2 rounded-3'>{page}</span>
        //             <GoTriangleRight className='fs-4 pb-2' onClick={(() => handleRight())} />
        //         </div>

        //         <div className=" mx-2  flex flex-col" style={{ background: "#89c8ff" }}>
        //             <div className="">
        //                 <div className="input-group mb-3 container col-lg-5 col-12">
        //                     <input
        //                         type="text"
        //                         placeholder='Search'
        //                         value={searchTerm}
        //                         className="form-control"
        //                         onChange={(e) => setSearchTerm(e.target.value)}
        //                         onKeyPress={handleKeyPress}
        //                         aria-label="search"
        //                     />
        //                     <button
        //                         className="btn btn-primary"
        //                         onClick={handleSearchTerm}
        //                         type="button"
        //                         id="button-addon2"
        //                         disabled={searchTerm.trim() === ''}
        //                     >
        //                         Search
        //                     </button>
        //                 </div>

        //                 <div className="d-flex justify-content-center mx-auto row" style={{ minWidth: '100%' }}>
        //                     {podcasterlist.map((item, index) => (
        //                         // <div className='mx-2'>
        //                         <div key={index} className="card my-4  mx-2 px-0 hoverb" style={{ width: '300px' }}>
        //                             <img src={item.image || noimg} className="card-img-top" style={{ width: '100%' }} alt='' onError={(e) => { e.target.src = noimg }} />
        //                             <div className="card-body">
        //                                 <h5 className="card-title mb-3">{item.title}</h5>
        //                                 <button className='btn btn-primary' data-title={item.title} data-podId={item.podId} onClick={handlePodcasterDetail}>Podcaster Details</button>
        //                             </div>
        //                         </div>
        //                         // </div>
        //                     ))}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Section1Podcasters