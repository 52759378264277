import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';

const HomeS2Sub2 = () => {

  const BACKEND =process.env.REACT_APP_URL
const[email,setEmail]= useState('')
const[loading,setLoading]=useState(false)

const handleSummarizeUpdate=async()=>{
  setLoading(true)
  if (email ==='') {
    toast.error("Email can't be Empty");
    setLoading(false)
    return;
  }
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  if (!emailRegex.test(email)) {
    toast.error("Invalid Email Format");
    setLoading(false)
    return;
  }

  await axios.post(`${BACKEND}summarizeUpdate`,{email},{
    'Content-type':'application/json', 
    'Accept':'application/json',
    'Access-Control-Allow-Origin':'*'
}).then(res=> {console.log(res.data);if(res.data === 'Already'){toast.success('Already Subscribed', { autoClose: 1000 })}else{toast.success('Subscribed', { autoClose: 1000 })};setEmail('');setLoading(false); setTimeout(() => {
  window.location.reload()
}, 2000)}).catch(err => {console.log(err);toast.error("Some Error Occured");setLoading(false)})

}

  return (
    <div className="homepage-banner" style={{ backgroundColor: '#FFF7F1', padding: '20px 0' }}>
    <div className="container">
      <div className="mx-5 text-center my-5" style={{ backgroundColor: '#FFFCFA', borderTop: '2px solid #C463FF', padding: '20px', borderRadius: '10px' }}>
        <p className="fs-5 col-lg-8 col-xl-7 col-md-10 text-center mx-auto pt-3 pb-4">Sign Up here to get the latest on how we are improving this product as well as the latest experimentation from the Podcast Inc team.</p>
        <div className='row d-flex justify-content-center'>
  
        <div className="input-group mb-3 row py-3" style={{  overflow: 'hidden' }}>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        className="form-control col-lg-7 col-md-8 inputr my-1 py-3"
        placeholder="name@example.com"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        style={{ }}
      />
      <button
        onClick={handleSummarizeUpdate}
        className="btn  col-lg-5 py-3 fs-5 col-md-4 btnr my-1"
        type="button"
        id="button-addon2"
        style={{ backgroundColor:'#FF8730',color:'white' }}
      >
       Send Me Updates
      </button>
    </div>

        {/* <div class="input-group mb-3">
          <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="col-lg-3 col-sm-5 col-10 my-1" id="exampleFormControlInput1" placeholder="name@example.com" />
          <button onClick={() => handleSummarizeUpdate()} type="button" className="btn rounded-pill col-xl-3 col-lg-5 col-sm-6 mx-2 px-2 col-10 my-1" style={{ borderColor: 'grey', backgroundColor: '#9E2063', color: 'white' }}>Yes, Send me Updates</button>
          </div> */}
          <p className="fs-6 fst-italic pt-1">We respect your privacy. Unsubscribe at any time.</p>
          <div className='form-group d-flex justify-content-center mt-4'>
            {loading ? (
              <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={true} />
    </div>
  </div>
  )
}

export default HomeS2Sub2