import React, { useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import img1 from './assets/1.jpg'
import { ThreeDots } from 'react-loader-spinner';
import Navbar from '../Components/Common/Navbar';
import { useNavigate } from 'react-router-dom';
import NavbarTwo from '../Components/Common/NavbarTwo';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './btngradient.css'

import Footer2 from '../Components/Common/Footer2';

import girl from './assets/signingirl.webp'
import rect from './assets/signinrect.webp'
import logo from './assets/logo.png'


const Signup = () => {

  const navigate = useNavigate();


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const [showOTP, setShowOTP] = useState(false)
  const [showMainForm, setShowmainform] = useState(true)
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const lastNameRef = useRef(null);
  const usernameRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const BACKEND = process.env.REACT_APP_URL


  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log("handle submit", password, username)
    setLoading(true)

    if (password === '' || firstName === '' || username === '' || lastName === '' || confirmPassword === '' || phone === '') {
      toast.error("Fill all the Fields");
      setLoading(false);
      return;
    }



    const nameRegex = /[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/;
    if (nameRegex.test(firstName)) {
      toast.error("Name cannot contain numbers or symbols");
      setLoading(false);
      return;
    }
    if (nameRegex.test(lastName)) {
      toast.error("Name cannot contain numbers or symbols");
      setLoading(false);
      return;
    }
    if (password.length < 8) {
      console.log("pass")
      toast.error("Password must be at least 8 characters long");
      setLoading(false);
      return;
    }
    const passRegex = /\s{2,}/;
    if (passRegex.test(password)) {
      toast.error("Invalid Password Format, 2 consecutive white spaces");
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Confirm Password is not same as Password");
      setLoading(false);
      return;
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) {
      toast.error("Invalid Phone Number Format");
      setLoading(false);
      return;
    }


    console.log(username, password)

    await axios.post(`${BACKEND}signup/form`, { username, password, firstName, lastName, phone }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => {
      if (res.data == 'ok') { console.log(res.data); setLoading(false); toast.success("Verification Code sent, check email"); setShowOTP(true); setShowmainform(false) }
      else { console.log(res.data); setLoading(false); toast.error(res.data) }
    })
      .catch(err => { console.log(err, "l"); setLoading(false); toast.error("Some Error Occured") })
  }

  const handleSubmitOtp = async (e) => {
    e.preventDefault()
    setLoading2(true)

    await axios.post(`${BACKEND}signup/getOTP`, { username, otp }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => {
      if (res.data == 'ok') {
        console.log(res.data); setLoading2(false); toast.success("Profile Created Successfully");
        setTimeout(() => {
          navigate('/signin');
        }, 2000);
      }
      else if (res.data.error == 'error') { console.log(res.data.msg); setOtp(''); setLoading2(false); toast.error(res.data.msg); setShowOTP(false); setShowmainform(true) }
      else { console.log(res.data.err); setLoading2(false); toast.error(res.data.err); setOtp(''); setShowmainform(true); setShowOTP(false) }
    }).catch(err => { console.log(err, "l"); setLoading2(false); toast.error("Some Error Occured"); setOtp(''); setShowmainform(true); setShowOTP(false) })

  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.id === 'firstName') {
        lastNameRef.current.focus();
      } else if (e.target.id === 'lastName') {
        usernameRef.current.focus();
      } else if (e.target.id === 'username') {
        phoneRef.current.focus();
      } else if (e.target.id === 'phone') {
        passwordRef.current.focus();
      } else if (e.target.id === 'password') {
        confirmPasswordRef.current.focus();
      } else {
        handleSubmit(e);
      }
    }
  };

  const handleKeyPress2 = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.id === 'otp') {
        handleSubmitOtp(e);
      } else if (e.target.id === 'anotherInputId') {
        // Handle Enter key press for another input field
      }
      // Add more conditions as needed for different input fields
    }
  };

  return (
    <>
      {/* <Navbar/> */}
      <NavbarTwo />
      <div className='row'>
        <div className="container   mt-1  col-xl-5 col-lg-6 col-12" style={{ zIndex: '5' }}>
          {showMainForm ?
          <div className="form-container">
           
            <form action="#" className="mt-1 register-form rounded-3 mx-auto col-10  mx-1 " style={{ minWidth: '300px', paddingTop: '0px', minWidth: '270px', border: '1px  lightgrey' }}>
              <div className="row">
                <div className='py-3 px-0  '>
                
                  <img src={logo} style={{ width: '100px' }} />
                  <p className=' text-center fs-2 px-0 my-0'>Create Your Account</p>
                  <p className=' text-center fs-5 px-0'>to continue to Podcast Summarizer</p>
                 
                </div>
               
                <div className="col-sm-6  col-12 px-2 mb-3">
                  <label htmlFor="firstName" className="mb-4 fs-5 text-black d-flex justify-content-start">
                    First Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control rounded-pill py-3"
                      style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
                      placeholder="First Name"
                      id="firstName"
                      required
                      aria-label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      onKeyPress={handleKeyPress}
                      tabIndex={1}
                    />
                  </div>
                </div>
                <div className="col-sm-6  col-12 px-2 mb-3">
                  <label htmlFor="lastName" className="mb-4 fs-5 text-black d-flex justify-content-start">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control rounded-pill py-3"
                      style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
                      placeholder="Last Name"
                      id="lastName"
                      required
                      aria-label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      onKeyPress={handleKeyPress}
                      tabIndex={2}
                      ref={lastNameRef}
                    />
                  </div>
                </div>
                <div className="col-sm-6  col-12 px-2 mb-3">
                  <label htmlFor="username" className="mb-4 fs-5 text-black d-flex justify-content-start">
                    Email Address<span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control rounded-pill py-3"
                      style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
                      placeholder="Email"
                      id="username"
                      required
                      aria-label="Email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyPress={handleKeyPress}
                      tabIndex={3}
                      ref={usernameRef}
                    />
                  </div>
                </div>
                <div className="col-sm-6  col-12 px-2 mb-3">
                  <label htmlFor="phone" className="mb-4 fs-5 text-black d-flex justify-content-start">
                    Phone Number<span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control rounded-pill py-3"
                      style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
                      placeholder="Phone Number"
                      id="phone"
                      required
                      aria-label="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      onKeyPress={handleKeyPress}
                      tabIndex={4}
                      ref={phoneRef}
                    />
                  </div>
                </div>
                <div className="col-sm-6  col-12 px-2 mb-3">
                  <label htmlFor="password" className="mb-4 fs-5 text-black d-flex justify-content-start">
                    Password<span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control rounded-pill py-3"
                      style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
                      placeholder="Password"
                      id="password"
                      required
                      aria-label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handleKeyPress}
                      tabIndex={5}
                      ref={passwordRef}
                    />
                  </div>
                </div>
                <div className="col-sm-6  col-12 px-2 mb-3">
                  <label htmlFor="confirmPassword" className="mb-4 fs-5 text-black d-flex justify-content-start">
                    Confirm Password<span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control rounded-pill py-3"
                      style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
                      placeholder="Confirm Password"
                      id="confirmPassword"
                      required
                      aria-label="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onKeyPress={handleKeyPress}
                      tabIndex={6}
                      ref={confirmPasswordRef}
                    />
                  </div>
                </div>
                <button type="button" onClick={handleSubmit} className="btn btn-gradient mt-3 col-12 py-3 fs-5 col-lg-12 rounded-pill mx-auto">Continue</button>
              </div>
              <div className="input-group mt-4">
                <Link to='/signin' style={{ textDecoration: 'none' }}><Nav.Link href="#link" className='btn link fs-6' style={{ color: 'grey' }}>Have an Account? <span style={{ color: '#5E17EB' }}>Sign in</span></Nav.Link></Link>
              </div>
              <div className='form-group d-flex justify-content-center mt-4'>
                {showMainForm && loading ? (
                  <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
                  // <Skeleton count={5} />
                ) : (
                  ''
                )}
              </div>

            </form> 
            </div>: ''
          }
          {showOTP ?
          
          <form action="#" className="mt-4 register-form rounded-3 mx-1 col-10 mx-auto" style={{ minWidth: '270px', backgroundColor: 'white', height:'70vh'}}>
          <div className="row">
            <div className="py-3 px-0">
              <p className="fw-bold text-center px-0 fs-4 my-0">Create Your Account</p>
              <p className="text-center px-0">to continue to Podcast Summarizer</p>
            </div>
            <div className="col-12 px-2 mb-3">
              <label htmlFor="otp" className="mb-1 fw-bold text-black d-flex justify-content-start">
                Verification Code received via Email<span className="text-danger">*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control rounded-pill py-3"
                  style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
                  placeholder="Verification Code"
                  id="otp"
                  required
                  aria-label="Verification Code"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  onKeyPress={handleKeyPress2}
                  tabIndex={1}
                />
              </div>
            </div>
            <div className="col-12 px-2 mb-3">
              <button type="button" onClick={handleSubmitOtp} className="btn btn-gradient mt-3 col-12 py-3 fs-5 col-lg-12 rounded-pill mx-auto">Continue</button>
            </div>
          </div>
          <div className="input-group mt-4">
            <Link to="/signin" style={{ textDecoration: 'none' }}>
              <Nav.Link href="#link" className="btn link fs-6" style={{ color: 'grey' }}>
                Have an Account? <span style={{ color: '#5E17EB' }}>Sign in</span>
              </Nav.Link>
            </Link>
          </div>
          <div className="form-group d-flex justify-content-center mt-4">
            {loading2 ? (
              <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
            ) : (
              ''
            )}
          </div>
        </form> : ''
          }

        </div>


        <div className='col-xl-7 col-lg-6 col-12  position-relative mt-auto mx-auto image-below-lg-noshow' style={{ maxHeight: '80vh', }}>
          <img className='image1  position-absolute' style={{ bottom: '0', right: '0px', maxHeight: '70vh',minHeight:'100%', width: '100%', maxWidth: '800px' }} src={rect} alt='Rectangle' />
          <img className='image2  position-absolute' style={{ bottom: '0', right: '50px', maxHeight: '80vh',minHeight:'100%', width: '100%', maxWidth: '800px' }} src={girl} alt='Girl' />
        </div>

        

        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
      </div>
      <Footer2 />
    </>
  )
}

export default Signup