// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hoverB{
    color:rgb(75, 75, 75);
}
.hoverB:hover{
    background-color: #C463FF;
    color:whitesmoke
    
}
.selectB{
    background-color: #C463FF;
    border-color: #dbb6c7;
    color:whitesmoke;
}
.hoverpink {
    border: 1px solid white;
}

.hoverpink:hover {
    background-color: #8906E6;
    border: 1px solid white;
    color: white; /* White text color on hover for better visibility */
}

@media screen and (min-width: 381px) {
    .width-card {
      width: 340px;
    }
  }

  @media screen and (min-width: 1295px) {
    .banner-font-size{
        font-size:3.4rem
    }
    .smal-font-size{
        font-size:1.2rem ;
    }
}
@media screen and (min-width: 1294px) and (max-width: 1294px) {
    .banner-font-size{
        font-size:2.4rem
    }
}

@media screen and (min-width: 768px) {
    .inputr{
         border-top-left-radius: 50px;
         border-bottom-left-radius:50px;
    }
    .btnr{
        border-top-right-radius: 50px;
        border-bottom-right-radius:50px;
    }
}
@media screen and (max-width: 767px) {
    .banner-font-size{
        font-size:2.4rem
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Home/Home.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;AACzB;AACA;IACI,yBAAyB;IACzB;;AAEJ;AACA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,YAAY,EAAE,oDAAoD;AACtE;;AAEA;IACI;MACE,YAAY;IACd;EACF;;EAEA;IACE;QACI;IACJ;IACA;QACI,iBAAiB;IACrB;AACJ;AACA;IACI;QACI;IACJ;AACJ;;AAEA;IACI;SACK,4BAA4B;SAC5B,8BAA8B;IACnC;IACA;QACI,6BAA6B;QAC7B,+BAA+B;IACnC;AACJ;AACA;IACI;QACI;IACJ;AACJ","sourcesContent":["\n.hoverB{\n    color:rgb(75, 75, 75);\n}\n.hoverB:hover{\n    background-color: #C463FF;\n    color:whitesmoke\n    \n}\n.selectB{\n    background-color: #C463FF;\n    border-color: #dbb6c7;\n    color:whitesmoke;\n}\n.hoverpink {\n    border: 1px solid white;\n}\n\n.hoverpink:hover {\n    background-color: #8906E6;\n    border: 1px solid white;\n    color: white; /* White text color on hover for better visibility */\n}\n\n@media screen and (min-width: 381px) {\n    .width-card {\n      width: 340px;\n    }\n  }\n\n  @media screen and (min-width: 1295px) {\n    .banner-font-size{\n        font-size:3.4rem\n    }\n    .smal-font-size{\n        font-size:1.2rem ;\n    }\n}\n@media screen and (min-width: 1294px) and (max-width: 1294px) {\n    .banner-font-size{\n        font-size:2.4rem\n    }\n}\n\n@media screen and (min-width: 768px) {\n    .inputr{\n         border-top-left-radius: 50px;\n         border-bottom-left-radius:50px;\n    }\n    .btnr{\n        border-top-right-radius: 50px;\n        border-bottom-right-radius:50px;\n    }\n}\n@media screen and (max-width: 767px) {\n    .banner-font-size{\n        font-size:2.4rem\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
