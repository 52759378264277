// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoverb:hover{
    background-color: rgb(242, 242, 242);
    border-radius: 15px;
}
.hoverbb{
    color: white;
}
.hoverbb:hover{
    color: yellow;
}

.hoverf:hover{
    background-color: #309bc9;
    border-radius: 15px;

}

.custom-border {
    display: inline-block;
    position: relative;
    padding-bottom: 5px; /* Adjust as needed */
    margin: 0 auto; /* Center align */
  }
  
  .custom-border::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust thickness as needed */
    background: linear-gradient(
      to right,
      orange 0%,
      orange 35%,
      rgba(255, 165, 0, 0.5) 40%,
      #EFEFEF 45%,
      #EFEFEF 55%,
      rgba(255, 165, 0, 0.5) 60%,
      orange 65%,
      orange 100%
    );
  }`, "",{"version":3,"sources":["webpack://./src/Components/Common/Navbar.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;;AAEvB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB,EAAE,qBAAqB;IAC1C,cAAc,EAAE,iBAAiB;EACnC;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,WAAW,EAAE,+BAA+B;IAC5C;;;;;;;;;;KAUC;EACH","sourcesContent":[".hoverb:hover{\n    background-color: rgb(242, 242, 242);\n    border-radius: 15px;\n}\n.hoverbb{\n    color: white;\n}\n.hoverbb:hover{\n    color: yellow;\n}\n\n.hoverf:hover{\n    background-color: #309bc9;\n    border-radius: 15px;\n\n}\n\n.custom-border {\n    display: inline-block;\n    position: relative;\n    padding-bottom: 5px; /* Adjust as needed */\n    margin: 0 auto; /* Center align */\n  }\n  \n  .custom-border::after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 1px; /* Adjust thickness as needed */\n    background: linear-gradient(\n      to right,\n      orange 0%,\n      orange 35%,\n      rgba(255, 165, 0, 0.5) 40%,\n      #EFEFEF 45%,\n      #EFEFEF 55%,\n      rgba(255, 165, 0, 0.5) 60%,\n      orange 65%,\n      orange 100%\n    );\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
