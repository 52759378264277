import React from 'react'
import img1 from './assets/sam1.png'
import img2 from './assets/sam2.png'
import img3 from './assets/sam3.png'

const Section1 = () => {
  return (
    <div  className="homepage-banner  " style={{ paddingTop: '30px', paddingBottom: '30px', backgroundColor: '#F8FAFC' }}>
            <div className="container text-center py-5 col-lg-5">
                <h1 className='fs-1 ' style={{ color: '#0F172A' }}>Podcast Summarizer Samples</h1>
                <p className="lead pt-3">Podcast Summarize is revolutionizing the way podcast lovers, busy professionals, avid learners, and even those new to podcasts engage with content, enabling a more efficient and enriched listening experience.</p>
            </div>    
                <div className='row d-flex justify-content-center '>
                <img style={{width:'100%',height:'100%', maxHeight:'600px',maxWidth:'600px'}} className='rounded-3' src={img1}/>
                <img style={{width:'100%',height:'100%', maxHeight:'600px',maxWidth:'600px'}} className='rounded-3' src={img3}/>
                 <img style={{width:'100%',height:'100%', maxHeight:'600px',maxWidth:'600px'}} className='rounded-3' src={img2}/>
                   
                </div>
            </div>
  )
}

export default Section1