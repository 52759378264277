import React, {useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";
import {  useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import './Navbar.css'
import logo from './assets/logo.png'


const NavbarTwo = () => {

  const navigate = useNavigate();
  const [token,setToken]=useState('')
  const [admin_token,setAdmin_token]=useState('')

  const refreshAccessToken = async () => {
    try {
      const refreshToken = Cookies.get('refresh_token');
      const response = await axios.post(`${process.env.REACT_APP_URL}login/refresh`, {
        refresh_token: refreshToken,
      },{
        headers: {
          'Content-type':'application/json', 
          'Accept':'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
  
      if (response.data.token) {
        // Update the access token in cookies or localStorage
        Cookies.set('token', response.data.token, { expires: 1, path: '/' });
        if(response.data.admintoken){
        Cookies.set('admin_token', response.data.admintoken, { expires: 1, path: '/' });
        }
        console.log("Token Refreshed");
      } else {
        console.log(response.data.error);
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
    }
  };
  
  // Call refreshAccessToken every 5 minutes (300,000 milliseconds)
  let intervalId;
  const startTokenRefresh = () => {
    intervalId = setInterval(refreshAccessToken, 300000);
  };
  const stopTokenRefresh = () => {
    clearInterval(intervalId);
  };
  
  useEffect(() => {
    if (token === '' && Cookies.get('token') !== undefined) {
      setToken(Cookies.get('token'));
      console.log(Cookies.get('token'));
    }
    if(admin_token ==='' && Cookies.get('admin_token')!== undefined){
      setAdmin_token(Cookies.get('admin_token'));
    }
  }, [token]); // Include token in dependency array
  
  useEffect(() => {

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refreshAccessToken();
        // console.log("VVVVVVVVVVV")
      }
      // console.log("VVVVVVVVVVVVVVVvvvvvv aaaaaaaaaaa out")
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    startTokenRefresh(); // Start token refresh when component mounts
    return () => stopTokenRefresh(); // Cleanup function to stop interval when component unmounts
  }, []); 


const handleLogout=()=>{
  Cookies.remove('token')
  Cookies.remove('refresh_token')
  Cookies.remove('admin_token')
  console.log('logout')
  window.location.href = '/';

}



  return (
    <div>
       <nav className="navbar navbar-expand-lg navbar-light py-1" style={{backgroundCoor:'white', borderBottom:'1px solid #FF8730'}}>
       <div className="container-fluid">
       <NavLink 
  className="navbar-brand" 
  to="/" 
  style={{ display: 'inline-block', pointerEvents: 'none' }}  // NavLink itself is not clickable
>
  <img 
    src={logo} 
    alt="Logo" 
    style={{ 
      height: '85px', 
      width: '85px', 
      borderRadius: '100%', 
      pointerEvents: 'auto'  // Image itself is clickable
    }} 
    className="mx-2" 
  />
</NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
      <ul className="navbar-nav" style={{ color: 'black' }}>
        {token !== '' && (
          <li className="nav-item hoverb mx-3">
            <NavLink className="nav-link my-1  fs-5 fw-bold" style={{ color: 'black' }} to="/podcast">Podcasts</NavLink>
          </li>
        )}
        {admin_token !== '' && (
          <li className="nav-item hoverb mx-3">
            <NavLink className="nav-link my-1  fs-5 fw-bold" style={{ color: 'black' }} to="/superadmin">Admin</NavLink>
          </li>
        )}
      </ul>
     
    </div>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
      
      <ul className="navbar-nav" style={{ color: 'black' }}>
        {token === '' ? (
          <>
            <li className="nav-item">
              <NavLink className="nav-link active rounded-pill my-1 px-5 mx-3 fs-5" style={{ backgroundColor: '#FF8730', color: 'white' }} to="/signin">
                Sign In
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link mx-3 rounded-pill my-1 hoverbb px-5 fs-5" style={{ color: '#FF8730', backgroundColor: 'white', border: '1px solid #FF8730' }} to="/signup">
                Sign Up
              </NavLink>
            </li> 
          </>
        ) : (
          <li className="nav-item">
            <NavLink className="nav-link mx-3 rounded-pill my-1 hoverbb px-5 fs-5" style={{ color: '#FF8730', border: '1px solid #FF8730', backgroundColor: 'white' }} onClick={() => handleLogout()}>
              Log out
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  </div>
  </nav></div>
  )
}

export default NavbarTwo