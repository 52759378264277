import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import { GoTriangleLeft } from "react-icons/go";
import { GoTriangleRight } from "react-icons/go";
import noimg from './assets/noimg.png'
// import './assets/podcast.css'
import '../PodcastersDetails/assets/podcast.css'
import { useNavigate } from 'react-router-dom';


const PodcastImage = ({ imageUrl, noimg, timeoutDuration = 2000 }) => {
    const [src, setSrc] = useState(imageUrl);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;

        const timer = setTimeout(() => {
            setSrc(noimg);
        }, timeoutDuration);

        img.onload = () => {
            clearTimeout(timer);
            setSrc(imageUrl);
        };

        img.onerror = () => {
            clearTimeout(timer);
            setSrc(noimg);
        };

        return () => clearTimeout(timer);
    }, [imageUrl, noimg, timeoutDuration]);

    return (
        <img
            style={{ minWidth: '250px', maxHeight: '250px', maxWidth: '250px', objectFit: 'cover', width: '100%' }}
            className="rounded-4 m-auto "
            alt="podcast"
            src={src}
        />
    );
};

const PodcastImage2 = ({ imageUrl, noimg, timeoutDuration = 2000 }) => {
    const [src, setSrc] = useState(imageUrl);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;

        const timer = setTimeout(() => {
            setSrc(noimg);
        }, timeoutDuration);

        img.onload = () => {
            clearTimeout(timer);
            setSrc(imageUrl);
        };

        img.onerror = () => {
            clearTimeout(timer);
            setSrc(noimg);
        };

        return () => clearTimeout(timer);
    }, [imageUrl, noimg, timeoutDuration]);

    return (
        <img
            style={{ minWidth: '200px', maxHeight: '200px', maxWidth: '200px', objectFit: 'cover', width: '100%' }}
            className="rounded-4 m-sm-0 m-auto"
            alt="podcast"
            src={src}
        />
    );
};

const Section1PodcastersDetails = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams(); // Access the id parameter

    console.log("only id---",id)

    

const podcasterLink = id

    const [page, setPage] = useState(1)
    const [podList, setPodlist] = useState([])
    const [pTitle, setPTitle] = useState('')
    const [podcastDetail, setPodcastDetail] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const handleLeft = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    const handleRight = () => {
        setPage(page + 1)
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (searchTerm.trim() !== '') {
                handleSearchTerm()
            } else {
                handleSearch()
            }
        }
    };

    const handleSearch = () => {
        // console.log(state," --- ",podTitle)
        axios.post(`${process.env.REACT_APP_URL}podcastersSearchDetails`, { batch_size: 10, page_number: page, podcasterLink }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            console.log(res.data)
            if (res.data[0].length === 0 && page > 1) {
                setPage(page - 1)
            } else {
                setPodlist(res.data)
                console.log(res.data,"---137")

            }
        }).catch(err => { console.log(err) })

    }

    useEffect(() => {
        handleSearch()
    }, [])

    const handleSearchTerm = () => {
        // console.log(searchTerm)
        if (searchTerm === '') {

            handleSearch()
            return
        }
        axios.post(`${process.env.REACT_APP_URL}podcastersSearchDetailsSearchTerm`, { batch_size: 10, page_number: page, podcasterLink,  searchTerm }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            // console.log(res.data)
            if (res.data.length === 0 && page > 1) {
                setPage(page - 1)
            } else {
                setPodlist(res.data)
                // console.log(res.data)

            }
        }).catch(err => { console.log(err) })
    }

    useEffect(() => {
        if (searchTerm === '') {
            handleSearch()
        } else {
            handleSearchTerm()
        }

    }, [page])

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_URL}podcastersPodcastDetail`, { podcasterLink }
            , {
                headers: {
                    'Content-Type': 'application/json' // Optional: Specify content type if needed
                }
            }
        ).then(res => {
            setPodcastDetail(res.data)
            console.log(res.data,"----191")
        }).catch(err => { console.log(err,"-------192") })

    }, [])

    const transformTitle = (title) => {
        // Replace spaces with hyphens
        let transformed = title.replace(/ /g, '-');
        
        // Replace special characters except apostrophes and periods with hyphens
        transformed = transformed.replace(/[^\w'.-]/g, '-');
        
        return transformed;
      };

    const handlePodcasterDetail = (event) => {
        const episode = event.target.getAttribute('data-episode');
        const podTitle = event.target.getAttribute('data-podTitle');
        const podId = event.target.getAttribute('data-podId');
        console.log(episode,podId,podTitle)
        navigate(`/podcasters/${transformTitle(podTitle)}/${transformTitle(episode)}` )
    }

    const MAX_PARAGRAPH_LENGTH = 200; // Maximum characters before truncation

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text; // Return full text if within limit
        } else {
            return text.slice(0, maxLength) + '...'; // Truncate and add three dots
        }
    };

    return (
<>
        {
            
            podcastDetail.map((podcastDetail,index1)=>(
    
        <div
            style={{ minHeight: "80vh", background: "#778AFF" }}
            className=" min-h-screen pt-5"
        >
      
       
            <div className='row justify-content-center mx-lg-5 mx-md-3 mx-3'>
                <div style={{ backgroundColor: '#BFC2FE' }} className='rounded-5 mx-lg-5 mx-md-3 mx-sm-2 mx-1 col-xxl-10 col-xl-11 '>
                    <div className="d-flex px-lg-4 px-md-3 px-sm-2 px-1 mx-lg-5 mx-md-3 mx-sm-2 mx-1 wrapSubCard" style={{}}>
                        <PodcastImage imageUrl={podcastDetail.image || noimg} noimg={noimg} timeoutDuration={5000} />
                        <div style={{ color: 'white' }} className="text-start mt-4 mt-md-0 mx-lg-5 mx-md-3 mx-sm-2 mx-1 d-flex flex-column justify-content-center">
                            <h1 className='font-res font-color-trans' >{podcastDetail.podTitle}</h1>
                            <p className="mt-3 pb-3 text-black">
                                {podcastDetail.description || "Podcast Description not available. Sorry for inconvinience."}
                            </p>
                        </div>
                    </div>



                </div>


            </div>

            <div className='row justify-content-xxl-center justify-content-start  mx-0 px-0'>
                <div className='px-lg-5 mt-5 px-md-3 px-sm-2 px-1 col-xxl-10 mx-0'>
                    <p className='text-start mx-0 fs-1 ' style={{ color: 'white', textDecoration: 'underline #FFAA6C 1px', textUnderlineOffset: '15px' }}>Latest <span style={{ color: '#FFAA6C' }}>Episodes</span></p>
                </div>
            </div>

            <div className='row justify-content-xxl-center justify-content-start px-0  mx-0'>
                <div className='px-lg-5 mt-5 px-md-3 px-sm-2 px-1 mx-0 col-xxl-10'>

                <div className="row mx-0 mt-2 ">
                {Array.isArray(podList[index1]) && podList[index1].map((item, index) => (
        <div key={index} className="d-flex pe-2 row  col-lg-6 wrapSubCardpd" style={{ maxWidth: '900px' }}>
            <PodcastImage2 imageUrl={item.image || noimg} style={{width:'250px'}}  noimg={noimg} timeoutDuration={5000} />
            <div className="d-flex flex-column col-sm-7    me-sm-auto ms-sm-0 mx-auto" style={{ color: 'white' }}>
                <h4 className=' text-sm-start text-center mt-sm-0 mt-5'>{item.title}</h4>
                {/* <h4 className='text-sm-start text-center mt-sm-0 mt-5'>Fake Title Testing{index*1090000}</h4> */}
                <h6 className='text-sm-start text-center mt-sm-2 mt-2'>{item.airDate}</h6>
                <button
                    type="button"
                    value={item.podId}
                    className="btn rounded-pill px-5 fw-bold mt-md-auto mt-3 mx-auto me-sm-auto mx-0 mx-sm-0"
                    style={{ backgroundColor: '#FFAA6C', color: 'white',maxWidth:'150px' }}
                    data-episode={item.PodcasterLinkEp} data-podTitle={item.PodcasterLink} data-podId={item.podId}
                    onClick={handlePodcasterDetail}
                >
                    Details
                </button>
            </div>
           
        </div>
    ))}

<div className='mb-3'>
    <button className='' onClick={(() => handleLeft())}><GoTriangleLeft  /></button>
                    <span className='fs-4 p-3'>{page}</span>
                    <button className='' onClick={(() => handleRight())}> <GoTriangleRight  /></button>
                   
                </div>
</div>
                </div>
            </div>


            <div className=" mx-2 mx-lg-5 row mx-sm-3  flex flex-col" style={{ background: "#778AFF" }}>
                <div className=" col-xxl-10 col-xl-11">

                    {/* <div className="input-group mb-3 container col-lg-5 col-12">
                            <input
                                type="text"
                                placeholder='Search'
                                value={searchTerm}
                                className="form-control"
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={handleKeyPress}
                                aria-label="search"
                            />
                            <button
                                className="btn btn-primary"
                                onClick={handleSearchTerm}
                                type="button"
                                id="button-addon2"
                                disabled={searchTerm.trim() === ''}
                            >
                                Search
                            </button>
                        </div> */}



                </div>
            </div>

        </div>
             ))
            }
</>
    )
}

export default Section1PodcastersDetails