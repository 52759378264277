import React from 'react'
import Section1SA from '../Components/SuperAdmin/Section1SA'
import NavbarTwo from '../Components/Common/NavbarTwo'
import Footer3 from '../Components/Common/Footer3'

const SuperAdmin = () => {
  return (
    <div>
        {/* <NavbarTwo/> */}
<Section1SA/>
<Footer3/>

    </div>
  )
}

export default SuperAdmin