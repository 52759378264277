import React,{useState,useEffect} from 'react'
import NavbarTwo from '../Components/Common/NavbarTwo'
import Footer2 from '../Components/Common/Footer2'
import axios from 'axios'
import Cookies from 'js-cookie';
import Button from 'react-bootstrap/Button';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom'
import logo from './assets/logo.png'

const Success = () => {

    const token = Cookies.get('token')
    const[user,setUser]=useState('')
    const[data,setData]=useState('')
    const[product,setProduct]=useState('')
    const[date,setDate]=useState('')
    const[time,setTime]=useState('')
    const[loading,setLoading]=useState(false)

    useEffect(()=>{
      setLoading(true)
      console.log(loading,"29")
      axios.post(`${process.env.REACT_APP_URL}stripepay/order/success`,{},
       {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Optional: Specify content type if needed
        }
      }).then(res => {console.log(res.data); setData(res?.data[0]);setProduct(res?.data[1]);setUser(res?.data[2]); setLoading(false) })
      },[])

      useEffect(()=>{
        const timestamp = data.created;
      
        const dateObj = new Date(timestamp * 1000);
      
        // Extract the date and time components from the Date object
         setDate(dateObj.toLocaleDateString());
        setTime(dateObj.toLocaleTimeString())
      },[data])

  return (
    <div>
    <NavbarTwo />
    <div className='d-flex justify-content-center ' style={{ paddingTop: '100px', paddingBottom: '150px', backgroundColor: '#89c8ff', minHeight: '100vh' }}>
        <div className="card p-3 shadow" style={{ width: '300px', borderRadius: '15px' }}>
            <img src={logo} className='mx-auto' style={{ height: '100px', width: '100px', borderRadius: '50%' }} alt="Logo" />
            <div className="card-body">
                <h5 className="card-title text-start">Payment Successful</h5>
                <hr />
                <p className="card-text text-start"><strong>User:</strong> {user}</p>
                <p className="card-text text-start"><strong>Payment Time:</strong> {time}</p>
                <p className="card-text text-start"><strong>Payment Date:</strong> {date}</p>
                <p className="card-text text-start"><strong>Email:</strong> {data.email}</p>
                <p className="card-text text-start"><strong>Name:</strong> {data.name}</p>
                <p className="card-text text-start"><strong>ID:</strong> {data.id}</p>
                <p className="card-text text-start"><strong>Plan:</strong> {product.product_id}</p>
                <p className="card-text text-start"><strong>Price:</strong> {product.amount / 100} {product.currency}</p>
                <div className="text-center">
                    <Link className='col-sm-5 ms-sm-auto col-11 my-2' to='/' style={{ textDecoration: 'none' }}>
                        <Button className='fw-bolder fs-5 rounded-3 text-center mb-1' style={{ color: '#FFFFFF', backgroundColor: '#242439', borderColor: '#FFFFFF' }} variant="primary">Home Page</Button>
                    </Link>
                </div>
                <div className='form-group d-flex justify-content-center  mt-4'>
        {loading ? (
          <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
        ) : (
          ''
        )}
      </div>
            </div>
        </div>
    </div>
    <Footer2 />
</div>
  )
}

export default Success