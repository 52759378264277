import React, { useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import img1 from './assets/1.jpg'
import axios from 'axios';
import Navbar from '../Components/Common/Navbar';
import {ThreeDots} from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import NavbarTwo from '../Components/Common/NavbarTwo';

import Footer2 from '../Components/Common/Footer2';

import girl from './assets/signingirl.webp'
import rect from './assets/signinrect.webp'
import logo from './assets/logo.png'
import './btngradient.css'

const Signin = () => {

  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const[loading,setLoading]=useState(false)

  const passwordRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
setLoading(true)
    try {
        const response = await axios.post(`${process.env.REACT_APP_URL}login/form`, {username,password},{
        'Content-type':'application/json', 
        'Accept':'application/json',
        'Access-Control-Allow-Origin':'*',
    })
    .then(res => {if(res.data.error){console.log(res.data.error);setLoading(false);toast.error(res.data.error)}
    else if(res.data)
    {console.log(res.data);
      setLoading(false);
      toast.success(res.data);
       Cookies.set('token', res.data.token, { expires: 1, path: '/' });
       Cookies.set('refresh_token', res.data.refresh_token, { expires: 1, path: '/' })
       if (res.data.admintoken){
       Cookies.set('admin_token', res.data.admintoken, { expires: 1, path: '/' })
       }
      
setTimeout(() => { navigate('/') }, 2000)}})
    } catch (error) {
        console.error('Some Error occured:', error); 
        ;setLoading(false);
        toast.error(error)
    
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.id === 'username') {
        passwordRef.current.focus();
      } else {
        handleSubmit(e);
      }
    }
  };




  return (
    <>
 
        <NavbarTwo />
      <div className='row' style={{minHeight:'100%'}}>
        <div className="container   mt-lg-5 mt-1  col-xl-5 col-lg-6 col-12" style={{ zIndex: '5' }}>
          
          <div className="form-container">
           
          <form action="#" className="mt-1 register-form rounded-3 mx-1 col-10 mx-auto" style={{ minWidth: '270px', backgroundColor: 'white', minHeight:'70vh' }}>
  <div className="row">
  <div className='py-3 px-0  '>
                
                <img src={logo} style={{ width: '100px' }} />
                <p className=' text-center fs-2 px-0 my-0'>Welcome Back !</p>
                <p className=' text-center fs-5 px-0'>Sign In to continue to Podcast Summarizer</p>
               
              </div>
    <div className="col-12 px-0">
      <label htmlFor="username" className="mb-4 fs-5 text-black d-flex justify-content-start"S>
        Email Address<span className="text-danger">*</span>
      </label>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control rounded-pill py-3"
          style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
          placeholder="Email"
          id="username"
          required
          aria-label="Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress}
          tabIndex={1}
        />
      </div>
    </div>
    <div className="col-12 px-0">
      <label htmlFor="password" className="mb-4 fs-5 text-black d-flex justify-content-start">
        Password<span className="text-danger">*</span>
      </label>
      <div className="input-group mb-3">
        <input
          type="password"
          className="form-control rounded-pill py-3"
          style={{ border: '1px solid #5B058F', boxShadow: '15px 15px 15px lightgrey' }}
          placeholder="Password"
          id="password"
          required
          aria-label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          tabIndex={2}
          ref={passwordRef}
        />
      </div>
    </div>
    <div className="col-12 px-0">
      <button type="button" onClick={handleSubmit} className="btn btn-gradient mt-3 col-12 py-3 fs-5 col-lg-12 rounded-pill mx-auto">Login</button>
    </div>
  </div>
  <div className="input-group mt-4">
    <Link to="/signup" style={{ textDecoration: 'none' }}>
      <Nav.Link href="#link" className="btn link fs-6" style={{ color: 'grey' }}>
        Don't have an account? <span style={{ color: '#5E17EB' }}>Sign up</span>
      </Nav.Link>
    </Link>
  </div>
  <div className="input-group mt-4">
    <Link to="/forget" style={{ textDecoration: 'none' }}>
      <Nav.Link href="#link" className="btn link fs-6" style={{ color: 'grey' }}>
        <span style={{ color: '#5E17EB' }}>Forgot Password ?</span>
      </Nav.Link>
    </Link>
  </div>
  <div className="form-group d-flex justify-content-center mt-4">
    {loading ? (
      <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
    ) : (
      ''
    )}
  </div>
</form>
            </div>
          
        

        </div>


        <div className='col-xl-7 col-lg-6 col-12  position-relative mt-auto mx-auto image-below-lg-noshow' style={{ maxHeight: '80vh', }}>
          <img className='image1  position-absolute' style={{ bottom: '0', right: '0px', maxHeight: '70vh',minHeight:'100%', width: '100%', maxWidth: '800px' }} src={rect} alt='Rectangle' />
          <img className='image2  position-absolute' style={{ bottom: '0', right: '50px', maxHeight: '80vh',minHeight:'100%', width: '100%', maxWidth: '800px' }} src={girl} alt='Girl' />
        </div>

        {/* <div className='col-xl-7 col-lg-6 col-12  position-relative mt-auto mx-auto image-below-lg-noshow' style={{ maxHeight: '80vh', }}>
          <img className='image1  position-absolute' style={{ bottom: '0', right: '0px', maxHeight: '70vh', width: '100%', maxWidth: '800px' }} src={rect} alt='Rectangle' />
          <img className='image2  position-absolute' style={{ bottom: '0', right: '50px', maxHeight: '80vh', width: '100%', maxWidth: '800px' }} src={girl} alt='Girl' />
        </div> */}

        

        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
      </div>
      <Footer2 />

        </>
  )
}

export default Signin