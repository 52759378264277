import React from 'react'
import { FaCircleUser } from "react-icons/fa6";
import './home4.css'

const HomeS4Copilot = () => {
    return (
        <div id='Testimonials' className="homepage-banner  " style={{ paddingTop: '30px', paddingBottom: '30px', backgroundColor: '#FFF7F1' }}>
            <div className="container text-center py-5 col-lg-8">
                <h1 className='banner-font-size' style={{ color: '#5B058F' }}>AI Augmented Podcast Consumption Tool</h1>
                <p className="lead pt-5 fw-bold fs-5" style={{color:'#FF8730'}}>Podcast Summarize is revolutionizing the way podcast lovers, busy professionals, avid learners, and even those new to podcasts engage with content, enabling a more efficient and enriched listening experience.</p>
            </div>    
            <div className='row d-flex justify-content-center mx-1'>
    <div className="card rounded-4 col-lg-3 col-sm-5 m-3" style={{ minHeight:'270px',minWidth:'280px', boxShadow: '5px 5px 15px lightgrey', border: '1px' }} >
        <div className="card-body d-flex flex-column px-0">
            <div style={{  width:'100%',borderImage: 'linear-gradient(to right, orange 0%, orange 45%, rgba(255, 165, 0, 0.5) 60%, white 85%) 0 0 100% 0 stretch', borderBottom: '1px solid transparent' }} className='d-flex  justify-content-start align-items-center'>
                <FaCircleUser style={{ height:'100px', fontSize:'50px',color:'#9A32FF' }} />
                <div className='ms-3'>
                    <p className=' fs-4 text-start pt-4 pb-0 mt-auto mb-0'>Aman D</p>
                    <p className='fs-6 text-start pt-0'>Startup Founder</p>
                </div>
            </div>
            <div className="mt-3">
                <p className="card-text text-start py-2 fs-5">Simple idea, something I could do on my own if I had the time, but well executed. Have more ideas I have sent to the Podcast Summarize team.</p>
            </div>
        </div>
    </div>
    <div className="card rounded-4 col-lg-3 col-sm-5 m-3" style={{ minHeight:'270px',minWidth:'280px', boxShadow: '5px 5px 15px lightgrey', border: '1px' }} >
        <div className="card-body d-flex flex-column px-0">
            <div style={{  width:'100%',borderImage: 'linear-gradient(to right, orange 0%, orange 45%, rgba(255, 165, 0, 0.5) 60%, white 85%) 0 0 100% 0 stretch', borderBottom: '1px solid transparent' }} className='d-flex  justify-content-start align-items-center'>
                <FaCircleUser style={{ height:'100px', fontSize:'50px',color:'#9A32FF' }} />
                <div className='ms-3'>
                    <p className='fs-4 text-start pt-4 pb-0 mt-auto mb-0'>Riley M</p>
                    <p className='fs-6 text-start pt-0'>Data Analyst</p>
                </div>
            </div>
            <div className="mt-3">
                <p className="card-text text-start py-2 fs-5">Something that saves me time and I can decide what episode of my favorite Podcast I want to listen into.</p>
            </div>
        </div>
    </div>
    <div className="card rounded-4 col-lg-3 col-sm-5 m-3" style={{ minHeight:'270px',minWidth:'280px', boxShadow: '5px 5px 15px lightgrey', border: '1px' }} >
        <div className="card-body d-flex flex-column px-0">
            <div style={{  width:'100%',borderImage: 'linear-gradient(to right, orange 0%, orange 45%, rgba(255, 165, 0, 0.5) 60%, white 85%) 0 0 100% 0 stretch', borderBottom: '1px solid transparent' }} className='d-flex  justify-content-start align-items-center'>
                <FaCircleUser style={{ height:'100px', fontSize:'50px',color:'#9A32FF' }} />
                <div className='ms-3'>
                    <p className='fs-4 text-start pt-4 pb-0 mt-auto mb-0'>Jason J</p>
                    <p className='fs-6 text-start pt-0'>Mortgage Loan Originator</p>
                </div>
            </div>
            <div className="mt-3">
                <p className="card-text text-start py-2 fs-5">I like the simplicity of the Product. Does a very simple thing but is useful.</p>
            </div>
        </div>
    </div>
</div>
            </div>
    )
}

export default HomeS4Copilot