import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import { VscTriangleRight } from "react-icons/vsc";
import {  useNavigate } from 'react-router-dom';
import girl from './assets/signingirl.webp'
import rect from './assets/assets/invertrect.webp'
import logo from './assets/logo.png'
import { Link, NavLink, useLocation } from "react-router-dom";
import axios from 'axios';

const HomeS1Banner = () => {

const navigate = useNavigate();
const[token,setToken]=useState('')
const [admin_token,setAdmin_token]=useState('')

useEffect(()=>{
if(Cookies.get('token')){
  setToken(Cookies.get('token'))
}
},[])

const refreshAccessToken = async () => {
  try {
    const refreshToken = Cookies.get('refresh_token');
    const response = await axios.post(`${process.env.REACT_APP_URL}login/refresh`, {
      refresh_token: refreshToken,
    },{
      headers: {
        'Content-type':'application/json', 
        'Accept':'application/json',
        'Authorization': `Bearer ${refreshToken}`,
      },
    });

    if (response.data.token) {
      // Update the access token in cookies or localStorage
      Cookies.set('token', response.data.token, { expires: 1, path: '/' });
      if(response.data.admintoken){
      Cookies.set('admin_token', response.data.admintoken, { expires: 1, path: '/' });
      }
      console.log("Token Refreshed");
    } else {
      console.log(response.data.error);
    }
  } catch (error) {
    console.error('Error refreshing access token:', error);
  }
};

// Call refreshAccessToken every 5 minutes (300,000 milliseconds)
let intervalId;
const startTokenRefresh = () => {
  intervalId = setInterval(refreshAccessToken, 300000);
};
const stopTokenRefresh = () => {
  clearInterval(intervalId);
};

useEffect(() => {
  if (token === '' && Cookies.get('token') !== undefined) {
    setToken(Cookies.get('token'));
    console.log(Cookies.get('token'));
  }
  if(admin_token ==='' && Cookies.get('admin_token')!== undefined){
    setAdmin_token(Cookies.get('admin_token'));
  }
}, [token]); // Include token in dependency array

useEffect(() => {
  const handleVisibilityChange = () => {
    if (!document.hidden) {
      refreshAccessToken();
      // console.log("VVVVVVVVVVV")
    }
    // console.log("VVVVVVVVVVVVVVVvvvvvv aaaaaaaaaaa out")
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  startTokenRefresh(); // Start token refresh when component mounts
  return () => stopTokenRefresh(); // Cleanup function to stop interval when component unmounts
}, []); 

const handleLogout=()=>{
Cookies.remove('token')
Cookies.remove('refresh_token')
Cookies.remove('admin_token')
console.log('logout')
window.location.href = '/';
}

  return (
<>
  {/* nav */}
  <div style={{ position: 'relative', zIndex: 10 }}>
    <nav className="navbar navbar-expand-lg navbar-light py-1" style={{ backgroundColor: '' }}>
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img src={logo} alt="Logo" style={{ height: '85px', width: '85px', borderRadius: '100%' }} className='mx-2' />
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
          <ul className="navbar-nav" style={{ color: 'black' }}>
            {token !== '' && (
              <li className="nav-item hoverb mx-3">
                <NavLink className="nav-link fs-5 my-1  fw-bold" style={{ color: 'black' }} to="/podcast">Podcasts</NavLink>
              </li>
            )}
            {admin_token !== '' && (
              <li className="nav-item hoverb mx-3">
                <NavLink className="nav-link fs-5 my-1  fw-bold" style={{ color: 'black' }} to="/superadmin">Admin</NavLink>
              </li>
            )}
            <li className="nav-item hoverb">
              <a className="nav-link mx-3 my-1  fs-5 fw-bold" aria-current="page" style={{ color: 'black' }} href="#Features">Features</a>
            </li>
            <li className="nav-item hoverb">
              <a className="nav-link mx-3  my-1 fs-5 fw-bold" aria-current="page" style={{ color: 'black' }} href="#Testimonials">Testimonials</a>
            </li>
            <li className="nav-item hoverb ">
              <a className="nav-link mx-3 my-1  fs-5 fw-bold" aria-current="page" style={{ color: 'black' }} href="#Pricing">Pricing</a>
            </li>
          </ul>
        </div>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
          <ul className="navbar-nav" style={{ color: 'black' }}>
            {token === '' ? (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link active mx-3 rounded-pill my-1  px-5 fs-5" to="/signin" style={{ backgroundColor: '#FF8730', color: 'white' }}>
                    Sign In
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link mx-3 rounded-pill my-1  hoverbb px-5 fs-5" style={{ color: '#FF8730', backgroundColor: 'white', border: '1px solid #FF8730' }} to="/signup">
                    Sign Up
                  </NavLink>
                </li>
              </>
            ) : (
              <li className="nav-item">
                <NavLink className="nav-link mx-3 rounded-pill my-1  hoverbb px-5 fs-5" style={{ color: '#FF8730', border: '1px solid #FF8730', backgroundColor: 'white' }} onClick={() => handleLogout()}>
                  Log out
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  </div>
  {/* banner */}
  <div className="homepage-banner row col-12 mx-0 px-0" style={{ paddingTop: '50px', paddingBottom: '0px', backgroundColor: 'white', zIndex: 0, minHeight: '650px', position: 'relative' }}>

    <div className="container text-start pt-5 px-5 col-xxl-8 col-xl-7 col-lg-6" style={{ zIndex: 1 }}>
      <div className='col-xl-10 col-lg-12'>
        <h1 className='banner-font-size' style={{ color: '#5B058F' }}>Concise and Relevant Podcast Summaries sent to your inbox weekly to elevate your Podcast knowledge.</h1>
        <p className="pt-5 small-font-size">Get the latest episode summaries for all your favorite Podcasts in one place once a week.</p>
      </div>
      <div className='d-flex justify-content-around row flex-wrap mb-5' style={{ zIndex: 2 }}>
        {token === ''
          ? <button type="button" className="btn rounded-pill col-xl-5 col-lg-8 col-md-9 col-sm-10  py-2 fs-5 mx-1 my-1" style={{ backgroundColor: '#FF8730', color: 'white', minWidth: '220px' }} onClick={() => navigate('/signup')}>Get Podcast Summaries Now</button>
          : <button type="button" className="btn rounded-pill col-xl-5 col-lg-8 col-md-9 col-sm-10 py-2 fs-5 mx-1 my-1" style={{ backgroundColor: '#FF8730', color: 'white', minWidth: '220px' }} onClick={() => navigate('/podcast')}>Get Podcast Summaries Now</button>
        }
        <button type="button" onClick={() => navigate('/sample')} className="btn rounded-pill col-xl-5 col-lg-8 col-md-9 col-sm-10 py-2 fs-5 mx-1 my-1" style={{ borderColor: '#FF8730', minWidth: '220px', color: '#FF8730' }}><VscTriangleRight style={{ color: 'white' }} /> See Sample Podcast Summaries</button>
      </div>
    </div>

    <div className='col-xxl-4 col-xl-5 col-lg-6 position-relative mt-auto mx-auto image-below-lg-noshow' style={{ minHeight: '500px', zIndex: 0 }}>
    <img rel='preload' className='image1 px-0 col-12 position-absolute' style={{ bottom: '0', right: '0px', minHeight: '500px', height: '200%', zIndex: -1, overflowX:'clip' }} src={rect} alt='Rectangle' />
    <img rel='preload' className='image2 col-12 position-absolute' style={{ bottom: '0', right: '50px', minHeight: '600px' }} src={girl} alt='Girl' />
    </div>
  </div>
</>

  )
}

export default HomeS1Banner