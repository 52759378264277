import React from 'react'
import Footer2 from '../Components/Common/Footer2'
import Section1Podcasters from '../Components/Podcasters/Section1Podcasters';
import { ToastContainer, toast } from 'react-toastify';
import NavbarTwo from '../Components/Common/NavbarTwo';

const Podcasters = () => {
  return (
    <div>
         <NavbarTwo/>
        <Section1Podcasters/>
        <Footer2/>
    </div>
  )
}

export default Podcasters