import React from 'react'

import Navbar from '../Components/Common/Navbar'
import HomeS1Banner from '../Components/Home/HomeS1Banner'
import HomeS2Sub from '../Components/Home/HomeS2Sub'
import HomeS2Sub2 from '../Components/Home/HomeS2Sub2'
import HomeS3Manager from '../Components/Home/HomeS3Manager'
import HomeS4Copilot from '../Components/Home/HomeS4Copilot'
import HomeS5Pricing from '../Components/Home/HomeS5Pricing'
import Footer from '../Components/Common/Footer'

const Home = () => {



  return (
    <div>
        {/* <Navbar/> */}
        <HomeS1Banner/>
        <HomeS2Sub/>
        <HomeS3Manager/>
        <HomeS4Copilot/>
        <HomeS5Pricing/>
        <HomeS2Sub2/>
        <Footer/>
    </div>
  )
}

export default Home